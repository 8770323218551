import React, { useEffect, useState } from 'react'


import { getPortfoloio } from '../services/PortfolioService'
import { getProviders } from '../services/ProviderServices'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AdminTable from '../components/Admin/AdminTable'
import $ from 'jquery'
// import { getStockDetails } from '../services/TradeService'

const AdminDashboard = () => {
    const [portfololioList, setPortfolioList] = useState([])
    const [providerList, setProviderList] = useState([])
    const [runPortFolioAPI, setRunPortFolioAPI] = useState(true)
    const [openTradeRun, setOpenTradeRun] = useState(false)
    const [transactionList, setTransactionList] = useState([])
    const [openTradeList, setOpenTradeList] = useState([])
    const [calculatedData, setCalculatedData] = useState()
    const [getOpenToggleId, setGetOpenToggleId] = useState()
    const [userType, setUserType] = useState('')

    const location = useLocation()
    //  $(document).ready(function () {
    //     $('[id="restrictedInput"]').on('input', function () {
    //         const input = $(this);
    //         const value = input.val();
    
    //         // Remove any characters that are not allowed
    //         const sanitizedValue = value.replace(/[^0-9\s\b\t\r\n\f\+\-\.\t]/g, '');
    
    //         // Update the input value with the sanitized value
    //         input.val(sanitizedValue);
    //     });
    // });
    let stateData = location.state
    useEffect(() => {
        let nm = localStorage.getItem('userDetails')
        setUserType(JSON.parse(nm))
    }, [])
    setTimeout(() => {
        if (location.state !== null && getOpenToggleId !== null && getOpenToggleId !== undefined) {

            setOpenTradeRun(true)
            setCalculatedData({
                portFolioId: stateData.portfolioId,
                margin: stateData.margin,
                entry: stateData.price,
                stockCode: stateData.stockCode,
                stockName: stateData.stockName,
                quantity: stateData.price / stateData.entry,
                share: '',
                providerId: stateData?.providerId || '',
                risk: '',
                stopLoss: stateData.stopLoss,
                cfd: '',
                target: '',
                cummission: '',
                reward: '',
                rewardPercent: ''
            })
        }
    }, 400)
    useEffect(() => {
        try {
            getPortfoloio().then((res) => {
                if (res?.status === 200) {
                    setPortfolioList(res.data)
                    setRunPortFolioAPI(false)
                }
            })
        } catch (error) {
            
        }
    }, [runPortFolioAPI === true])
    useEffect(() => {
        let data = {
            PageSize: 1000,
            PageNumber: 1
        }
        try {
            getProviders(data).then((res) => {
                if (res?.status === 200) {
                    setProviderList(res.data.data)
                }
            })
        } catch (error) {
            
        }

    }, [])
    // const [showCreateTrade,setShowCreateTrade]=useState(false)
    // const [showCalculator,setShowCalculator]=useState(false)
    // useEffect(()=>{
    //     try {
    //         getStockDetails().then((res)=>{
    // 
    //         })
    //     } catch (error) {
    //         
    //     }
    // },[])
    // stockDetails()
    const navigate=useNavigate()
    const goBack = () => {
		navigate({
            pathname: '/',
          });
	}
    return (
        <>
            <section>
                <div className="container-fluid">
                <button type="button" className="btn-close btn-close-provider admin-close" onClick={goBack} data-bs-dismiss="modal" aria-label="Close">×</button>
                    <div className="row">
                        {/* <SideBar /> */}
                        {/* <!--------ul-----clouse----> */}
                        <div className="col-lg-12">
                            {/* <Header/> */}
                            <AdminTable userType={userType}/>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default AdminDashboard