import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormikContext } from 'formik'
import * as Yup from "yup";
import moment from 'moment/moment'
import { IoReload } from "react-icons/io5";
import { getStrategy } from '../../../services/StrategyService';
import { getSearchStockDetails, getStockList } from '../../../services/HomeService';
import { GetCurrentBalabce, GetProviderCummission, GetProviderstocks, GetStockByCountry, getProviders } from '../../../services/ProviderServices';
import { AddOpenTradeAPI } from '../../../services/TradeService';
import CummissionDetails from '../../commonComponent/commonModels/CummissionDetails'
import { toast } from 'react-toastify';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
import $ from 'jquery'
import { getExchangeRateAPI } from '../../../services/commonServices';

const OpenTrade = ({runbalanceTranAPI, setRunBalancsTranAPI, countryList, runAgainDashBoard, setRunAgainDashBoard, userType, portfololioList, openTradeRun, calculatedData, setCalculatedData }) => {

    const [initialState, setInitialState] = useState({
        portfolioID: '',
        userId: userType?.userId,
        strategy: '',
        providerID: '',
        stockCode: "",
        stockName: "",
        share_Sector: "Stock",
        country: '',
        margin: "",
        short: "false",
        openDate: moment(new Date()).format('DD/MM/YYYY'),
        qty: '',
        transactionPrice: '',
        transFee: '',
        transCost: '',
        valueInCFD: '',
        valueInShares: '',
        comments: "",
        stopLossPrice: '',
        gsloPrice: '',
        gsloCost:0,
        gsloTradingFee: '',
        fromTransPricePercent: '',
        fromTransPriceDollar: '',
        atRiskPercent: '',
        atRiskDollar: '',
        createdBy: "",
        isOpen: true,
        modifiedBy: "",
        brockerId: '',
        tradeId: 0,
        reRender: 0
    })

    const { portfolioID, providerID, stockCode, comments, reRender, valueInShares, transactionPrice, brockerId, margin, qty, short, gsloTradingFee, gsloPrice, gsloCost, stopLossPrice } = initialState
    const [strategyLists, setStrategyLists] = useState([])
    const [selectedPortFolio, setSelectedPortFolio] = useState()
    const [showCodeList, setShowCodeList] = useState(false)
    const [stockLists, setStockLists] = useState([])
    const [cummissionData, setCummissionData] = useState([])
    const [brokerData, setBrokerData] = useState('')
    const [checkStopLoss, setCheckStopLoss] = useState(true)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [error, setError] = useState({ target: true, stopLoss: true })
    const [brokerList, setBrokerList] = useState(portfololioList)
    const [currencyCodes, setCurrencyCodes] = useState({ stockCountryCode: '', portfolioCountryCode: '', portfolioCurrency: '', stockCountrySymbol: '' })
    const { stockCountryCode, portfolioCountryCode, portfolioCurrency, stockCountrySymbol } = currencyCodes
    const [exchangeRate, setExchangerate] = useState(1)
    const [popUpShow, setPopUpShow] = useState(false)
    const [loading, setLoading] = useState(false);


    const toastQueue = [];

    const showToast = (message) => {
        
        toastQueue.push(message);
        if (!popUpShow) {
            displayNextToast();
        }
    };

    const displayNextToast = () => {
        if (toastQueue.length > 0) {
            const message = toastQueue.shift();
            toast.error(message, {
                autoClose: 3000, // Set the duration as needed
                // onClose: () => {
                //     setPopUpShow(false);
                //     displayNextToast(); // Display the next toast message
                // },
            });
            setPopUpShow(true);
            setTimeout(()=>{
                       setPopUpShow(false);
                    // displayNextToast();
            },3500)
        }
    };
    const [data, setData] = useState({
        country: '',
        PageNumber: 1,
        PageSize: 200,
        search: ''
    })
    let validationSchema = Yup.object().shape({
        share_Sector: Yup.string().required('Share/Sector is required'),
        qty: Yup.string().required('Quantity is required'),
        portfolioID: Yup.string().required('Portfolio is required'),
        stockCode: Yup.string().required('Stock is required'),
        strategy: Yup.string().required('Strategy is required'),
        margin: Yup.string().required('Margin is required').typeError('Margin must be a number.'),
        transactionPrice: Yup.string().required('Entry Price is required'),
        stopLossPrice: Yup.string().required('Stoploss is required'),
    });

    let validationSchemaGLO = Yup.object().shape({
        share_Sector: Yup.string().required('Share/Sector is required'),
        qty: Yup.string().required('Quantity is required'),
        portfolioID: Yup.string().required('Portfolio is required'),
        stockCode: Yup.string().required('Stock is required'),
        strategy: Yup.string().required('Strategy is required'),
        margin: Yup.string().required('Margin is required').typeError('Margin must be a number.'),
        transactionPrice: Yup.string().required('Entry Price is required'),
        gsloPrice: Yup.string().required('GSLO price is required'),
    });

    // checkStopLoss
    let filteredProviderId

    if (calculatedData !== '' && calculatedData !== undefined) {
        filteredProviderId = portfololioList.filter((item) => {
            if (item.portfolioId == calculatedData.portFolioId) {
                return item
            }
        })
    }
    useEffect(() => {
        setBrokerList(portfololioList)
    }, [portfololioList])

    useEffect(() => {

        if (calculatedData !== undefined && calculatedData !== '') {

            // try {
            //     getSearchStockDetails(calculatedData.stockCode).then((res) => {
            //         if (res?.status === 200) {

            //             let aus = calculatedData.stockCode.includes(".AX")
            //             if (aus) {
            //                 if (res.data.close >= 0) {

            //                     setInitialState({  ...initialState,brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId,
            //                     portfolioID: calculatedData?.portFolioId || '',
            //                     userId: userType?.userId,
            //                     strategy: calculatedData?.tradingSystemId,
            //                     country: calculatedData?.country,
            //                     providerID: calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId,
            //                     providerName: calculatedData?.providerName,
            //                     stockCode: calculatedData?.stockCode,
            //                     stockName: calculatedData?.stockName,
            //                     share_Sector: "Stock",
            //                     margin: calculatedData?.margin,
            //                     short: calculatedData?.long == true ? "false" : "true",
            //                     openDate: moment(new Date()).format('DD/MM/YYYY'),
            //                     qty: Math.round(calculatedData.quantity > 0 ? (calculatedData.quantity):''),
            //                     stopLossPrice: calculatedData?.stopLoss,
            //                     tradeId:calculatedData?.tradeId,
            //                          transactionPrice: res.data?.close, })
            //                 } else {
            //                     showToast('Please select valid stock.')
            //                 }
            //             } else if (res.data.length > 0) {
            //                 setInitialState({ ...initialState,brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId,
            //                 portfolioID: calculatedData?.portFolioId || '',
            //                 userId: userType?.userId,
            //                 strategy: calculatedData?.tradingSystemId,
            //                 country: calculatedData?.country,
            //                 providerID: calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId,
            //                 providerName: calculatedData?.providerName,
            //                 stockCode: calculatedData?.stockCode,
            //                 stockName: calculatedData?.stockName,
            //                 share_Sector: "Stock",
            //                 margin: calculatedData?.margin,
            //                 short: calculatedData?.long == true ? "false" : "true",
            //                 openDate: moment(new Date()).format('DD/MM/YYYY'),
            //                 qty: calculatedData.quantity > 0 ? (calculatedData.quantity).toFixed(3) : '',
            //                 stopLossPrice: calculatedData?.stopLoss,
            //                      transactionPrice: res.data[0]?.close,
            //                      tradeId:calculatedData?.tradeId

            //                      })
            //             } else {
            //                 showToast('Please select valid stock.')
            //             }
            //         }
            //     })
            // } catch (error) {

            // }
            setInitialState({
                ...initialState,
                portfolioID: calculatedData?.portFolioId || '',
                userId: userType?.userId,
                strategy: calculatedData?.tradingSystemId,
                country: calculatedData?.country,
                providerID: calculatedData?.providerId,
                providerName: calculatedData?.providerName,
                stockCode: calculatedData?.stockCode,
                stockName: calculatedData?.stockCode,
                share_Sector: "Stock",
                margin: calculatedData?.margin,
                short: calculatedData?.long == true ? "false" : "true",
                openDate: moment(new Date()).format('DD/MM/YYYY'),
                qty: calculatedData.quantity > 0 ? Math.ceil(calculatedData.quantity) : '',
                transactionPrice: calculatedData?.entry,
                stopLossPrice: calculatedData?.stopLoss,
                transFee: 0,
                transCost: 0,
                valueInCFD: 0,
                valueInShares: 0,
                comments: "",
                gsloPrice: 0,
                gsloCost: 0,
                gsloTradingFee: 0,
                fromTransPricePercent: 0,
                fromTransPriceDollar: 0,
                atRiskPercent: 0,
                atRiskDollar: 0,
                createdBy: "",
                isOpen: true,
                modifiedBy: "",
                tradeId: calculatedData?.tradeId,
            })

            let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == calculatedData.country.toUpperCase())
            let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)
            if (calculatedData?.portFolioId != '' && calculatedData?.portFolioId != undefined) {
                let selectedPortFolio = portfololioList.filter((val) => val?.portfolioId == calculatedData?.portFolioId)
                try {
                    getExchangeRateAPI(selectedStockCountry[0]?.value, selectedPortFolio[0]?.currency).then((respons) => {
                      if (respons?.status === 200) {
                        let exchange = Number(respons?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate']||1)
                        setExchangerate(exchange);
                      }
                    })
                  } catch (error) {

                  }
                setCurrencyCodes({ ...currencyCodes, stockCountryCode: selectedStockCountry[0]?.value, stockCountrySymbol: symbol[0]?.symbol_native, portfolioCurrency: selectedPortFolio[0]?.currencySymbol, portfolioCountryCode: selectedPortFolio[0]?.currency })
            } else {
                setCurrencyCodes({ ...currencyCodes, stockCountryCode: selectedStockCountry[0]?.value, stockCountrySymbol: symbol[0]?.symbol_native })
            }
        }
    }, [calculatedData, portfololioList])

    useEffect(() => {

        if (providerID !== '' && providerID !== undefined) {

            try {
                GetProviderCummission(providerID).then((res) => {
                    if (res?.status === 200) {
                        setCummissionData(res.data)
                    }
                })
            } catch (error) {

            }
        }
    }, [providerID])

    useEffect(() => {
        if (portfolioID !== '' && data.country.length > 0) {
            try {
                GetStockByCountry(data).then((res) => {
                    if (res?.status === 200) {
                        setStockLists(res.data.data)
                    }
                })
            } catch (error) {
            }
        }
        let cData = {
            portfolioId: portfolioID,
            userId: userType?.userId
        }
        
        if (cData.userId !== undefined && cData.portfolioId >0) {
            try {
                // console.log(cData , "cdata")
                GetCurrentBalabce(cData).then((res) => {

                    if (res?.status === 200) {
                        setBrokerData(res.data)
                    }
                })
            } catch (error) {

            }
        }

    }, [portfolioID, userType])
    const handleSearchStock = (e) => {
        if (portfolioID != '') {
            setInitialState({ ...initialState, stockCode: e.target.value })
            let nm = data
            nm.search = e.target.value
            nm.PageNumber = 1
            setData({
                ...nm
            })
            if (data.country.length > 0) {
                try {
                    GetStockByCountry(nm).then((res) => {
                        if (res?.status === 200) {
                            setShowCodeList(true)
                            setStockLists(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }
        } else {
            showToast('Select portfolio first.')
        }

    }

    const getStrategyList = () => {
        try {
            getStrategy(userType?.userId).then((res) => {
                if (res?.status === 200) {
                    setStrategyLists(res.data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        // if (userType?.userId!==undefined) {
        getStrategyList()
        // }
    }, [openTradeRun, userType])
    const twoDecimal = (value) => {
        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 2) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
            }
        }
        return roundedNumber
        // let n =value.split('.')
        // if (n.length == 2) {
        //     if (n[1].length <= 2) {
        //         return value
        //     }
        // } else if (n.length == 1) {
        //     return value
        // }
    }
    // const customQtyId = "custom-id-yes";
    // const customEntryId = "custom-id-yes";
    // const customMarginId = "custom-id-yes";
    // const customStoplossId = "custom-id-yes";
    // const customId = "custom-id-yes";
    // const customId = "custom-id-yes";
    const handleChangeOpenInput = (e) => {
        if (e.target.name == 'portfolioID') {
            if (e.target.value > 0) {

                let selectedPortFolio = portfololioList.filter((val) => e.target.value == val?.portfolioId)

                setCurrencyCodes({ ...currencyCodes, portfolioCountryCode: selectedPortFolio[0]?.currency, portfolioCurrency: selectedPortFolio[0]?.currencySymbol })
                let portVal = e.target.value
                if (calculatedData !== undefined) {
                    let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == calculatedData.country.toUpperCase())
                    try {
                        getExchangeRateAPI(selectedStockCountry[0]?.value, selectedPortFolio[0]?.currency).then((respons) => {
                            
                          if (respons?.status === 200) {
                            let n=respons?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate']||1
                            let exchange = Number(n)
                            if(n!=undefined){
                                setExchangerate(exchange);
                            }else{
                                setExchangerate(1);
                            }
                          }
                        })
                      } catch (error) {
                        
                      }
                   
                    setInitialState({
                        ...initialState,
                        [e.target.name]: selectedPortFolio[0].portfolioId
                    })
                } else {
                    setInitialState({ ...initialState, [e.target.name]: selectedPortFolio[0]?.portfolioId })
                }
                setData({ ...data, country: selectedPortFolio[0]?.country })
                setSelectedPortFolio(selectedPortFolio[0])
            } else {
                setInitialState({ ...initialState, [e.target.name]: '' })
            }


        } else if (e.target.name == 'qty') {


            if (Number(e.target.value) >= 0 && e.target.value != '' && e.target.value.length <= 10) {
                let vs = transactionPrice * Math.round(e.target.value)
                if (portfolioID == "undefined-undefined" || portfolioID == '') {
                    showToast('Select portfolio first!',)
                } else if (stockCode == '') {
                    showToast('Select stock code first.')
                } else if (transactionPrice == 'NaN') {
                    showToast('Please enter entry price first.',)
                } else if (brokerData?.currentBalance < vs) {
                    showToast('Does not have sufficient balance!',)
                } else {
                    setInitialState({ ...initialState, [e.target.name]: Math.round(e.target.value) })
                }
            } else if (e.target.value == '') {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
            // else{
            //     showToast('Quantity must be a number.')
            // }
        } else if (e.target.name == 'transactionPrice') {
            if ((Number(e.target.value) >= 0 && e.target.value != '') || e.target.value == '.') {
                if (portfolioID == '') {
                    showToast('Select portfolio first.')
                } else if (stockCode.length == 0) {
                    showToast('Select stock code first.')
                } else {


                    let vs = qty * e.target.value
                    if (brokerData?.currentBalance > vs || e.target.value == '.') {
                        let n = e.target.value.split('.')
                        let value = twoDecimal(e.target.value)
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setInitialState({ ...initialState, [e.target.name]: value, stopLossPrice: '' })
                            }
                        } else if (e.target.value.length <= 10) {

                            setInitialState({ ...initialState, [e.target.name]: value, stopLossPrice: '' })
                        }
                    } else {
                        showToast('Does not have sufficient balance!')
                    }

                }
            } else if (e.target.value == '') {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
            // else{
            //     showToast('Quantity must be a number.')
            // }
        } else if (e.target.name == 'margin' || e.target.name == 'gsloPrice') {
            if ((Number(e.target.value) >= 0 && e.target.value != "") || e.target.value == '.') {
                if (portfolioID == '') {
                    showToast('Select portfolio first.')
                } else if (stockCode.length == 0) {
                    showToast('Select stock code first.')
                } else {

                    let value = twoDecimal(e.target.value)
                    let n = e.target.value.split('.')
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setInitialState({ ...initialState, [e.target.name]: value })
                        }
                    } else if (e.target.value.length <= 10) {

                        setInitialState({ ...initialState, [e.target.name]: value })
                    }


                }
            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: e.target.value })
            }
        }else if (e.target.name == 'stopLossPrice') {
            if ((e.target.value >= 0 && e.target.value != "") || e.target.value == '.') {
                if (portfolioID == '') {
                    showToast('Select portfolio first.')
                } else if (stockCode.length == 0) {
                    showToast('Select stock code first.')
                } else {

                    let value = twoDecimal(e.target.value)
                    if (short == 'false') {
                        if (Number(transactionPrice) <= Number(e.target.value)) {
                            showToast('Set lower stop loss price than entry while long trading')
                        } else {
                            let n = e.target.value.split('.')
                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setInitialState({ ...initialState, [e.target.name]: value })
                                }
                            } else if (e.target.value.length <= 10) {

                                setInitialState({ ...initialState, [e.target.name]: value })
                            }
                        }
                    } else {
                        let n = e.target.value.split('.')
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setInitialState({ ...initialState, [e.target.name]: value })
                            }
                        } else if (e.target.value.length <= 10) {

                            setInitialState({ ...initialState, [e.target.name]: value })
                        }
                    }

                }

            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: '' })
            }
        } else if (e.target.name == 'gsloCost') {
            if (e.target.value >= 0 && e.target.value != "") {
                let value = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setInitialState({ ...initialState, [e.target.name]: value })
                    }
                } else if (e.target.value.length <= 10) {

                    setInitialState({ ...initialState, [e.target.name]: value })
                }
            } else if (e.target.value == "") {
                setInitialState({ ...initialState, [e.target.name]: '' })
            }
        } else {

            setInitialState({ ...initialState, [e.target.name]: e.target.value })
        }
    }
    const handleGetSequrityName = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;

        if (
            (scrollTop + clientHeight) >= scrollHeight &&
            (scrollTop + clientHeight) <= scrollHeight + 100
        ) {
            let nm = data
            nm.PageNumber = nm.PageNumber + 1
            setData({ ...nm })
            if (data.country.length > 0 && stockLists.length >= 200) {
                try {
                    GetStockByCountry(nm).then((res) => {
                        if (res?.status === 200) {
                            setStockLists(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }

        } else if (
            (scrollTop + clientHeight) <= scrollHeight &&
            scrollTop <= 10
        ) {
            let nm = data
            nm.PageNumber = nm.PageNumber > 1 ? nm.PageNumber - 1 : nm.PageNumber
            setData({ ...nm })
            if (data.country.length > 0 && stockLists.length >= 200) {
                try {
                    GetStockByCountry(nm).then((res) => {
                        if (res?.status === 200) {
                            setStockLists(res.data.data)
                        }
                    })
                } catch (error) {

                }
            }
        }
        // setInitialState({ ...initialState, stockName: e.target.value })
        // 
    }
    // setInitialState({ ...initialState, providerID: values.providerID })

   

    const handleStockData = (value) => {
        debugger
        let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == value.country.toUpperCase())
        let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)

        setCurrencyCodes({ ...currencyCodes, stockCountryCode: selectedStockCountry[0]?.value, stockCountrySymbol: symbol[0]?.symbol_native })
        setShowCodeList(false)
        
        try {
            getSearchStockDetails(value.stockCode).then((res) => {

                if (res?.status === 200) {
                    try {
                        getExchangeRateAPI(selectedStockCountry[0]?.value, portfolioCountryCode).then((respons) => {
                            if (respons?.status === 200) {

                                let exchange = respons?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate']||1
                                setExchangerate(exchange);
                              //  let aus = value.stockCode.includes(".AX")
                                let aus = value.stockCode.includes("ASX:")
                                if (aus) {
                                    console.log(res.data ,"search stock australia aaaaaaaa");
                                    const price =res.data.quotes[0].price 
                                    //const price = res.data["close"];
                                    setInitialState({ ...initialState, providerID: value?.providerId, transactionPrice: Number(price).toFixed(2), country: value.country, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })

                                } else {
                                    const price = res.data["Global Quote"]["05. price"];
                                    setInitialState({ ...initialState, providerID: value?.providerId, transactionPrice: Number(price).toFixed(2), country: value.country, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })
                                }

                            }

                        })


                    } catch (error) {

                    }
                }
            })
        } catch (error) {

        }
    }

    const RefreshExchangeRate = () => {
        setLoading(true); 
        getExchangeRateAPI(stockCountryCode, portfolioCountryCode)
          .then((response) => {
            if (response?.status === 200) {
              const exchange = response?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate'] || 1;
              setExchangerate(exchange);
            }
          })
          .catch((error) => {
            // Handle error if needed
          })
          .finally(() => {
            setLoading(false); 
          });
      };
      const handleRefresh=()=>{
        RefreshExchangeRate();
    }

    const handleLongShort = (val) => {
        if (val === 'long') {
            setInitialState({ ...initialState, short: "false", stopLossPrice: '' })
        } else {
            // setLongshort(false)
            setInitialState({ ...initialState, short: "true", stopLossPrice: '' })
        }
        setError({...error,stopLoss:true})
    }

    const settingData = (data) => {
        let tpd, tpp, atrp, atrd
        if (stopLossPrice > 0 && stopLossPrice !== '') {
            if (short == 'true') {
                tpd = stopLossPrice - transactionPrice
            } else {
                tpd = transactionPrice - stopLossPrice
            }
        } else if (gsloPrice > 0 && gsloPrice !== '') {
            if (short == 'true') {
                tpd = gsloPrice - transactionPrice
            } else {
                tpd = transactionPrice - gsloPrice
            }
        }
        if ((stopLossPrice > 0 && stopLossPrice !== '') || (gsloPrice > 0 && gsloPrice !== '')) {
            tpp = (tpd / transactionPrice) * 100
            atrd = qty * tpd
            atrp = (atrd / brokerData?.currentBalance) * 100
            setInitialState({ ...initialState, ...data, fromTransPricePercent: tpp?.toFixed(3), fromTransPriceDollar: tpd?.toFixed(2), atRiskPercent: atrp ? atrp?.toFixed(2) : 0, atRiskDollar: atrd?.toFixed(2) })
        } else {
            setInitialState({ ...initialState, ...data })
        }

    }

    useEffect(() => {
        
        if (qty != '' && qty != undefined && transactionPrice != "" && transactionPrice != "NaN") {

            let vs = 0, tf = 0, vcfd = 0, tc = 0
            vs = (transactionPrice * exchangeRate) * Number(qty)
            for (let i = 0; i < cummissionData.length; i++) {
                
                if (cummissionData[i].upToTransValue >= vs && cummissionData[i].aboveTransValue<=vs) {
                    if (cummissionData[i].type == "Percent") {
                        tf = (vs * cummissionData[i].commission) / 100
                    } else {
                        tf = cummissionData[i].commission
                    }
                    break;
                }else{
                    tf=0
                }
            }
            let gtf = 0
            if (Number(gsloCost) > 0 && gsloCost != '') {
                gtf = (gsloCost * qty) / 100
                // setInitialState({ ...initialState,  })
            }

            vcfd = (vs * margin) / 100
            tc = vcfd + tf + gtf

            let data = {
                gsloTradingFee: twoDecimal(gtf.toString()) , valueInShares: vs?.toFixed(2), transFee: tf?.toFixed(2), valueInCFD: vcfd?.toFixed(2), transCost: tc?.toFixed(2)
            }
            settingData(data)
        } else {
            let data = {
                gsloTradingFee: 0, valueInShares: 0, transFee: 0, valueInCFD: 0, transCost: 0
            }
            settingData(data)
        }
    }, [qty, gsloTradingFee, transactionPrice, exchangeRate, margin, cummissionData, gsloCost, stopLossPrice, gsloPrice])

    const handleAddEditPortfolio = (value) => {

        setShowSaveButton(true)

        let apiData = {
            portfolioID: Number(value.portfolioID),
            country: value.country.toUpperCase(),
            userId: userType?.userId,
            strategy: Number(value.strategy),
            providerID: Number(value.providerID),
            stockCode: value.stockCode,
            stockName: value.stockName,
            share_Sector: value.share_Sector,
            margin: value.margin.toString(),
            short: value.short,
            openDate: new Date(),
            qty: Number(value.qty),
            transactionPrice: Number(value.transactionPrice),
            transFee: Number(value.transFee),
            transCost: Number(value.transCost),
            valueInCFD: Number(value.valueInCFD),
            valueInShares: Number(value.valueInShares),
            comments: value.comments,
            stopLossPrice: Number(value.stopLossPrice),
            gsloPrice: Number(value.gsloPrice),
            gsloCost: Number(value.gsloCost),
            gsloTradingFee: Number(value.gsloTradingFee),
            fromTransPricePercent: Number(value.fromTransPricePercent),
            fromTransPriceDollar: Number(value.fromTransPriceDollar),
            atRiskPercent: Number(value.atRiskPercent),
            atRiskDollar: Number(value.atRiskDollar),
            createdBy: "",
            isOpen: true,
            modifiedBy: "",
            tradeId: initialState?.tradeId || 0
        }


        if (brokerData?.currentBalance > valueInShares && valueInShares !== 0 && qty != 0) {
            if (qty > 0) {
                try {
                    AddOpenTradeAPI(apiData).then((res) => {
                        if (res?.status === 200) {
                            if (res?.status === 200) {
                                let optmClose = document.getElementById('openTradeModelClose')
                                optmClose.click()
                                setRunAgainDashBoard(runAgainDashBoard + 1)
                                setShowSaveButton(false)
                            }
                        } else {
                            setShowSaveButton(false)
                        }
                    })
                } catch (error) {

                }
            } else if (qty == 0) {
                setShowSaveButton(false)
                showToast('Quantity must be greater than zero (0).')
            }
        } else {
            setShowSaveButton(false)
            showToast('does not have sufficient balance.')
        }
    }

    const handleRemoveOpenData = () => {
        setCurrencyCodes({ stockCountryCode: '', portfolioCountryCode: '', portfolioCurrency: '' })
        setShowSaveButton(false)
        setInitialState({
            ...initialState,
            portfolioID: '',
            userId: "1",
            strategy: '',
            providerID: '',
            stockCode: "",
            stockName: "",
            share_Sector: "Stock",
            margin: "",
            short: "false",
            openDate: moment(new Date()).format('DD/MM/YYYY'),
            qty: '',
            transactionPrice: '',
            transFee: 0,
            transCost: 0,
            valueInCFD: 0,
            valueInShares: 0,
            comments: "",
            stopLossPrice: '',
            gsloPrice: '',
            gsloCost: 0,
            gsloTradingFee: 0,
            fromTransPricePercent: 0,
            fromTransPriceDollar: 0,
            atRiskPercent: 0,
            atRiskDollar: 0,
            createdBy: "",
            isOpen: true,
            modifiedBy: "",
            brockerId: '',
            reRender: reRender + 1
        })
        setStockLists([])
        setData({ ...data, search: '' })
        setCalculatedData()
        setError({ target: true, stopLoss: true })
    }

    useEffect(() => {
        if (checkStopLoss == true) {
            setInitialState({ ...initialState, gsloPrice: 0, stopLossPrice: '' })
        } else {
            setInitialState({ ...initialState, stopLossPrice: 0, gsloPrice: '' })
        }
    }, [checkStopLoss])

    const handleCheckValidation = (e) => {
        if (Number(e.target.value) >= 0 && checkStopLoss == true) {
            if (transactionPrice !== '') {
                if (short == 'true') {
                    if (Number(transactionPrice) >= Number(e.target.value)) {
                        showToast('Set higher stop loss price than entry while short trading')
                        setError({ ...error, stopLoss: false })
                    } else {
                        setError({ ...error, stopLoss: true })
                    }
                } else {
                    setError({ ...error, stopLoss: true })
                }
            }
        }
    }



    const hideOnBlur = () => {

    }
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown2 > .caption2").length === 0) {
                setShowCodeList(false)
            }
        });
    });

    const Loader =<div class="preloader1">
    {/* <div class="loader loader-inner-1"> */}
    <div class=" loader-inner-2">
        <div class="loader loader-inner-3">
        </div>
    </div>
    {/* </div> */}
</div>
    return (
        <>
            <div class="modal fade hide backdrop-1" data-bs-backdrop="static" id="openTrade" tabindex="-1" aria-labelledby="exampleModalLgLabel" aria-modal="true" role="dialog" style={{ "display": "none" }}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content cal-model">
                        <div class="modal-header">
                            <h5 class="modal-title h4 white_text" id="openTradeLabel">Open Trade</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" id="openTradeModelClose" aria-label="Close" onClick={handleRemoveOpenData} >×</button>
                        </div>
                        <div class="modal-body">
                            {/* <!-- trs det --> */}
                            <div class="row">
                                <div class="col-sm-12 p-3">
                                    <div class="postion-bx-min mt-2">
                                        <span class="frm-hd-t">Trade Details</span>
                                    </div>
                                    <Formik
                                        initialValues={initialState}
                                        enableReinitialize={true}
                                        validationSchema={checkStopLoss ? validationSchema : validationSchemaGLO}
                                        onSubmit={(value) => {
                                            if (error.stopLoss == true) {
                                                handleAddEditPortfolio(value)
                                            }
                                        }}
                                    >
                                        {({ errors, touched, values }) => (
                                            <Form >
                                                <div class="row mt-1">
                                                    <div class="col-sm-12">
                                                        <div class="postion-min-dta postion-frm">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="row">
                                                                        <div class="col-sm-6">
                                                                            {/* <!---com-6---> */}
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Open Date</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class="form-control" disabled name='openDate' id="autoSizingInputDate" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3 dropdown2">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Stock Code <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto caption2" >
                                                                                    <Field name='stockCode' type="select" value={stockCode} onBlur={hideOnBlur} autoComplete="off" placeholder="select Stock" className={"form-select"} list="brow" onChange={handleSearchStock} onClick={() => { setShowCodeList(!showCodeList) }} />
                                                                                    {/* { */}
                                                                                    {errors.stockCode && touched.stockCode ? (
                                                                                        <div className='color-msg-err position-absolute'>Stock code required.</div>
                                                                                    ) : null}

                                                                                    <div className='position-relative'>
                                                                                        <div id='brow' onScroll={handleGetSequrityName} style={{ display: showCodeList === true ? 'block' : 'none' }}>

                                                                                            {portfolioID &&
                                                                                                stockLists?.map((val, ind) => {
                                                                                                    return (
                                                                                                        <p key={ind} value={(val.stockCode)} onClick={() => handleStockData(val)}>{val.stockCode}</p>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* } */}


                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Entry Price {stockCountrySymbol} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class="form-control" onBlur={hideOnBlur} name='transactionPrice' id="autoSizingInputEntry" onChange={handleChangeOpenInput} placeholder="0" />
                                                                                    {errors.transactionPrice && touched.transactionPrice ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.transactionPrice}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <!---com-6---> */}
                                                                        {/* <!---com-6---> */}
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Portfolio Name <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field name='portfolioID' as='select' onBlur={hideOnBlur} className={"form-select"} onChange={handleChangeOpenInput}>
                                                                                        <option value="" style={{ visibility: "hidden;" }}>Select Portfolio</option>
                                                                                        {
                                                                                            brokerList?.map((val, ind) => {
                                                                                                return (
                                                                                                    <option key={ind} value={val.portfolioId}>{val.portfolioName}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Field>
                                                                                    {errors.portfolioID && touched.portfolioID ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.portfolioID}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Margin <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class="form-control" id="restrictedInput" onBlur={hideOnBlur} name='margin' value={margin} placeholder="0" onChange={handleChangeOpenInput} />
                                                                                    {errors.margin && touched.margin ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.margin}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">
                                                                                    Quantity <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class={"form-control"} onBlur={hideOnBlur} value={qty} name='qty' id="autoSizingInputQuanity" onChange={handleChangeOpenInput} placeholder="0" />
                                                                                    {errors.qty && touched.qty ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.qty}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {/* <!---com-6---> */}
                                                                    </div>
                                                                    <div class="col-sm-12 radio-input mb-2 mt-1">
                                                                        <label class="form-check-label " for="gridRadios1"> Long / Short : </label>
                                                                        <input class="form-check-input" style={{ "font-size": " 13px;" }} checked={short === 'true' ? false : true} type="radio" onClick={() => handleLongShort('long')} name="gridRadios" required="" id="gridRadios1" value="option1" />
                                                                        <label class="form-check-label " for="gridRadios1"> Long </label>
                                                                        <input class="form-check-input" style={{ "font-size": " 13px;" }} checked={short === 'true' ? true : false} type="radio" onClick={() => handleLongShort('short')} name="gridRadios" required="" id="gridRadios2" value="option2" />
                                                                        <label class="form-check-label " for="gridRadios2"> Short </label>
                                                                        {/* <!-- <label class="form-check-label ancer-k" for="gridRadios3"> Commission </label> --> */}
                                                                        {/* <a>
                                                                                <button type='button' class="btn com_na mt-2 ms-auto d-block" data-bs-toggle="modal" data-bs-target="#cummissionDetails">Commission Details</button>
                                                                            </a> */}
                                                                    </div>
                                                                    <div class="col-sm-12">
                                                                        <div class="row mb-3">
                                                                            <label for="inputEmail3" class="col-sm-12 col-form-label">Comments</label>
                                                                            <div class="col-sm-12 ms-auto">
                                                                                <Field type="text" as="textarea" class="form-control" onChange={handleChangeOpenInput} name='comments' value={comments} id="autoSizingInputComments" placeholder="" />
                                                                                {errors.comments && touched.comments ? (
                                                                                    <div className='color-msg-err position-absolute'>{errors.comments}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            
                                                                        </div>
                                                                    </div>
                                                             
                                                                     </div>
                                                                {/* <!---com-6---> */}
                                                                <div class="col-sm-6">
                                                                    <div class="row">
                                                                        {/* <!---com-6---> */}
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Sector / Share</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field name='share_Sector' as='select' className={errors.share_Sector && touched.share_Sector ? "form-select border-red" : "form-select"} onChange={handleChangeOpenInput}>
                                                                                        {/* <option value="0" style={{ visibility: "hidden;" }}>Select Share</option> */}
                                                                                        <option value="Stock" >Stock</option>
                                                                                        <option value="Sector" >Sector</option>
                                                                                    </Field>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {/* <!---com-6---> */}
                                                                        {/* <!---com-6---> */}
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Strategy Name <span style={{ "color": "#f00" }}>*</span></label>
                                                                                <div class="col-sm-12 ms-auto">

                                                                                    <Field name='strategy' onBlur={hideOnBlur} as='select' className={"form-select"} onChange={handleChangeOpenInput}>
                                                                                        <option value="0" style={{ visibility: "hidden;" }}>Select Strategy</option>
                                                                                        {
                                                                                            strategyLists.map((val, ind) => {
                                                                                                return (
                                                                                                    <option key={ind} value={(val.tradingSystemId)}>{val.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </Field>
                                                                                    {errors.strategy && touched.strategy ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.strategy}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        {/* <!---com-6---> */}
                                                                        <div class="col-sm-12">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Security Name</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" class="form-control" disabled name='stockName' id="autoSizingInputSecurity" placeholder="" />
                                                                                    {errors.stockName && touched.stockName ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.stockName}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-6">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 radio-input">
                                                                                    <input class="form-check-input " type="radio" name="gridRadios5 " onClick={() => setCheckStopLoss(true)} required="" id="gridRadios1" value="option1" checked={checkStopLoss === true ? true : false} />
                                                                                    <label class="form-check-label " for="gridRadios5"> Stop Loss Price {stockCountrySymbol} {checkStopLoss === true&&<span style={{ "color": "#f00" }}>*</span>}</label>
                                                                                </div>
                                                                                <div class=" mb-3 mt-2">
                                                                                    <div class="col-sm-12 ms-auto">
                                                                                        <Field type="text" class={error.stopLoss == false ? "input-err-color form-control" : "form-control"} onBlur={handleCheckValidation} disabled={checkStopLoss === true ? false : true} name='stopLossPrice' id="autoSizingInputStopLoss" placeholder="0" onChange={handleChangeOpenInput} />
                                                                                        {errors.stopLossPrice && touched.stopLossPrice ? (
                                                                                            <div className='color-msg-err position-absolute'>{errors.stopLossPrice}</div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="row">
                                                                                <div class="col-sm-12 radio-input">
                                                                                    <input class="form-check-input " type="radio" name="gridRadios6 " onClick={() => { setError({ ...error, stopLoss: true }); setCheckStopLoss(false) }} required="" checked={checkStopLoss === false ? true : false} id="gridRadios6" value="option1" />
                                                                                    <label class="form-check-label " for="gridRadios6"> GSLO Price {portfolioCurrency} {checkStopLoss === false&&<span style={{ "color": "#f00" }}>*</span>}</label>
                                                                                </div>
                                                                                <div class=" mb-3 mt-2">
                                                                                    <div class="col-sm-12 ms-auto">
                                                                                        <Field type="text" class="form-control" name='gsloPrice' disabled={checkStopLoss === false ? false : true} id='restrictedInput' placeholder="0" onBlur={hideOnBlur} onChange={handleChangeOpenInput} />
                                                                                        {errors.gsloPrice && touched.gsloPrice ? (
                                                                                            <div className='color-msg-err position-absolute'>{errors.gsloPrice}</div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {/* <div class="col-sm-12 radio-input">
                                                                            <label class="form-check-label " for="gridRadios1"> Long / Short : </label>
                                                                            <input class="form-check-input" style={{ "font-size": " 13px;" }} checked={short === 'true' ? false : true} type="radio" onClick={() => handleLongShort('long')} name="gridRadios" required="" id="gridRadios1" value="option1" />
                                                                            <label class="form-check-label " for="gridRadios1"> Long </label>
                                                                            <input class="form-check-input" style={{ "font-size": " 13px;" }} checked={short === 'true' ? true : false} type="radio" onClick={() => handleLongShort('short')} name="gridRadios" required="" id="gridRadios2" value="option2" />
                                                                            <label class="form-check-label " for="gridRadios2"> short </label>
                                                                             <!-- <label class="form-check-label ancer-k" for="gridRadios3"> Commission </label> --> 
                                                                             <a>
                                                                                <button type='button' class="btn com_na mt-2 ms-auto d-block" data-bs-toggle="modal" data-bs-target="#cummissionDetails">Commission Details</button>
                                                                            </a> 
                                                                        </div> */}
                                                                        {/* <div class="row "> */}
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Value In Shares {portfolioCurrency}</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" disabled class="form-control" name='valueInShares' id="autoSizingInput" placeholder="0" />
                                                                                    {errors.valueInShares && touched.valueInShares ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.valueInShares}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Transaction Fee {portfolioCurrency}</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" disabled class="form-control" name='transFee' id="autoSizingInput" placeholder="0" />
                                                                                    {errors.transFee && touched.transFee ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.transFee}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>

                                                                          
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Value In Margin {portfolioCurrency}</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" disabled class="form-control" name='valueInCFD' id="autoSizingInputValueInMargin" placeholder="0" />
                                                                                    {errors.valueInCFD && touched.valueInCFD ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.valueInCFD}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mb-3">
                                                                                <label for="inputEmail3" class="col-sm-12 col-form-label">Transaction Cost {portfolioCurrency}</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text" disabled class="form-control" name='transCost' id="autoSizingInputTransactionCost" placeholder="0" />
                                                                                    {errors.transCost && touched.transCost ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.transCost}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>

                                                                           

                                                                        </div>
                                                                        {/* </div> */}



                                                                        
                                                                    </div>
                                                                    
                                                                </div>
                                                                {/* <!---com-6---> */}

                                        

                                                            </div>
                                                            
                                                            <div class="row">
                                                            <div class='col-12 col-md-3'>
                                                            <label for="portcurrency" class="col-sm-12 col-form-label">PortFolio Currency </label>
                                                                                <div class="col-sm-12 ms-auto caption2" >
                                                                                    <Field name='portfoliocurrency' class={"form-control"}  type="text" value={`${portfolioCurrency}  ${portfolioCountryCode}`} onBlur={hideOnBlur} autoComplete="off" placeholder="" />
                                                                              
                                                                              
                                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-md-3'>
  <label htmlFor="StockCurrency" className="col-sm-12 col-form-label">Stock Currency </label>
  <div className="col-sm-12 ms-auto caption2">
    <Field
      name='stockcurrency'
      type="text"
      className={"form-control"}
      value={`${stockCountrySymbol || ''} ${stockCountryCode || ''}`}
      onBlur={hideOnBlur}
      autoComplete="off"
      placeholder=""
    />
  </div>
</div>
        <div class='col-12 col-md-3'>
        <label for="excahngerate" class="col-sm-12 col-form-label">Exchange Rate </label>
                <div class="col-sm-12 ms-auto caption2" >
                <div class="icon">
                    {/* <Field name='exchangerate' type="number"class={"form-control"} value={exchangeRate} onBlur={hideOnBlur} autoComplete="off" placeholder="" />
                    <button class="iconbtn"  onClick={handleRefresh}> <IoReload/></button> */}
                    <div class="input-group mb-3">
                        <input name='exchangerate' type="text"class={"form-control"} value={exchangeRate} onBlur={hideOnBlur} autoComplete="off" placeholder="" />
                        <span class="input-group-text" id="basic-addon1" onClick={handleRefresh}>  {loading ? <div className="spinner"></div>: <IoReload />}</span>
                    </div>
                </div>
                </div>
        </div>


                                                            <div class='col-12 col-md-3'>
                                                            <label for="inputEmail3" class="col-sm-12 col-form-label">Value In Shares {portfolioCurrency}</label>
                                                                                <div class="col-sm-12 ms-auto">
                                                                                    <Field type="text"  class="form-control" name='valueInShares' id="autoSizingInput" placeholder="0" />
                                                                                    {errors.valueInShares && touched.valueInShares ? (
                                                                                        <div className='color-msg-err position-absolute'>{errors.valueInShares}</div>
                                                                                    ) : null}
                                                                                </div>
                                                            </div>
                                
                                                            
                                                            </div>
                                                        </div>

                                                        
                                                        {/* <!-- <div class="col-12"> --> */}
                                                        <div class="row">
                                                            {/* <!-- <div class="col-6"> --> */}
                                                            <div class="col-sm-6">
                                                                <div class="row mt-3">
                                                                    <div class="col-sm-12">
                                                                        <div class="postion-bx-min mt-2 ">
                                                                            <span class="frm-hd-t">Stop Loss</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class=" mt-1">
                                                                        <div class="col-sm-12">
                                                                            <div class="postion-min-dta postion-frm">
                                                                                {/* <!-- stop loss --> */}
                                                                                {/* <div class="row">
                                                                                    <div class="col-sm-12 radio-input">
                                                                                        <input class="form-check-input " type="radio" name="gridRadios5 " onClick={() => setCheckStopLoss(true)} required="" id="gridRadios1" value="option1" checked={checkStopLoss === true ? true : false} />
                                                                                        <label class="form-check-label " for="gridRadios5"> Stop Loss Price $</label>
                                                                                    </div>
                                                                                    <div class="row mb-3 mt-2">
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" disabled={checkStopLoss === true ? false : true} name='stopLossPrice' id="autoSizingInput" placeholder="0" onChange={handleChangeOpenInput} />
                                                                                            {errors.stopLossPrice && touched.stopLossPrice ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.stopLossPrice}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 radio-input">
                                                                                        <input class="form-check-input " type="radio" name="gridRadios6 " onClick={() => setCheckStopLoss(false)} required="" checked={checkStopLoss === false ? true : false} id="gridRadios6" value="option1" />
                                                                                        <label class="form-check-label " for="gridRadios6"> GSLO Price $</label>
                                                                                    </div>
                                                                                    <div class="row mb-3 mt-2">
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" name='gsloPrice' disabled={checkStopLoss === false ? false : true} id="autoSizingInput" placeholder="" onChange={handleChangeOpenInput} />
                                                                                            {errors.gsloPrice && touched.gsloPrice ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.gsloPrice}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 radio-input">
                                                                                        <label class="form-check-label " for="gridRadios7"> GSLO Cost In Price {portfolioCurrency} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                    </div>
                                                                                    <div class="row mb-4 mt-2">
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" id='restrictedInput' class="form-control" name='gsloCost' onBlur={hideOnBlur} value={gsloCost} placeholder="0" onChange={handleChangeOpenInput} />
                                                                                            {errors.gsloCost && touched.gsloCost ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.gsloCost}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12 radio-input">
                                                                                        <label class="form-check-label " for="gridRadios8"> GSLO Trading Fee {portfolioCurrency}</label>
                                                                                    </div>
                                                                                    <div class="row mb-3 mt-2">
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" disabled name='gsloTradingFee' id="autoSizingInputgsloTradingFee" placeholder="" />
                                                                                            {errors.gsloTradingFee && touched.gsloTradingFee ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.gsloTradingFee}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <!-- stop loss --> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <!-- <div class="col-6"> --> */}
                                                            <div class="col-sm-6 mt-3">
                                                                <div class="row">
                                                                    <div class="col-sm-12">
                                                                        <div class="postion-bx-min mt-2 ">
                                                                            <span class="frm-hd-t">Entered Stop Loss</span>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div class="row mt-1"> */}
                                                                    <div class="col-sm-12 mt-1">
                                                                        <div class="postion-min-dta postion-frm">
                                                                            <div class="row">
                                                                                <div class="col-sm-12">
                                                                                    <div class="row mb-3">
                                                                                        <label for="inputEmail3" class="col-sm-12 col-form-label"> Form Transaction Price %</label>
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" disabled class="form-control" name='fromTransPricePercent' id="autoSizingInputfromTransPricePercent" placeholder="" />
                                                                                            {errors.fromTransPricePercent && touched.fromTransPricePercent ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.fromTransPricePercent}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12">
                                                                                    <div class="row mb-3">
                                                                                        <label for="inputEmail3" class="col-sm-12 col-form-label"> Form Transaction Price {portfolioCurrency}</label>
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" disabled name='fromTransPriceDollar' id="autoSizingInputfromTransPriceDollar" placeholder="" />
                                                                                            {errors.fromTransPriceDollar && touched.fromTransPriceDollar ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.fromTransPriceDollar}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12">
                                                                                    <div class="row mb-3">
                                                                                        <label for="inputEmail3" class="col-sm-12 col-form-label"> AT Risk % *</label>
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" disabled name='atRiskPercent' id="autoSizingInputatRiskPercent" placeholder="" />
                                                                                            {errors.atRiskPercent && touched.atRiskPercent ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.atRiskPercent}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-12">
                                                                                    <div class="row mb-3">
                                                                                        <label for="inputEmail3" class="col-sm-12 col-form-label"> AT Risk * {portfolioCurrency}</label>
                                                                                        <div class="col-sm-12 ms-auto">
                                                                                            <Field type="text" class="form-control" disabled name='atRiskDollar' id="autoSizingInputatRiskDollar" placeholder="" />
                                                                                            {errors.atRiskDollar && touched.atRiskDollar ? (
                                                                                                <div className='color-msg-err position-absolute'>{errors.atRiskDollar}</div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="d-grid gap-2 d-sm-block mt-3 d-md-flex">
                                                            <button class="btn " type="submit">Save</button>
                                                            {/* {
                                                                showSaveButton ?
                                                                    <button type='button' className='btn loading-button position-relative d-flex'>
                                                                        <div class="dot1"> </div>
                                                                        <div class="dot2"></div>
                                                                        <div class="dot3"></div>
                                                                    </button> :

                                                                    <button class="btn " type="submit">Save</button>
                                                            } */}
                                                            <button class="btn ms-2" type="button" data-bs-dismiss="modal" id="openTradeModelClose" aria-label="Close" onClick={handleRemoveOpenData}>Cancel</button>
                                                        </div>
                                                      
                                                        {/* <!-- <div class="col-12"> --> */}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            {/* <!-- trs det --> */}
                        </div>
                    </div>
                </div>
            </div>
            <CummissionDetails cummissionData={cummissionData} />
        </>
    )
}

export default OpenTrade