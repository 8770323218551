import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const getCountryListAPI = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCountry`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
        
    }
}

export const getExchangeRateAPI = async (from,to) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetExchangeRate/${from}/${to}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
        
    }
}
export const getNews = async (category = '', from = '', to = '') => {
    try {
        const queryParameters = [];
        if (category) {
            queryParameters.push(`topics=${category}`);
        }
        if (from) {
            queryParameters.push(`timefrom=${from}`);
        }
        if (to) {
            queryParameters.push(`timeto=${to}`);
        }
        
        const queryString = queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';

        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetNews${queryString}`,
            mode: 'cors',
            headers,
        });

        if (response?.status === 200) {
            return response;
        } else if (response?.status === 401) {
            alert('Not authorized.');
        } else if (response?.status >= 500) {
            // Handle other errors if needed.
        }
    } catch (error) {
        // Handle any request error.
    }
}