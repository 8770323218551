import React, { useEffect } from 'react'
import { useState } from 'react'
import { BiSearchAlt } from 'react-icons/bi'
import { AiOutlineStar, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const Indicators = ({changeIndicators, selectedFullChart}) => {
    const [searchIndicator, setSearchIndicator] = useState('')
    const [selectedTabInd, setSelectedTabInd] = useState('Indicators')
    const [filteredIndicator, setFilteredindicator] = useState([])
    const indicatorTypeOption = [
        {
            name: 'Exponential Moving Average (EMA)',
            type: 'ema',
            icon: '',
            val1: 20,
            show:true,
            col1:'#bb6363',
            svType:'close',
            colWidth:2,
            chartType:'line',
            mainShowHide:true
        },
        {
            name: 'Accumulation Distribution Line',
            type: 'adl',
            icon: '',
            show:true,
            chartType:'line',
            col1:'#3c5ec3',
            mainShowHide:true
        },
        {
            name: 'Adaptive Moving Average (AMA)',
            type: 'ama',
            icon: '',
            val1:10,
            val2:5,
            val3:20,
            show:true,
            chartType:'line',
            col1:'#089981',
            col2:'#ec2d3c',
            col3:'#3c5ec3',
            mainShowHide:true
        },
        {
            name: 'Average True Range (ATR)',
            type: 'atr',
            icon: '',
            val1:1,
            show:true,
            mainShowHide:true,
            chartType:'line',
            col1:'#3c5ec3',
        },
        {
            name: 'Bollinger Bands',
            type: 'bbands',
            icon: '',
            val1:10,
            val2:5,
            show:true,
            chartType:'line',
            col1:'#bf360c',
            chartType1:'line',
            col2:'#ff6600',
            chartType2:'line',
            col3:'#bf360c',
            mainShowHide:true
        },
        {
            name: 'Directional Movement Index',
            type: 'dmi',
            icon: '',
            val1:14,
            val2:14,
            show:true,
            mainShowHide:true,
            chartType:'line',
            col1:'#bf360c',
            chartType1:'line',
            col2:'#ff6600',
            chartType2:'line',
            col3:'#bf360c',
        },
        {
            name: 'Envelope',
            type: 'env',
            icon: '',
            val1:7,
            val2:9,
            show:true,
            mainShowHide:true,
            chartType:'line',
            col1:'#bf360c',
            chartType1:'line',
            col2:'#ff6600',
        },
        {
            name: 'Heikin Ashi',
            type: 'ha',
            icon: '',
            col1:"#089981",
            col2:"#ec2d3c",
            chartType:'candlestick',
            show:true,
            mainShowHide:true
        },
        {
            name: 'Moving Average Convergence Divergence',
            type: 'macd',
            icon: '',
            val1:8,
            val2:7,
            val3:6,
            col1:"#089981", 
            col2:"#ec2d3c",
            col3:'#bf360c',
            chartType:'line',
            chartType1:'line',
            chartType2:'line',
            show:true,
            mainShowHide:true
        },
        {
            name: 'Parabolic SAR',
            type: 'psar',
            icon: '',
            val1:0.2,
            val2:0.2,
            val3:0.2,
            show:true,
            mainShowHide:true,
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Price Channels',
            type: 'priceChannels',
            icon: '',
            val1:10,
            show:true,
            mainShowHide:true,
            chartType:'area',
            col1:'#089981',
            col2:'#ec2d3c'
        },
        {
            name: 'Price Oscillator indicator',
            type: 'ppo',
            icon: '',
            val1:12,
            val2:26,
            val3:9,
            show:true,
            mainShowHide:true,
            chartType:'line',
            chartType1:'line',
            chartType2:'line',
            col1:'#089981',
            col2:'#ec2d3c',
            col3:'#ec2d3c'
        },
        {
            name: 'Psychological Line',
            type: 'psy',
            icon: '',
            val1:11,
            show:true,
            mainShowHide:true,
            col1:'#089981',
            col2:'#ec2d3c',
            chartType:'area',
        },
        {
            name: 'Rank Correlation Index',
            type: 'rci',
            icon: '',
            val1:12,
            show:true,
            mainShowHide:true,
            col1:"#089981", 
            col2:"#ec2d3c",
            chartType:'area',
        },
        {
            name: 'Rate of change',
            type: 'roc',
            icon: '',
            val1:3,
            show:true,
            mainShowHide:true,
            chartType:'column',
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Relative Strength Index',
            type: 'rsi',
            icon: '',
            val1:9,
            show:true,
            mainShowHide:true,
            chartType:'column',
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Simple Moving Average',
            type: 'sma',
            icon: '',
            val1:20,
            show:true,
            mainShowHide:true,
            chartType:'column',
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Stochastic',
            type: 'stochastic',
            icon: '',
            val1:10,
            val2:10,
            val3:20,
            show:true,
            mainShowHide:true,
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Triple Exponential Moving Average',
            type: 'trix',
            icon: '',
            val1:5,
            val2:1,
            show:true,
            mainShowHide:true,
            col1:"#089981", 
            col2:"#ec2d3c",
        },
        {
            name: 'Volume Moving Average',
            type: 'volumeMa',
            icon: '',
            val1:10,
            show:true,
            col1:"#00838f",
            col2:'#00838f'
        },
        {
            name: 'WilliamsR',
            type: 'williamsR',
            icon: '',
            show:true,
            mainShowHide:true,
            chartType:'line',
            col1:"#089981",
            col2:"#ec2d3c",
        },
        // {
        //     name: 'Aroon',
        //     type: 'aroon',
        //     icon: '',
        //     show:true,
        //     mainShowHide:true
        // }
    ]
    useEffect(() => {
        setFilteredindicator(indicatorTypeOption)
    }, [indicatorTypeOption.length])

    const handleFilterIndicator = (e) => {
        setSearchIndicator(e.target.value)
        if (e.target.value.length > 2) {
            let n = indicatorTypeOption.filter((item) => {
                let name = item.name.toLowerCase()
                let enteredName = e.target.value.toLowerCase()
                return name.includes(enteredName)
            })
            if (n.length == 0) {
                let s = indicatorTypeOption.filter((item) => {
                    let type = item.type.toLowerCase()
                    let enteredName = e.target.value.toLowerCase()
                    return type.includes(enteredName)
                })
                setFilteredindicator(s)
            } else {
                setFilteredindicator(n)
            }

        } else {
            setFilteredindicator(indicatorTypeOption)
        }
    }
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="indicators_view" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg  modal-dialog-centered technical_bg_indicat">
                <div className="modal-content">
                    <div className="modal-header p-0 border-bottom-0">
                        <h3>Technical Indicators</h3>
                        <button type="button" className="btn-close btn-close-provider" id='IndicatorListModal' data-bs-dismiss="modal" aria-label="Close">×</button>
                    </div>
                    <div>
                        <button class="nav-link search-input select_stock_input_field_search w-100">
                            <input name='search' value={searchIndicator} onChange={handleFilterIndicator} className='search-input-main w-100' placeholder='search by code...' />
                            <span className='search-input-charting-level' ><BiSearchAlt /></span>
                        </button>
                    </div>
                    <div className='d-flex main_div_ind' >
                        <div className='w-25 pe-2 indicator_div_left common_active_div' >
                            <ul class="nav nav-pills nav-justified nav-stacked" id="pills-tab" role="tablist">
                                <li className='w-100' onClick={() => setSelectedTabInd('Favorites')} ><button class={selectedTabInd == 'Favorites' ? "nav-link active color-thumb w-100" : "nav-link color-thumb w-100"} id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-Favorites"
                                    aria-selected="false" ><span >Favorites</span></button></li>
                                {/* <li ><button class={selectedTabInd=='watch'?"nav-link active color-thumb":"nav-link color-thumb"} id="pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-strategy" type="button" role="tab" aria-controls="pills-home"
                            aria-selected="false" ><span >WatchList</span></button></li> */}

                                <li className='w-100 mt-2' onClick={() => setSelectedTabInd('Indicators')} ><button class={selectedTabInd == 'Indicators' ? "nav-link active color-thumb w-100" : "nav-link color-thumb w-100"} id="pills-Trade-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-Trade" type="button" role="tab" aria-controls="pills-Indicators"
                                    aria-selected="false"><span >Indicators</span></button></li>

                            </ul>
                        </div>
                        <div class="tab-content mt-0 w-75 " id="pills-tabContent">

                            <div class={selectedTabInd == 'Indicators' ? "tab-pane fade show active" : "tab-pane fade show"} id="pills-Indicators" role="tabpanel"
                                aria-labelledby="pills-profile-tab">
                                <div className='indicator_div stockListScrollar' >
                                    {
                                        filteredIndicator.map((val, ind) => {
                                            return (
                                                <>

                                                    <p onClick={()=>changeIndicators(val,true, selectedFullChart)} > <span className='favorite_icon_main'  >
                                                        <span className='favorite_icon' ><AiOutlineStar /></span>
                                                    </span> {val.name}</p>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div class={selectedTabInd == 'Favorites' ? "tab-pane fade show active" : "tab-pane fade show"} id="pills-Favorites" role="tabpanel"
                                aria-labelledby="pills-profile-tab">
                                <div className="modal-body blackout-bx stockListScrollar">


                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Indicators