import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment/moment'
import { getOpentradeListFinancelYear } from '../services/TradeService'
import { getTransactionListFinancialAPI } from '../services/ManageAccountServices';
function Records() {
    const [paginationTrade, setPaginationTrade] = useState({ currentPageTrade: 1, pageNumberTrade: 1, pageSizeTrade: 10, totalPagesTrade: 0 })
    const { currentPageTrade, pageNumberTrade, pageSizeTrade, totalPagesTrade } = paginationTrade
    const [openTradeList, setOpenTradeList] = useState([])
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 10, totalPages: 0 })
    const { pageNumber, pageSize, currentPage, totalPages } = pagination
    const [transactionList, setTransactionList] = useState([])
    const location = useLocation();
    const userType = location.state?.userType;
    const selectedUserData = location.state?.selectedUserData;
    const currencySymbol = location.state?.currencySymbol;
    // const pageSize = location.state?.pageSize;
    // const currentPage = location.state?.currentPage;
    // const pagination = location.state?.pagination;


    useEffect(() => {
        // console.log(selectedUserData,location.state, "selectedUserData");
    }, [selectedUserData]);



    // useEffect(() => {
    //     let paramData = {
    //         PortfolioId: selectedUserData?.portFolioId,
    //         id: userType?.userId,
    //         pageNumber: currentPage,
    //         pageSize: pageSize,
    //         dateFrom: selectedUserData?.dateFrom,
    //         dateTo: selectedUserData?.dateTo
    //     }
    //     // console.log(paramData ,"payload")
    //     if (paramData.id !== undefined && paramData.PortfolioId !== undefined) {
    //         try {
    //             getTransactionListFinancialAPI(paramData).then((res) => {
    //                 if (res?.status === 200) {
    //                     console.log(res.data.data, "transactionlist")
    //                     setTransactionList(res.data.data)
    //                     // setPagination({ ...pagination, totalPages: res.data.totalPages })
    //                 }
    //             })
    //         } catch (error) {

    //         }
    //     }


    // }, [currentPage, userType, selectedUserData?.portFolioId, selectedUserData])

    const handlePageChange = (newPage) => {
        setPagination({ ...pagination, currentPage: currentPage + newPage })
        // Perform data fetching or other actions when page changes
    };


    useEffect(() => {
      debugger
        let data = {
            // providerId: selectedUserData.providerId, 
            portfolioId: selectedUserData?.portFolioId,
            id: userType?.userId,
            pageNumber: currentPageTrade,
            pageSize: pageSizeTrade,
            dateFrom: selectedUserData?.dateFrom,
            dateTo: selectedUserData?.dateTo
        }
        if (data.id !== undefined && data.portfolioId !== undefined) {
            try {
                getOpentradeListFinancelYear(data).then((res) => {
                    if (res?.status === 200) {
                        setOpenTradeList(res.data.data)
                        setPaginationTrade({ ...paginationTrade, totalPagesTrade: res.data.totalPages })
                        let data = res.data
                        // let pft = 0
                        // let bs = 0
                        // let bcfd = 0, lev 

                        // lev = (bcfd / bs) * 100
                        // setShowData({ ...showData, profit: pft.toFixed(2), balanceinShares: bs, balanceinCFD: bcfd, leverage: lev })


                    } else {
                        // setShowData({ ...showData, balanceinShares: 0, balanceinCFD: 0 })
                    }
                })
            } catch (error) {

            }
        }

    }, [currentPageTrade, userType, selectedUserData?.providerId, selectedUserData])

    return (
        <>


            <div className="row mt-4">
                <div className="col-lg-6 col-sm-6">
                    <h3>Transaction Record</h3>
                </div>

                {/* <div className="col-lg-6 col-sm-5 ms-auto d-block text-end">
                    {
                        userType?.role === 'admin' ?
                            <Link to={'/admin'}><button className="btn bt-drk admin-btn-main" type="button" >Admin</button></Link>
                            :
                            <h6 className="btn bt-drk admin-btn-main" data-bs-toggle="modal" data-bs-target="#createStrategy">Strategy </h6>

                    }
                </div> */}

            </div>


            <div className="table-bx mt-4">
                <div className="row">
                    <div className="col-lg-12  orc-table text-center">
                        <div className="table-responsive ">

                            <table className="table border" id='accordionExample1'>
                                <thead className="table-dark" id="headingOne"
                                >
                                    <tr >
                                        <th scope="col" className="col-2">Transaction ld</th>
                                        <th scope="col" className="col-2">Type</th>
                                        <th scope="col" className="col-1">Date</th>
                                        <th scope="col" className="col-2">Amount <span className='currency_code'>({currencySymbol?.code})</span></th>
                                        <th scope="col" className="col-3">Running Transaction <span className='currency_code'>({currencySymbol?.code})</span></th>
                                        <th scope="col" className="col-2">Comments</th>
                                        <th scope="col" className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"></th>

                                    </tr>
                                </thead>
                                <tbody id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">

                                    {
                                        transactionList.map((val, ind) => {
                                            return (
                                                <tr key={ind} className="accordion-body" >
                                                    <th>{val.transactionNo}</th>
                                                    <td>{val.type}</td>
                                                    <td>{moment(val.transDate).format('DD MMM YYYY')}</td>
                                                    <td>{currencySymbol?.symbol} {val.amount} </td>
                                                    <td>{currencySymbol?.symbol} {val.runningBalance} </td>
                                                    <td>{val.comments}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })
                                    }





                                </tbody>
                            </table>

                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                {/* <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    setPagination={setPagination}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    pagination={pagination}
                                /> */}
                            </div>


                        </div>
                    </div>
                    
                    <div className="row mt-4">
                    <div className="col-lg-6 col-sm-6">
                        <h3>Stock Record</h3>
                    </div>

                </div>

                <div className="col-lg-12  orc-table text-center">
                    <div className="col-lg-12  orc-table text-center">
                        <div className="table-responsive mb-5">
                            <table className="table border" id='accordionExample'>
                                <thead className="table-dark " id="headingOne">
                                    <tr>
                                        <th scope="col" >Name</th>
                                        {/* <th scope="col" >Product</th> */}
                                        <th scope="col" >Open/Close</th>
                                        <th scope="col" >Spread</th>
                                        {/* <th scope="col" >Close</th> */}
                                        <th scope="col" >Date</th>
                                        <th scope="col" >Quantity</th>
                                        <th scope="col" >Buy Price <span className='currency_code'>({currencySymbol?.code})</span></th>
                                        {/* <th scope="col" >Sell</th> */}
                                        <th scope="col" >Margin</th>
                                        <th scope="col" className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" ></th>

                                    </tr>
                                </thead>
                                <tbody id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    {
                                        openTradeList?.map((val, ind) => {
                                            return (
                                                <tr key={ind}>
                                                    <th >&nbsp;{val.stockCode}</th>
                                                    {/* <td>&nbsp;{val.stockCode}</td> */}
                                                    <td>&nbsp;{val.isOpen ? 'Open' : 'Close'}</td>
                                                    <td>&nbsp;{val.short}</td>
                                                    <td>&nbsp;{moment(val.openDate).format('DD MMM YYYY')}</td>
                                                    <td>&nbsp;{val.quantity}</td>
                                                    <td>&nbsp;{currencySymbol?.symbol} {val.entryPrice} </td>
                                                    {/* <td>&nbsp;</td>  */}
                                                    <td>&nbsp;{val.margin} %</td>
                                                    <td></td>
                                                    {/* <td>&nbsp;</td> */}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

                                {/* <Pagination
                                    currentPage={currentPageTrade}
                                    totalPages={totalPagesTrade}
                                    onPageChange={handlePageChangeTrade}
                                    setPagination={setPaginationTrade}
                                    pageNumber={pageNumberTrade}
                                    pageSize={pageSizeTrade}
                                    pagination={setPaginationTrade}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>


        </>
    )
}

export default Records