import React from 'react';

const Pagination = (props) => {
  const {currentPageTrade, pageNumberTrade,nextLoading, pageSizeTrade,totalPagesTrade, currentPage, totalPages,pagination, onPageChange,pageSize,setPagination,pageNumber }=props
  const handlePageChange = (newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };
  const handleDecreasePage=()=>{
    onPageChange(-1)
    // setPagination({...pagination,currentPage:currentPage-1})
  }
  const handleIncreasePage=()=>{
    onPageChange(1)
    // setPagination({...pagination,currentPage:currentPage+1})
  }

  return (
    totalPages!=0?
    <div className='pagination-container'>
      <button className=' pagination-button white_text_next' type='button'
        
        onClick={handleDecreasePage}
        disabled={currentPage === 1 ||nextLoading==true}
      >
        Previous
      </button>
      <span className='white_text_next' >
        Page {currentPage} of {totalPages}
      </span>
      <button className=' pagination-button white_text_next' type='button'
        onClick={handleIncreasePage}
        disabled={currentPage === totalPages||totalPages==0 ||nextLoading==true}
      >
        Next
      </button>
    </div>:<div className='w-100 text-center white_text_next'>
    <h4 className='no-data white_text_next'>No data found</h4>
    </div>
  );
};

export default Pagination;
