import React, { useEffect, useState } from 'react'
import { IoAnalyticsOutline } from 'react-icons/io5';
import $ from 'jquery'

$(document).ready(function () {
    $(document).click(function () {
        $('.dropdownmenuInd.show').removeClass('show');
    });
    $('body').on('click', '.autoTriggerDropIndi', function (e) {
        e.stopPropagation();
        $(this).closest('.aptoDropDownWrapIndi').find('.dropdownmenuInd').toggleClass('show');
    });
});
const IndicatorsChartType = ({selectedFullChart, types, changeIndicators,setOldIndicatorValue, oldIndicatorValue, chartTypeOption }) => {
    
    const [chartTypeIndi, setChartTypeIndi] = useState(<svg viewBox="0 0 595.3 841.9" enable-background="new 0 0 595.3 841.9" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M142 447c-102 102-83 83 0 0m399-186l-70 94H370l-136 159-91-112-38 41L6 542l41 41 94-94 91.6 112 162.6-186h103.5l88-118z"></path></g></svg>)
    useEffect(() => {
        if (oldIndicatorValue.chartType) {
            let preSelectedChartType = chartTypeOption.filter((val) => val.key == oldIndicatorValue[types])
            // console.log(preSelectedChartType)
            setChartTypeIndi(preSelectedChartType[0]?.icon)
        }
        // type  
    }, [oldIndicatorValue.type])
    const handleSelectIndChartType = (e,value) => {
        e.preventDefault();
        setChartTypeIndi(value.icon)
        setOldIndicatorValue({ ...oldIndicatorValue, [types]:value.key })
        let funVal={
             ...oldIndicatorValue, [types]:value.key 
        }
        changeIndicators(funVal,false, selectedFullChart)
    }
    return (
        <>
            <div class="apto-dropdown-wrapper aptoDropDownWrapIndi">
                <button type='button' class="apto-trigger-dropdown autoTriggerDropIndi">
                    {chartTypeIndi}
                    <i class="fas fa-caret-down"></i>
                </button>

                <div class="dropdown-menu chartTypeInd dropdownmenuInd" data-selected="messenger">
                    {
                        chartTypeOption.map((val, ind) => {
                            if (val.type != 'bar') {
                                return (
                                    <button type="button" style={{'backgroundColor':oldIndicatorValue?.chartType==val.key&&'#dedede'}} value="messenger" onClick={(e) => handleSelectIndChartType(e,val)} tabindex="0" class="dropdown-item dropdownitemIndi">
                                        {val.icon}
                                    </button>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default IndicatorsChartType