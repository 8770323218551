import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Analysis = () => {
    const [country, setCountry] = useState('america');
    let width = window.innerWidth;
    let height = window.innerHeight;

    useEffect(() => {
        const existingScript = document.getElementById('tradingview-widget-script');
        if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
        }

        const script = document.createElement('script');
        script.id = 'tradingview-widget-script';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
        script.async = true;
        script.innerHTML = `
            {
                "width": ${width},
                "height": ${height - 40},
                "defaultColumn": "overview",
                "defaultScreen": "most_capitalized",
                "market": "${country}",
                "showToolbar": true,
                "colorTheme": "light",
                "locale": "en"
            }
        `;

        // Define a callback function to handle script load errors
        const handleScriptError = () => {
            console.error('Error loading TradingView widget script');
        };

        script.addEventListener('error', handleScriptError);

        const container = document.getElementById('tradingview-widget');
        if (container) {
            container.appendChild(script);
        }

        // Cleanup script and event listener on component unmount
        return () => {
            script.removeEventListener('error', handleScriptError);
            if (container && container.contains(script)) {
                container.removeChild(script);
            }
        };
    }, [country, width, height]);

    const handleChangeCountry = (e) => {
        setCountry(e.target.value);
    };

    return (
        <div class="position-relative">
            <div class="position-country_select">
                <select value={country} onChange={handleChangeCountry}>
                    <option value={'america'}>USA</option>
                    <option value={'australia'}>AUS</option>
                </select>
            </div>
            <div className="tradingview-widget-container" id="tradingview-widget">
                <div className="tradingview-widget-container__widget"></div>
                <div className="tradingview-widget-copyright">
                    <Link href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank">
                        {/* <span className="blue-text">Track all markets on TradingView</span> */}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Analysis;
