import React from 'react'
import { IoAnalyticsOutline } from 'react-icons/io5';
import { BiChevronDown } from "react-icons/bi";
import { BiBarChartAlt2 } from "react-icons/bi";
import $ from 'jquery'
import { useState } from 'react';
import { useEffect } from 'react';


$(document).ready(function () {
    $(document).click(function () {
        $('.dropDownMenuChart.show').removeClass('show');
    });

    $('body').on('click', '.autoTriggerDropChart', function (e) {

        e.stopPropagation();

        $(this).closest('.aptoDropDownWrapChart').find('.dropDownMenuChart').toggleClass('show');
    });




});

const ChartToolDropdown = ({ handleChangeChartType, chartTypeOption }) => {
    const [chartType, setChartType] = useState()
    $(document).ready(function () {
        $('body').on('click', '.dropdownitemChart', function (e) {
            e.stopPropagation();

            let $selectedValue = $(this).val();
            let $icon = $(this).find('svg');
            let $btn = $(this).closest('.aptoDropDownWrapChart').find('.autoTriggerDropChart');

            $(this).closest('.aptoDropDownWrapChart').find('.dropDownMenuChart').removeClass('show').attr('data-selected', $selectedValue);

            setChartType($selectedValue)
            $btn.find('svg').remove();
            $btn.prepend($icon[0]?.outerHTML);
        });

    });
    useEffect(() => {
        if (chartType) {
            handleChangeChartType(chartType)
        }
    }, [chartType])
    return (
        <>
            <button class="btn ms-2 drop_down_sec">
                <div class="aptoDropDownWrapChart apto-dropdown-wrapper">
                    <button class="autoTriggerDropChart apto-trigger-dropdown">
                    <svg viewBox="0 0 26 26" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M16 3v3h-2v12h2v5h1v-5h2V6h-2V3h-1zM9 4v5H7v11h2v3h1v-3h2V9h-2V4H9zm-1 6h3v9H8v-9z"></path></g></svg>
                        <span>Charts</span>
                        <i class="bi bi-caret-down-fill"></i>
                    </button>

                    <div class="dropDownMenuChart dropdown-menu" data-selected="messenger">
                        {
                            chartTypeOption.map((val, ind) => {
                                return (
                                    <button type="button" value={val.key} tabindex="0" class="dropdown-item dropdownitemChart">
                                        {val.icon}
                                        {val.name}
                                    </button>
                                )
                            })
                        }
                        {/* <button type="button" value="step-line" tabindex="0" class="dropdown-item">
                            
                            Messenger
                        </button>
                        <button type="button" value="hilo" tabindex="0" class="dropdown-item">
                            <IoAnalyticsOutline />
                            WhatsApp
                        </button>
                        <button type="button" value="telegram" tabindex="0" class="dropdown-item">
                            <IoAnalyticsOutline />
                            Telegram
                        </button>
                        <button type="button" value="skype" tabindex="0" class="dropdown-item">
                            <IoAnalyticsOutline />
                            Skype
                        </button>
                        <button type="button" value="viber" tabindex="0" class="active dropdown-item active">
                            <BiBarChartAlt2 />
                            Viber
                        </button> */}

                    </div>
                </div>
            </button>
        </>
    )
}

export default ChartToolDropdown
