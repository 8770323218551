import React, { useState } from "react";
import NewsHeader from "./NewsHeader";
import NewsLeftSidebar from "./NewsLeftSidebar";
import NewsBody from "./NewsBody";

const News = () => {
    const [newsData, setNewsData] = useState([]);
    const [currentDate, setCurrentDate] = useState([]);
    const [formatedendDate, setFormatedEndDate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="news">
          
            <div class="row">
                <div class="col-12 col-xm-12 col-md-12 col-lg-3 col-xl-2 col-xxl-2 bg_sec_sd" >
                  <div class="main_menu">
                        <div className="NewsHeading">
                            <h1 style={{color:"white"}}>News</h1>
                        </div>
                        <NewsHeader setCurrentDate={setCurrentDate} setFormatedEndDate={setFormatedEndDate} currentDate={currentDate} />
                        <NewsLeftSidebar setIsLoading={setIsLoading} formatedendDate={formatedendDate} currentDate={currentDate} setNewsData={setNewsData} />
                    </div>
                </div>
                <div class="col-12 col-xm-12 col-md-12 col-lg-9 col-xl-10 col-xxl-10" >
                    <NewsBody className="body_news_sec" isLoading={isLoading} newsData={newsData} />
                </div>
            </div>
        </div>
    );
}

export default News;

