import React, { useEffect, useState } from 'react'
import { deleteProviderCommission, updateProviderCommission } from '../../../services/ProviderServices'
import DeleteConfirmation from '../DeleteConfirmation'
import { toast } from 'react-toastify'

const CummissionDetails = ({ setDeleteConfirmCommi, deleteConfirmCommi, cummissionData, HandleViewCummission, setDeleteMsg, setDeleteType, deleteType, setDeleteConfirmData }) => {
    const [cummissionList, setCummissionList] = useState([])
    const [actionEdit, setActionEdit] = useState(false)
    const [editCommissionData, setEditCommissionData] = useState({})
    const [deleteConfirmcommissionData, setDeleteConfirmcommissionData] = useState('')
    const [popUpShow, setPopUpShow] = useState(null)

    const showToast = (message,type) => {
        if (popUpShow) {
            toast.dismiss(popUpShow);
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
              });
              setPopUpShow(newToast);
          } else {
            // If no active toast, show a new one
            const newToast = toast.error(message, {
              autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
    };
    const showToastSucc = (message) => {
    
        if (popUpShow) {
            toast.dismiss(popUpShow);
            const newToast = toast.success(message, {
                autoClose: 3000, // Set the duration as needed
              });
              setPopUpShow(newToast);
          } else {
            // If no active toast, show a new one
            const newToast = toast.success(message, {
              autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
        // setTimeout(()=>{
        //     setPopUpShow(null);
        // },3500)
    };
    useEffect(() => {
        setCummissionList(cummissionData)
    }, [cummissionData])
    const HandleEditCommission = (val) => {
        setEditCommissionData(val)
       
        setActionEdit(true)
    }
    const handleChangeCommissionInput = (e) => {
        if (e.target.value >= 0 || e.target.value == '.') {

            let n = e.target.value.split('.')
            var roundedNumber = e.target.value;
            var decimalIndex = roundedNumber.indexOf(".");
            if (decimalIndex !== -1) {
                var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                if (decimalPlaces > 2) {
                    roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                }
            }
      
            if (n.length == 2) {
                if (n[0].length <= 10) {
                    setEditCommissionData({ ...editCommissionData, [e.target.name]: roundedNumber })
                }
            } else if (n.length == 1 && e.target.value.length <= 10) {
                setEditCommissionData({ ...editCommissionData, [e.target.name]: roundedNumber })
            }
        } else if (e.target.value == '') {
            setEditCommissionData({ ...editCommissionData, [e.target.name]: e.target.value })
        }
    }
  
    const handleUpdateCommissionAction = () => {
       
        let data = editCommissionData
        if(Number(editCommissionData?.aboveTransValue)>0 && Number(editCommissionData?.upToTransValue)>0 && Number(editCommissionData?.commission)>0){
            try {
                updateProviderCommission(data).then((res) => {
                    if (res?.status === 200) {
                        // NotificationToast(response.data.message)
                        showToastSucc(res.data.message)
                        HandleViewCummission(data?.providerId)
                        setEditCommissionData({})
                        setActionEdit(false)
                    }
                })
            } catch (error) {
    
            }
        }else{
            showToast('Value must be greator then 0.')
        }
    }
    const handleDeleteCommission = (value) => {
        setDeleteConfirmcommissionData(value)
        setDeleteConfirmData(value.id)
        setDeleteType('commission')
        setDeleteMsg('delete this Commission')

    }

    const deleteConfirmCommission = () => {
        if (deleteType == 'commission') {

            try {
                deleteProviderCommission(deleteConfirmcommissionData.id).then((res) => {
                    HandleViewCummission(deleteConfirmcommissionData.providerId)
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        if (deleteConfirmCommi == true) {
            try {
                deleteProviderCommission(deleteConfirmcommissionData.id).then((res) => {
                    HandleViewCummission(deleteConfirmcommissionData.providerId)
                    setDeleteConfirmCommi(false)
                })
            } catch (error) {

            }
        }
    }, [deleteConfirmCommi])
    const handleCloseCommissionModel = () => {
        setEditCommissionData({})
        setActionEdit(false)
    }
    return (
        <>

            <div className="modal fade show backdrop-1 z-indez-2" data-bs-backdrop="static" id="cummissionDetails" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ "display": 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md commission_model_size  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Commission Details</h5>
                            <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseCommissionModel}>×</button>
                        </div>

                        <div className="modal-body blackout-bx">

                            <div class="row mt-1">
                                <div class="col-sm-12">
                                    <div class="postion-min-dta border-radius-10 postion-frm">
                                        <div class="table-responsive text-center ">
                                            <div class="table-input1">
                                                <table class="table text-center  table-input-data1">
                                                    <thead>
                                                        <tr class="border-bottom-0">
                                                            <th scope="row" class="ad-tab-b color-grey ">up to Trans Value</th>
                                                            <th scope="row" class="ad-tab-b color-grey ">above Trans Value</th>
                                                            <th scope="row" class="ad-tab-b color-grey">commission</th>
                                                            <th class="ad-tab-b border-end-0 color-grey">Edit</th>
                                                            <th className='ad-tab-b color-grey'>Delete</th>
                                                            {/* <th scope="row" class="ad-tab-b">Edit</th> */}
                                                        </tr>
                                                    </thead>
                                                    {
                                                        cummissionList.length > 0 &&
                                                        <tbody>
                                                            {
                                                                cummissionList?.map((val, ind) => {
                                                                    return (
                                                                        <tr key={ind}>{

                                                                            (actionEdit == true && editCommissionData?.id == val.id) ? <>

                                                                                <td class="text-center"><input className={actionEdit == true && editCommissionData?.upToTransValue == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='upToTransValue' value={editCommissionData?.upToTransValue} onChange={handleChangeCommissionInput} /></td>
                                                                                <td class="text-center"><input className={actionEdit == true && editCommissionData?.aboveTransValue == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='aboveTransValue' value={editCommissionData?.aboveTransValue} onChange={handleChangeCommissionInput} /></td>
                                                                                <td class="text-center"><input className={actionEdit == true && editCommissionData?.commission == '' ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='commission' value={editCommissionData?.commission} onChange={handleChangeCommissionInput} /></td>
                                                                                <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" onClick={() => handleUpdateCommissionAction()}> Save </button> </td>
                                                                            </> :
                                                                                <>
                                                                                    <td>$ {val.upToTransValue}</td>
                                                                                    <td>$ {val.aboveTransValue}</td>
                                                                                    <td>{val.type == "Fixed" && '$ '}{val.commission} {val.type == "Percent" && '%'}</td>
                                                                                    <td><button class="btn padding-btn-dtd edit-btn-adm" title="Edit" onClick={() => HandleEditCommission(val)}> <img src="assets/image/edit.png" class="img-fluid" /> Edit</button></td>
                                                                                </>
                                                                        }

                                                                            <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleDeleteCommission(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                                        </tr>
                                                                    )
                                                                })

                                                            }
                                                        </tbody>


                                                    }
                                                </table>
                                                {
                                                    cummissionList.length == 0 &&
                                                    <div class="w-100 text-center"><h4 class="no-data">No data found</h4></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <DeleteConfirmation type={deleteMsg} deleteConfirm={deleteConfirmCommission} /> */}
        </>
    )
}

export default CummissionDetails