import { Route, Routes, useLocation, useParams, useSearchParams } from 'react-router-dom';
import './assets/css/bootstrap.css';
import './assets/css/style.css'
import './assets/css/myStyle.css'
import './assets/css/animetion.css'
import './assets/css/loading.css'
import './assets/scss/anychart.css'
// import './assets/css/home-scss/loader.sass'
import Analysis from './pages/Analysis';
import Home from './pages/Home';
import AdminDashboard from './pages/AdminDashboard';
import Learn from './pages/Learn';
// import Tools from './pages/Tools';
import Trade from './pages/Trade';
import TradeCheck from './pages/TradeCheck';
import Provider from './pages/Provider';
import CandleChartView from './pages/CandleChartView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import Loader from './components/commonComponent/Loader';
// import TradingLoungeChart from './pages/TradingLoungeChart';
import AnyChartView from './pages/AnyChartView';
import News from './pages/NewsComponent/News';
import Records from './pages/Records';
import TradeChartingLavel from './pages/TradeChartingLavel';
// import './assets/js/bootstrap.js'
// import useAuth from './routeProtect/useAuth'

function App() {
  const [userType, setUserType] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();
  function caseInsensitiveGet(obj, key) {
    const lowercaseKey = key.toLowerCase();
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop) && prop.toLowerCase() === lowercaseKey) {
        return obj[prop];
      }
    }
    return undefined;
  }
  const params = Object.fromEntries(  
    new URLSearchParams(window.location.search)
  )
  const id = caseInsensitiveGet(params, 'userId');
  let rol=''
 
  if(id=="5" || id=="6" || id=="10" || id=="35" ){
    rol="admin";
  }else{
    rol="user";
  }

  useEffect(() => {
    let data = {
      userId: id,
      role: rol?.toLowerCase()
    }
    localStorage.setItem('userDetails', JSON.stringify(data))
    let nm = localStorage.getItem('userDetails')
    // setUserType(JSON.parse(nm))
    setUserType(data)
  }, [])
  // console.log(Window._pcq)
  // Window._pcq.push(['triggerOptIn',{subscriberSegment: 'homepage', modal: {text: 'Get instant alerts from our website. Please subscribe.', blackenBackground: true}}]); //Calls triggerOptIn with custom text, blackened background and segment as 'homepage'

  // const { home } = useAuth();
  return (
    <>
      <ToastContainer
        position="top-center"
        // limit={1}
        autoClose={3000}
        theme="dark"
      />
      <Routes>
      {/* <Route path='/' element={<Home/>}/> */}
        <Route path='/' element={userType?.role !== undefined?<Home/>:<Loader/>} />
        <Route path='*' element={userType?.role !== undefined?<Home/>:<Loader/>} />
        <Route path='/trade' element={<Trade/>} />
        <Route path='/admin' element={<AdminDashboard/>} />
        {/* <Route path='*' element={userType?.role == 'admin' ? <AdminDashboard/> :userType?.role == 'user' ? <Home/>:<Loader/>} /> */}
        {/* <Route path='/chart' element={<TradingLoungeChart/>} /> */}
         <Route path='/stock-screener' element={<Analysis/>} />
        {/* <Route path='/trade-check' element={<TradeCheck/>} />  */}
        <Route path='/learn' element={<Learn/>} />
        <Route path='/chart' element={<CandleChartView/>} />
        <Route path='/Charting-level1' element={<AnyChartView/>} />
        <Route path='/Charting-level' element={<TradeChartingLavel/>} />
        <Route path='/News' element={<News/>} />
        <Route path='/Records' element={<Records/>} />
      </Routes>
    </>
  );
}

export default App;
