import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { AddChartDraw, UpdateChartDraw } from '../../services/TradeService';
import anychart from 'anychart'
import xmljs from 'xml-js';

let validationSchema = Yup.object().shape({
    ChartName: Yup.string().required('Title is required'),
    // providerId: Yup.string().required('Provider is required'),
});
const SaveChart = ({selectedChartType,scrolar, selectedSeries, stockDetailValue, showEditChart, setShowEditChart, stockCode, fullChart, setRefressChartList }) => {
    const [chartInput, setChartInput] = useState({ ChartName: '', Description: '' })
    const { ChartName, Description } = chartInput

    const handleChangePortFolioInput = (e) => {
        setChartInput({
            ...chartInput, [e.target.name]: e.target.value
        })
    }
    const handleAddEditChart = () => {
        let userDetail = JSON.parse(localStorage.getItem('userDetails'))
        var xml = fullChart.toXml()
        var xmlAnnotation = fullChart.plot(0).annotations().toXml();
        const currentTimestampMilliseconds = new Date().getTime();
        const currentTimestampSeconds = Math.floor(currentTimestampMilliseconds / 1000);
        // console.log('selectedChartType',selectedChartType)
        const jsonBlob = new Blob([JSON.stringify(stockDetailValue)], { type: 'application/json' });
       
        const xmlChartFileName = 'xmlChart' +'_'+ currentTimestampSeconds+'_'+userDetail.userId
        const cmlAnnotationName = 'xmlAnnotation' +'_'+ currentTimestampSeconds+'_'+userDetail.userId
        const formData = new FormData();
        formData.append('UserId', userDetail.userId);
        formData.append('Description', Description);
        formData.append('StockCode', stockCode);
        formData.append('ChartName', ChartName);
        formData.append('ChartFile', xmlChartFileName);
        formData.append('ChartData', 'chartData');
        formData.append('ChartType', selectedSeries.key);
        formData.append('ChartAnnotationFile', cmlAnnotationName);
        formData.append('file', jsonBlob, `${xmlChartFileName}.json`);
        formData.append('file', new Blob([xmlAnnotation], { type: 'text/xml' }), `${cmlAnnotationName}.xml`);
        if(scrolar==1){

            try {
                AddChartDraw(formData).then((res) => {
                    if (res?.status === 200) {
                        document.getElementById('saveChartClose').click()
                        setRefressChartList(true)
                    }
                })
            } catch (error) {
    
            }
        }else{
            try {
                UpdateChartDraw(formData,selectedChartType.chartingLevelId).then((res) => {
                    if (res?.status === 200) {
                        document.getElementById('saveChartClose').click()
                        setRefressChartList(true)
                    }
                })
            } catch (error) {
    
            }
        }
    }
    const handleRemoveInputData = () => {

    }
    const handleBlurInputChartInput = (e) => {

    }
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" data-keyboard="false" id="saveChart" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ "display": 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-md  modal-dialog-centered">
                <div className="modal-content  provider-min-md">
                    <div className="modal-header border-bottom-0">
                        <h5 className="modal-title h4" id="exampleModalLgLabel">{scrolar==1?'Save':'Update'} Chart</h5>
                        <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" id='saveChartClose' aria-label="Close" onClick={handleRemoveInputData}>×</button>
                    </div>

                    <div className="modal-body blackout-bx">

                        <Formik
                            initialValues={chartInput}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={(value) => {
                                handleAddEditChart(value)
                                // alert(JSON.stringify(value))
                            }}

                        >
                            {({ errors, touched }) => (
                                <Form className='row p-3'>
                                    {/* <div class="col-sm-12"> */}
                                    <div class="col-12">
                                        <label>Chart Name</label>
                                        <Field type="text" class="form-control" value={ChartName} onBlur={handleBlurInputChartInput} name='ChartName' onChange={handleChangePortFolioInput} id="autoSizingInput" placeholder="" />
                                        {errors.ChartName && touched.ChartName ? (
                                            <div className='color-msg-err'>{errors.ChartName}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-12 position-relative mt-2">
                                        <label>Description</label>
                                        <Field type="text" as="textarea" name='Description' value={Description} className="form-control" id="formGroupExampleInput" onChange={handleChangePortFolioInput} />
                                        {errors.Description && touched.Description ? (
                                            <div className='color-msg-err'>{errors.Description}</div>
                                        ) : null}
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-md-6'>


                                            {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEditChart === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}
                                            <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit'> {'Save'}</button>


                                        </div>
                                        <div className='col-md-6'>
                                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-portFolio' type='button' onClick={handleRemoveInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                        </div>

                                    </div>

                                </Form>
                            )}

                        </Formik>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaveChart