import React from 'react'
import '../assets/css/anyChart.css'
import anychart from 'anychart'
import '../assets/scss/anychart.css'
import { useEffect } from 'react'
import { useState } from 'react'
import { TbColumns1 } from "react-icons/tb";
import { TbColumns2 } from "react-icons/tb";
import { MdDataSaverOn } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { BiLockOpen, BiLock, BiText, BiBarChartAlt2, BiChevronDown, BiCheckbox } from "react-icons/bi";
import { MdOutlineDeleteForever } from "react-icons/md";
import { BiDotsHorizontal } from "react-icons/bi";
import { CgEditMarkup } from "react-icons/cg";
import { CgEditShadows } from "react-icons/cg";
import { FaUserEdit } from "react-icons/fa";
import { CgEditFlipH } from "react-icons/cg";
import { BiMessageSquareEdit } from "react-icons/bi";
import { BiCreditCardFront } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineAppstore } from "react-icons/ai";
import { BiMove } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsBoxArrowInRight } from "react-icons/bs";
import $ from 'jquery'
import { SketchPicker } from 'react-color'
import Multiselect from 'multiselect-react-dropdown';
import { AiOutlineItalic, AiOutlineAlignCenter, AiOutlineVerticalAlignBottom, AiOutlineAlignLeft, AiOutlineVerticalAlignMiddle, AiOutlineAlignRight, AiOutlineVerticalAlignTop } from 'react-icons/ai'
import { FaBold, FaUnderline, FaStrikethrough, FaPencilAlt } from 'react-icons/fa'
import { MdFormatOverline, MdStart, MdTrendingFlat } from 'react-icons/md'
import { BiDownArrow } from 'react-icons/bi'
import SaveChart from '../components/AnyChart/SaveChart'
import XmlChartList from '../components/AnyChart/XmlChartList'
import process from "process";
import { getStockDetails, getXmlsChartData } from '../services/TradeService'
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineSaveAlt } from "react-icons/md"
import { AiOutlineFontSize, AiOutlineBorder, AiOutlineStock } from "react-icons/ai"
import { BiPencil, BiExitFullscreen, BiSave } from "react-icons/bi"
import { BsBorderOuter } from "react-icons/bs"
import { SiAdobefonts, SiMakerbot } from "react-icons/si"
import axios from 'axios'
import Indicators from '../components/AnyChart/Indicators'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { RxCross2 } from "react-icons/rx"
import AnychartSlideNav from '../components/AnyChart/AnychartSlideNav'
import { GrSelect } from 'react-icons/gr';
import { RxPieChart } from 'react-icons/rx';
import { AiOutlineBarChart } from 'react-icons/ai';
import { RiSearch2Line } from 'react-icons/ri';
import { MdOutlineZoomOutMap } from 'react-icons/md';
import { MdOutlineRectangle } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import IndicatorModal from '../components/AnyChart/IndicatorModal'
import ChartToolDropdown from "../components/AnyChart/ChartToolDropdown";
import { CiUnlock } from "react-icons/ci";
import { RxBorderDotted } from "react-icons/rx";
import { CgBorderStyleDashed } from "react-icons/cg";
import { TfiLayoutLineSolid } from "react-icons/tfi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { IoColorPaletteOutline } from "react-icons/io5";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { BsFullscreen } from "react-icons/bs";
import StocksList from '../components/AnyChart/StocksList'



const API_URL = process.env.REACT_APP_API_BASE_URL






const AnyChartView = () => {
  const [scrolar, setScrolar] = useState(1)
  const [reRenderChart, setReRenderChart] = useState(true)
  const [stockFullDetails, setStockFullDetails] = useState({})
  const [disableSeriesType, setDisableSeriesType] = useState(false)
  const [currentPrice, setCurrentPrice] = useState('')
  const [fullChart, setFullChart] = useState()
  const [fullChart2, setFullChart2] = useState()
  const [fullChart3, setFullChart3] = useState()
  const [fullChart4, setFullChart4] = useState()
  const [savePlot, setSavePlot] = useState()
  const [colorPicker, setColoerPicker] = useState({ type: '', showHide: false })
  const [colors, setColors] = useState({ series: '#DA2828', stroke: '#EBE2E2', fontCol: '#E6D0D0' })
  const [drawingVal, setDrawingVal] = useState({ drawCol: '#DA2828', drawWidth: 1, drawLock: false, drawDash: 'none', drawDashIcon: <TfiLayoutLineSolid /> })
  const { drawCol, drawWidth, drawLock, drawDashIcon, drawDash } = drawingVal
  const [makerVal, setMakerVal] = useState({ makerCol: '#DA2828', makerBorderCol: '#1F4CC6', makerBorder: 1, makerLock: false, makerDash: 'none', makerSize: 100, makerDashIcon: <TfiLayoutLineSolid /> })
  const { makerCol, makerBorder, makerLock, makerSize, makerBorderCol, makerDash, makerDashIcon } = makerVal
  const [selectedTab, setSelectedTab] = useState('stock')
  const { series, stroke, fontCol } = colors
  const [stockCode, setStockCode] = useState('')
  const [stockDetailValue, setStockDetailValue] = useState([])
  const { type, showHide } = colorPicker
  const [isDragging, setIsDragging] = useState(false);
  const [progressWidth, setProgressWidth] = useState(15);
  const [isDraggingMaker, setIsDraggingMaker] = useState(false);
  const [progressWidthMaker, setProgressWidthMaker] = useState(100);
  const [showFontDrager, setShowFontDrager] = useState(false)
  const [showFontDragerMaker, setShowFontDragerMaker] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState('light')
  const [themeToggle, setThemeToggle] = useState(false)
  const [stockSelect, setStockSelect] = useState({
    sWidth: {
      name: '2 px',
      id: '2'
    },
    sDash: {
      stroke: 'Strok dash',
      name: 'Solid',
      id: '6'
    }
  })
  const { sWidth, sDash } = stockSelect
  const [showMulti, setShowMulti] = useState(false)
  const [showFontStyle, setShowFontStyle] = useState(false)
  const [fontStyling, setFontStyling] = useState({ anchor: null, fontDecoration: null, fontWeight: null, hAlign: null, vAlign: null })
  const { anchor, fontDecoration, fontWeight, hAlign, vAlign } = fontStyling
  const [makerType, setMakerType] = useState('')
  const [refressChartList, setRefressChartList] = useState(true)
  const [labelText, setLabelText] = useState('')
  const [showBorderSetting, setShowBorderSetting] = useState(false)
  const [showMaker, setShowMaker] = useState(false)
  const [sriesTypeData, setSeriesTypeData] = useState()
  const [labelBorderSide, setLabelBorderSize] = useState()
  const [selectedSeries, setSelectedSeries] = useState({
    name: 'Candlestick',
    key: 'candlestick',
    icon: '',
    type: 'column'
  })

  const [mappingValue, setMappingValue] = useState()
  const [interValTime, setInterValTime] = useState(1)
  const [selectedIndicators, setSelectedIndicators] = useState([])
  const [modalShowIndi, setModalShowIndi] = useState(false);
  const [selectedIndicatorSetting, setSelectedIndicatorSetting] = useState()
  const [showEditTools, setShowEditTools] = useState({ textTool: false, makerTool: false, indicatorTool: false, drawingTool: false })
  const { textTool, makerTool, indicatorTool, drawingTool } = showEditTools
  const [toolEditPosition, setToolEditPosition] = useState({
    top: '10px',
    left: '0px'
  })
  const [splitedArea, setSplitedArea] = useState({
    area: 1,
    splitedAreaType: 1
  })
  const { area, splitedAreaType } = splitedArea
  const [selectedSplitedArea, setSelectedSplitedArea] = useState(1)
  const [selectedFullChart, setSelectedFullChart] = useState()
  const [fullScreen, setFullScreen] = useState(false)
  const [selectedChartType,setSelectedChartType]=useState()
  const { top, left } = toolEditPosition

  const options = [

    {
      stroke: 'Strok Width',
      name: '0 px',
      id: '0'
    },
    {
      name: '1 px',
      id: '1'
    },
    {
      name: '2 px',
      id: '2'
    },
    {
      name: '3 px',
      id: '3'
    },
    {
      name: '4 px',
      id: '4'
    },
    {
      name: '5 px',
      id: '5'
    },
    {
      stroke: 'Strok dash',
      name: 'Solid',
      id: '6'
    },
    {
      name: 'Dotted',
      id: '7'
    },
    {
      name: 'Dashed',
      id: '8'
    }
  ]
  const fontStyle = [
    {
      name: 'Italic',
      value: 'italic',
      icon: <AiOutlineItalic />,
      group: 'Font style',
      type: '0'
    },
    {
      name: 'Bold',
      value: 'bold',
      icon: <FaBold />,
      type: '0'
    },
    {
      name: 'Line-through',
      value: 'line-through',
      icon: <FaStrikethrough />,
      group: 'Font decoration',
      type: '1'
    },
    {
      name: 'Overline',
      value: 'overline',
      icon: <MdFormatOverline />,
      type: '1'
    },
    {
      name: 'Underline',
      value: 'underline',
      icon: <FaUnderline />,
      type: '1'
    },
    {
      name: 'Center',
      value: 'center',
      icon: <AiOutlineAlignCenter />,
      group: 'Horizontal align',
      type: '2'
    },
    {
      name: 'Left',
      value: 'left',
      icon: <AiOutlineAlignLeft />,
      type: '2'
    },
    {
      name: 'Right',
      value: 'right',
      icon: <AiOutlineAlignRight />,
      type: '2'
    },
    {
      name: 'Start',
      value: 'start',
      icon: <MdStart />,
      type: '2'
    },
    {
      name: 'End',
      value: 'end',
      icon: <MdTrendingFlat />,
      type: '2'
    },
    {
      name: 'Center bottom',
      value: 'center-bottom',
      icon: ' ',
      group: 'Anchor',
      type: '3'
    },
    {
      name: 'Center top',
      value: 'center-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left bottom',
      value: 'left-bottom',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left center',
      value: 'left-center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left top',
      value: 'left-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right bottom',
      value: 'right-bottom',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right center',
      value: 'right-center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right top',
      value: 'right-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Center',
      value: 'center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Top',
      value: 'top',
      icon: <AiOutlineVerticalAlignTop />,
      group: 'Vertical align',
      type: '4'
    },
    {
      name: 'Middle',
      value: 'middle',
      icon: <AiOutlineVerticalAlignMiddle />,
      type: '4'
    },
    {
      name: 'Bottom',
      value: 'bottom',
      icon: <AiOutlineVerticalAlignBottom />,
      type: '4'
    }
  ]
  const makerOption = [
    {
      key: 'assets/image/icons/up.svg',
      value: 'arrowUp'
    },
    {
      key: 'assets/image/icons/down.svg',
      value: 'arrow-down'
    },
    {
      key: 'assets/image/icons/left.svg',
      value: 'arrow-left'
    },
    {
      key: 'assets/image/icons/right.svg',
      value: 'arrow-right'
    },
    {
      key: 'assets/image/icons/play.svg',
      value: 'arrowHead'
    },
    {
      key: 'assets/image/icons/plus.svg',
      value: 'cross'
    },
    {
      key: 'assets/image/icons/crose.svg',
      value: 'diagonal-cross'
    },
    {
      key: 'assets/image/icons/dymd.svg',
      value: 'diamond'
    },
    {
      key: 'assets/image/icons/satcone.svg',
      value: 'pentagon'
    },
    {
      key: 'assets/image/icons/square.svg',
      value: 'square'
    },
    {
      key: 'assets/image/icons/10star.svg',
      value: 'star10'
    },
    {
      key: 'assets/image/icons/4star.svg',
      value: 'star4'
    },
    {
      key: 'assets/image/icons/5star.svg',
      value: 'star5'
    },
    {
      key: 'assets/image/icons/6stat.svg',
      value: 'star6'
    },
    {
      key: 'assets/image/icons/7star.svg',
      value: 'star7'
    },
    {
      key: 'assets/image/icons/trapezium.svg',
      value: 'trapezium'
    },
    {
      key: 'assets/image/icons/up_up.svg',
      value: 'triangle-up'
    },
    {
      key: 'assets/image/icons/down-down.svg',
      value: 'triangle-down'
    },
    {
      key: 'assets/image/icons/left-left.svg',
      value: 'triangle-left'
    },
    {
      key: 'assets/image/icons/right-right.svg',
      value: 'triangle-right'
    },
  ]
  const chartTypeOption = [
    {
      name: 'Area',
      key: 'area',
      icon: <svg viewBox="0 0 595.3 841.9" enable-background="new 0 0 595.3 841.9" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M453.8 360.2l-92.1 6L234 517.7l-86.2-86.1L5.2 514.7v118.9h585.3V268.1z" opacity=".3"></path><path d="M234 508.8c-156 222.067-78 111.033 0 0zm309-276.3l-71.3 95.1h-101L236.9 488l-92.1-112.9-38.6 41.6-101 98.1 41.6 41.6 95.1-95.1L234 574.2 397.4 387h104l89.1-118.9z"></path></g></svg>,
      type: 'area'
    },
    {
      name: 'Line',
      key: 'line',
      icon: <svg viewBox="0 0 595.3 841.9" enable-background="new 0 0 595.3 841.9" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M142 447c-102 102-83 83 0 0m399-186l-70 94H370l-136 159-91-112-38 41L6 542l41 41 94-94 91.6 112 162.6-186h103.5l88-118z"></path></g></svg>,
      type: 'area'
    },
    {
      name: 'Step Line',
      key: 'step-line',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M19 5h5v1h-4v13h-6v-7h-4v12H5v-1h4V11h6v7h4V5Z"></path></svg>,
      type: 'area'
    },
    {
      name: 'Column',
      key: 'column',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M12 7v14h5V7h-5Zm4 1h-3v12h3V8ZM19 15v6h5v-6h-5Zm4 1h-3v4h3v-4ZM5 12h5v9H5v-9Zm1 1h3v7H6v-7Z"></path></svg>,
      type: 'column'
    },
    {
      name: 'Bars',
      key: 'ohlc',
      icon: <svg viewBox="0 0 26 26" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M16 4v7h-3v2h3v7h2v-2h3v-2h-3V4h-2zM7 6v12H4v2h3v2h2V10h3V8H9V6H7z"></path></g></svg>,
      type: 'bar'
    },
    {
      name: 'Candlestick',
      key: 'candlestick',
      icon: <svg viewBox="0 0 26 26" width="28" height="28" fill="currentColor"><g transform="translate(0.5, 0.5)"><path d="M16 3v3h-2v12h2v5h1v-5h2V6h-2V3h-1zM9 4v5H7v11h2v3h1v-3h2V9h-2V4H9zm-1 6h3v9H8v-9z"></path></g></svg>,
      type: 'bar'
    },
    {
      name: 'Hilo',
      key: 'hilo',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7H7v14h5V7H7.5zM8 20V8h3v12H8zm7.5-11H15v10h5V9h-4.5zm.5 9v-8h3v8h-3z"></path></svg>,
      type: 'bar'
    },
    {
      name: 'Range Area',
      key: 'range-area',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path fill-rule="evenodd" d="M22 3h1v1h-1V3Zm0 2V4h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1V9h-1V8h-1V7h-1V6h-1V5h-1v1H9v1H8v1H7v1H6v1H5v1H4v1h1v1H4v1h1v-1h1v-1h1v-1h1v-1h1V9h1V8h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h-1Zm-1 1V5h1v1h-1Zm-1 1V6h1v1h-1Zm-1 1V7h1v1h-1Zm-1 1V8h1v1h-1Zm-1 1V9h1v1h-1Zm-1 1v-1h1v1h-1Zm-1 0v-1h-1V9h-1V8h-1V7h-1V6h-1v1H9v1H8v1H7v1H6v1H5v1h1v-1h1v-1h1V9h1V8h1V7h1v1h1v1h1v1h1v1h1Zm0 0h1v1h-1v-1Zm.84 6.37 7.5-7-.68-.74-7.15 6.67-4.66-4.65-.33-.34-.36.32-5.5 5 .68.74 5.14-4.68 4.67 4.66.34.35.35-.33ZM6 23H5v1h1v-1Zm0-1H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0v1H7v-1h1Zm0-1H7v-1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0v1H9v-1h1Zm0-1H9v-1h1v1Zm1 0h-1v1h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1h1v1Zm0 0h1v1h-1v-1Zm2 2v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1Zm0 0v-1h-1v1h1Z"></path></svg>,
      type: 'bar'
    },
  ]
  const chartSymbolButton = [
    {
      tooltip: 'Line Segment',
      icon: <i class="ac ac-line" aria-hidden="true"></i>,
      type: '1',
      value: 'line'
    },
    {
      tooltip: 'Horizontal Line',
      icon: <i class="ac ac-horizontal-line" aria-hidden="true"></i>,
      type: '1',
      value: 'horizontal-line'
    },
    {
      tooltip: 'Vertical Line',
      icon: <i class="ac ac-vertical-line" aria-hidden="true"></i>,
      type: '1',
      value: 'vertical-line'
    },
    {
      tooltip: 'Infinite Line',
      icon: <i class="ac ac-infinite-line" aria-hidden="true"></i>,
      type: '1',
      value: 'infinite-line'
    },
    {
      tooltip: 'Ray',
      icon: <i class="ac ac-ray" aria-hidden="true"></i>,
      type: '1',
      value: 'rey'
    },
    {
      tooltip: 'Triangle',
      icon: <i class="ac ac-triangle" aria-hidden="true"></i>,
      type: '2',
      value: 'triangle'
    },
    {
      tooltip: 'Rectangle',
      icon: <i class="ac ac-rectangle" aria-hidden="true"></i>,
      type: '2',
      value: 'rectangle'
    },
    {
      tooltip: 'Ellipse',
      icon: <i class="ac ac-ellipse" aria-hidden="true"></i>,
      type: '2',
      value: 'ellipse'
    },
    {
      tooltip: 'Horizontal Range',
      icon: <i class="ac ac-horizontal-range" aria-hidden="true"></i>,
      type: '3',
      value: 'horizontal-range'
    },
    {
      tooltip: 'Vertical Range',
      icon: <i class="ac ac-vertical-range" aria-hidden="true"></i>,
      type: '3',
      value: 'vertical-range'
    },
    {
      tooltip: 'Trend Channel',
      icon: <i class="ac ac-trend-channel" aria-hidden="true"></i>,
      type: '4',
      value: 'ac-trend-channel'
    },
    {
      tooltip: 'Finite Trend Channel',
      icon: <i class="ac ac-finite-trend-channel" aria-hidden="true"></i>,
      type: '4',
      value: 'finite-trend-channel'
    },
    {
      tooltip: "Andrew's Pitchfork",
      icon: <i class="ac ac-andrews-pitchfork" aria-hidden="true"></i>,
      type: '4',
      value: 'andrews-pitchfork'
    },
    {
      tooltip: 'Fibonacci Fan',
      icon: <i class="ac ac-fibonacci-fan" aria-hidden="true"></i>,
      type: '5',
      value: 'fibonacci-fan'
    },
    {
      tooltip: 'Fibonacci Arc',
      icon: <i class="ac ac-fibonacci-arc" aria-hidden="true"></i>,
      type: '5',
      value: 'fibonacci-arc'
    },
    {
      tooltip: 'Fibonacci Retracement',
      icon: <i class="ac ac-fibonacci-retracement" aria-hidden="true"></i>,
      type: '5',
      value: 'fibonacci-retracement'
    },
    {
      tooltip: 'Fibonacci Time Zones',
      icon: <i class="ac ac-fibonacci-timezones" aria-hidden="true"></i>,
      type: '5',
      value: 'fibonacci-timezones'
    }

  ]
  const stockPriceList = (check, code) => {
    let data = {
      tradeCode: code,
      stockDate: 1
    }
    if (data?.tradeCode?.length > 0) {

      try {
        getStockDetails(data).then((res) => {
          const timeSeriesData = res.data["Time Series (Daily)"];

          const transformedData = [];
          for (const date in timeSeriesData) {
            if (timeSeriesData.hasOwnProperty(date)) {
              const record = timeSeriesData[date];
              transformedData.push({
                x: date,
                open: parseFloat(record["1. open"]),
                high: parseFloat(record["2. high"]),
                low: parseFloat(record["3. low"]),
                close: parseFloat(record["4. close"]), // Convert close price to a floating-point number
                volume: parseFloat(record["5. volume"]),
                value: parseFloat(record["4. close"])
              });
            }
          }
          // Sort the data by date
          transformedData.sort((a, b) => new Date(a.x) - new Date(b.x));

          if (check == 0) {
            setDisableSeriesType(false)
            setStockDetailValue(transformedData);
          }
          setCurrentPrice(transformedData[transformedData?.length - 1]?.value || '')
          setReRenderChart(false)

        })
      } catch (error) {

      }
    }
  }

  var annotation = fullChart?.annotations()?.getSelectedAnnotation();
  // console.log(annotation)
  if (annotation) {
    fullChart.listen("annotationSelect", function (e) {
// console.log(selectedAnnotation)
      var selectedAnnotation = e.annotation;
      if (selectedAnnotation.type == 'label') {
        setShowEditTools({ textTool: true, makerTool: false, indicatorTool: false, drawingTool: false })
      } else if (selectedAnnotation.type == 'marker') {
        setShowEditTools({ textTool: false, makerTool: true, indicatorTool: false, drawingTool: false })
      } else {
        // drawDash
        let stroke = selectedAnnotation.stroke()
      
        let data_icon = stroke.dash == '10 5' ? <CgBorderStyleDashed /> : stroke.dash == '1 1' ? <RxBorderDotted /> : <TfiLayoutLineSolid />
        setDrawingVal({ ...drawingVal, drawCol: stroke.color, drawWidth: stroke.thickness, drawDash: stroke.dash, drawDashIcon: data_icon })

        setShowEditTools({ textTool: false, makerTool: false, indicatorTool: false, drawingTool: true })
      }
    });
    fullChart.listen("annotationUnselect", function (e) {
      setShowEditTools({ textTool: false, makerTool: false, indicatorTool: false, drawingTool: false })
    });
  }
  // if(fullChart){
  //   if(annotation){


  //   }
  // }



  const leftSideOpen = [
    {
      name: 'drwing',
      toolTipName: 'Drawing Tools',
      id: 'drwing_main',
      class: 'drwing',
      icon: <FaPencilAlt />,
      options: <div className='charting_option_main charting_hide' id='drwing_main' >
        <ul class="charting_ul">
          <li>

            <div class="list_charting" onClick={() => handleShowHideCountry('drwing_main_child')} >
              <div class="count_seca"> <span>88</span></div>
              <div class="count_secb"> <span>Lines & </span></div>
              <div class="count_secd"> <span> <MdKeyboardArrowRight /> </span> </div>
            </div>

            <ul className='charting_option_main_child charting_hide' id='drwing_main_child'>
              {
                chartSymbolButton.map((value, index) => {
                  return (
                    <li type="button">
                      <div class="chiled_seca" onClick={() => selectDrawType(value.value)} > <span>{value.icon} </span></div>
                      <div class="chiled_secb" onClick={() => selectDrawType(value.value)} > <span> {value.tooltip} </span></div>
                      <div class="chiled_secd"> <span> <FaHeart /> </span> </div>
                    </li>
                  )
                })
              }

            </ul>

            <div class="list_charting" onClick={() => handleShowHideCountry('drwing_main_child1')} >
              <div class="count_seca"> <span>78</span></div>
              <div class="count_secb"> <span>Lines & Measures</span></div>
              <div class="count_secd"> <span> <MdKeyboardArrowRight /> </span> </div>
            </div>
            <ul className='charting_option_main_child charting_hide' id='drwing_main_child1'>
              <li>111111111111111111</li>

            </ul>

            <div class="list_charting" onClick={() => handleShowHideCountry('drwing_main_child2')} >
              <div class="count_seca"> <span>55</span></div>
              <div class="count_secb"> <span>Trading & Measures</span></div>
              <div class="count_secd"> <span> <MdKeyboardArrowRight /> </span> </div>
            </div>
            <ul className='charting_option_main_child charting_hide' id='drwing_main_child2'>
              <li>111111111111111111</li>

            </ul>

          </li>

        </ul>
      </div>
    }
  ]




  useEffect(() => {
    if (reRenderChart == true) {
      stockPriceList('0', stockCode)
    }
  }, [stockCode])

  const handleChangePreSelectedChartType = (val, series) => {
    let value = val.key
    series.seriesType(value)
  }
  var currentDate = new Date();

  // Initial date range: two months ago to one day before the current date
  var startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 2);

  var endDate = new Date(currentDate);
  endDate.setDate(currentDate.getDate());

  // Format the initial dates for the chart.selectRange method
  var formattedStartDate = formatDate(startDate);
  var formattedEndDate = formatDate(endDate);

  // Assuming formatDate is a function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }
  const CopysameCharts = (key) => {
    debugger
    var chart = anychart.stock();
    var chart2 = anychart.stock();
    var chart3 = anychart.stock();
    var chart4 = anychart.stock();
    const dataTable = anychart.data.table();
    let datas = stockDetailValue.map((val) => ([val.x, val.open, val.high, val.low, val.close, val.volume]))
    dataTable.addData(datas);
    const mapping = dataTable.mapAs({
      'open': '1',
      'high': '2',
      'low': '3',
      'close': '4',
      'volume': '5',
      'x': '0',
      'value': { column: 4, type: 'close' }
    });
    setMappingValue(mapping)
    const VolumeMapping = dataTable.mapAs({
      'x': '0',
      'value': '5'
    });



    if (selectedFullChart) {
      selectedFullChart.dispose()
    }


    if (key === "line") {
      let chartsSetting = chart.plot(0).line(mapping);
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).line(mapping);
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).line(mapping);
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).line(mapping);
      chartsSetting4.name(stockCode);
    } else if (key === "area") {
      let chartsSetting = chart.plot(0).area(mapping);
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).area(mapping);
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).area(mapping);
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).area(mapping);
      chartsSetting4.name(stockCode);
    } else if (key === "candlestick") {

      // Add a candlestick series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).candlestick(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).candlestick(mapping)
      chartsSetting2.risingStroke('#089981')
      chartsSetting2.risingFill('#089981');
      chartsSetting2.fallingStroke('#ec2d3c')
      chartsSetting2.fallingFill('#ec2d3c');
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).candlestick(mapping)
      chartsSetting3.risingStroke('#089981')
      chartsSetting3.risingFill('#089981');
      chartsSetting3.fallingStroke('#ec2d3c')
      chartsSetting3.fallingFill('#ec2d3c');
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).candlestick(mapping)
      chartsSetting4.risingStroke('#089981')
      chartsSetting4.risingFill('#089981');
      chartsSetting4.fallingStroke('#ec2d3c')
      chartsSetting4.fallingFill('#ec2d3c');
      chartsSetting4.name(stockCode);

    } else if (key === "step-line") {
      let chartsSetting = chart.plot(0).stepLine(mapping);
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).stepLine(mapping);
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).stepLine(mapping);
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).stepLine(mapping);
      chartsSetting4.name(stockCode);
    } else if (key === "column") {
      let chartsSetting = chart.plot(0).column(mapping);
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).column(mapping);
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).column(mapping);
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).column(mapping);
      chartsSetting4.name(stockCode);
    } else if (key === "ohlc") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).ohlc(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).ohlc(mapping)
      chartsSetting2.risingStroke('#089981')
      chartsSetting2.risingFill('#089981');
      chartsSetting2.fallingStroke('#ec2d3c')
      chartsSetting2.fallingFill('#ec2d3c');
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).ohlc(mapping)
      chartsSetting3.risingStroke('#089981')
      chartsSetting3.risingFill('#089981');
      chartsSetting3.fallingStroke('#ec2d3c')
      chartsSetting3.fallingFill('#ec2d3c');
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).ohlc(mapping)
      chartsSetting4.risingStroke('#089981')
      chartsSetting4.risingFill('#089981');
      chartsSetting4.fallingStroke('#ec2d3c')
      chartsSetting4.fallingFill('#ec2d3c');
      chartsSetting4.name(stockCode);
    } else if (key === "hilo") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).hilo(mapping)
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart2.plot(0).hilo(mapping)
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).hilo(mapping)
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).hilo(mapping)
      chartsSetting4.name(stockCode);
    } else if (key === "range-area") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).rangeArea(mapping)
      chartsSetting.name(stockCode);

      let chartsSetting2 = chart.plot(0).rangeArea(mapping)
      chartsSetting2.name(stockCode);

      let chartsSetting3 = chart3.plot(0).rangeArea(mapping)
      chartsSetting3.name(stockCode);

      let chartsSetting4 = chart4.plot(0).rangeArea(mapping)
      chartsSetting4.name(stockCode);
    }
    const xAxis = chart.plot(0).xAxis();
    xAxis.labels().enabled(false);
    xAxis.minorLabels().enabled(false);

    const volumeSeries = chart.plot(1).column(VolumeMapping);

    volumeSeries.name('Volume')
    volumeSeries.risingStroke('#92d2cc')
    volumeSeries.risingFill('#92d2cc');
    volumeSeries.fallingStroke('#f7a9a7')
    volumeSeries.fallingFill('#f7a9a7');

    chart.padding(0, 80, 0, 5);

    var currentScroller = chart.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller.line(mapping);

    chart.plot(1).height(100);

    const yAxis0 = chart.plot(0).yAxis();
    yAxis0.orientation('right');
    yAxis0.enabled(true);
    const yAxis1 = chart.plot(1).yAxis();
    yAxis1.orientation('right');


    // for container 2
    const xAxis2 = chart2.plot(0).xAxis();
    xAxis2.labels().enabled(false);
    xAxis2.minorLabels().enabled(false);

    const volumeSeries2 = chart2.plot(1).column(VolumeMapping);

    volumeSeries2.name('Volume')
    volumeSeries2.risingStroke('#92d2cc')
    volumeSeries2.risingFill('#92d2cc');
    volumeSeries2.fallingStroke('#f7a9a7')
    volumeSeries2.fallingFill('#f7a9a7');

    chart2.padding(0, 80, 0, 5);

    var currentScroller2 = chart2.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller2.line(mapping);

    chart2.plot(1).height(100);

    const yAxis02 = chart2.plot(0).yAxis();
    yAxis02.orientation('right');
    yAxis02.enabled(true);
    const yAxis12 = chart2.plot(1).yAxis();
    yAxis12.orientation('right');




    // for container 3

    const xAxis3 = chart3.plot(0).xAxis();
    xAxis3.labels().enabled(false);
    xAxis3.minorLabels().enabled(false);

    const volumeSeries3 = chart3.plot(1).column(VolumeMapping);

    volumeSeries3.name('Volume')
    volumeSeries3.risingStroke('#92d2cc')
    volumeSeries3.risingFill('#92d2cc');
    volumeSeries3.fallingStroke('#f7a9a7')
    volumeSeries3.fallingFill('#f7a9a7');

    chart3.padding(0, 80, 0, 5);

    var currentScroller3 = chart3.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller3.line(mapping);

    chart3.plot(1).height(100);

    const yAxis03 = chart3.plot(0).yAxis();
    yAxis03.orientation('right');
    yAxis03.enabled(true);
    const yAxis13 = chart3.plot(1).yAxis();
    yAxis13.orientation('right');

    // for container 4
    const xAxis4 = chart4.plot(0).xAxis();
    xAxis4.labels().enabled(false);
    xAxis4.minorLabels().enabled(false);

    const volumeSeries4 = chart4.plot(1).column(VolumeMapping);

    volumeSeries4.name('Volume')
    volumeSeries4.risingStroke('#92d2cc')
    volumeSeries4.risingFill('#92d2cc');
    volumeSeries4.fallingStroke('#f7a9a7')
    volumeSeries4.fallingFill('#f7a9a7');

    chart4.padding(0, 80, 0, 5);

    var currentScroller4 = chart4.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller4.line(mapping);

    chart4.plot(1).height(100);

    const yAxis04 = chart4.plot(0).yAxis();
    yAxis04.orientation('right');
    yAxis04.enabled(true);
    const yAxis14 = chart4.plot(1).yAxis();
    yAxis14.orientation('right');
    chart.selectRange(startDate, endDate);
    chart2.selectRange(startDate, endDate);
    chart3.selectRange(startDate, endDate);
    chart4.selectRange(startDate, endDate);

    if (selectedTheme == 'dark') {
      chart.background().enabled(chart.background().enabled());
      // Dark theme settings
      chart.background('#131722'); // Dark background color
      chart.plot().background('#131722'); // Dark plot background color
      chart.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme

      chart2.background().enabled(chart2.background().enabled());
      // Dark theme settings
      chart2.background('#131722'); // Dark background color
      chart2.plot().background('#131722'); // Dark plot background color
      chart2.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme

      chart3.background().enabled(chart3.background().enabled());
      // Dark theme settings
      chart3.background('#131722'); // Dark background color
      chart3.plot().background('#131722'); // Dark plot background color
      chart3.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme

      chart4.background().enabled(chart4.background().enabled());
      // Dark theme settings
      chart4.background('#131722'); // Dark background color
      chart4.plot().background('#131722'); // Dark plot background color
      chart4.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme
    } else {
      chart.background().enabled(!chart.background().enabled());
      // Light theme settings
      chart.background('#ffffff'); // Light background color
      chart.plot().background('#ffffff'); // Light plot background color
      chart.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme

      chart2.background().enabled(!chart2.background().enabled());
      // Light theme settings
      chart2.background('#ffffff'); // Light background color
      chart2.plot().background('#ffffff'); // Light plot background color
      chart2.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme

      chart3.background().enabled(!chart3.background().enabled());
      // Light theme settings
      chart3.background('#ffffff'); // Light background color
      chart3.plot().background('#ffffff'); // Light plot background color
      chart3.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme

      chart4.background().enabled(!chart4.background().enabled());
      // Light theme settings
      chart4.background('#ffffff'); // Light background color
      chart4.plot().background('#ffffff'); // Light plot background color
      chart4.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme
    }
    chart.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });
    chart2.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });
    chart3.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });
    chart4.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });
    if (area == 1) {
      chart.container("container");
      chart.draw();
      setFullChart(chart)
      setSelectedFullChart(chart)
    } else if (area == 2) {
      chart.container("container");
      chart.draw();
      setFullChart(chart)

      chart2.container("container2");
      chart2.draw();
      setFullChart2(chart2)

    } else if (area == 3) {
      chart.container("container");
      chart.draw();
      setFullChart(chart)

      chart2.container("container2");
      chart2.draw();
      setFullChart2(chart2)

      chart3.container("container3");
      chart3.draw();
      setFullChart3(chart3)

    } else if (area == 4) {
      chart.container("container");
      chart.draw();
      setFullChart(chart)

      chart2.container("container2");
      chart2.draw();
      setFullChart2(chart2)

      chart3.container("container3");
      chart3.draw();
      setFullChart3(chart3)

      chart4.container("container4");
      chart4.draw();
      setFullChart4(chart4)
    }
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }

  const updateChartType = (key, chart) => {
    debugger
    const dataTable = anychart.data.table();
    let datas = stockDetailValue.map((val) => ([val.x, val.open, val.high, val.low, val.close, val.volume]))
    dataTable.addData(datas);
    const mapping = dataTable.mapAs({
      'open': '1',
      'high': '2',
      'low': '3',
      'close': '4',
      'volume': '5',
      'x': '0',
      'value': { column: 4, type: 'close' }
    });
    setMappingValue(mapping)
    const VolumeMapping = dataTable.mapAs({
      'x': '0',
      'value': '5'
    });

    if (selectedSplitedArea == 1) {

      if (fullChart) {
        fullChart.dispose()
      }
    } else if (selectedSplitedArea >= 2) {
      if (selectedFullChart) {
        selectedFullChart.dispose()
      }
    }

    if (key === "line") {
      let chartsSetting = chart.plot(0).line(mapping);
      chartsSetting.name(stockCode);
    } else if (key === "area") {
      let chartsSetting = chart.plot(0).area(mapping);
      chartsSetting.name(stockCode);
    } else if (key === "candlestick") {

      // Add a candlestick series with risingStroke and risingFill settings
      let chartsSetting = chart.plot().candlestick(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(stockCode);

    } else if (key === "step-line") {
      let chartsSetting = chart.plot(0).stepLine(mapping);
      chartsSetting.name(stockCode);
    } else if (key === "column") {
      let chartsSetting = chart.plot(0).column(mapping);
      chartsSetting.name(stockCode);
    } else if (key === "ohlc") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).ohlc(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(stockCode);
    } else if (key === "hilo") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).hilo(mapping)
      chartsSetting.name(stockCode);
    } else if (key === "range-area") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).rangeArea(mapping)
      chartsSetting.name(stockCode);
    }
    const xAxis = chart.plot(0).xAxis();
    xAxis.labels().enabled(false);
    xAxis.minorLabels().enabled(false);
    const volumeSeries = chart.plot(1).column(VolumeMapping);
    volumeSeries.name('Volume')
    volumeSeries.risingStroke('#92d2cc')
    volumeSeries.risingFill('#92d2cc');
    volumeSeries.fallingStroke('#f7a9a7')
    volumeSeries.fallingFill('#f7a9a7');
    chart.padding(0, 80, 0, 5);
    var currentScroller = chart.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller.line(mapping);
    chart.plot(1).height(100);
    const yAxis0 = chart.plot(0).yAxis();
    yAxis0.orientation('right');
    yAxis0.enabled(true);
    const yAxis1 = chart.plot(1).yAxis();
    yAxis1.orientation('right');
    chart.selectRange(startDate, endDate);

    if (selectedTheme == 'dark') {
      chart.background().enabled(chart.background().enabled());
      // Dark theme settings
      chart.background('#131722'); // Dark background color
      chart.plot().background('#131722'); // Dark plot background color
      chart.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme
    } else {
      chart.background().enabled(!chart.background().enabled());
      // Light theme settings
      chart.background('#ffffff'); // Light background color
      chart.plot().background('#ffffff'); // Light plot background color
      chart.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme
    }
    chart.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });

    if (selectedSplitedArea == 1) {
      chart.container("container");
      chart.draw();
      setSelectedFullChart(chart)
      setFullChart(chart)
    } else if (selectedSplitedArea == 2) {
      chart.container("container2");
      chart.draw();
      setSelectedFullChart(chart)
      setFullChart2(chart)
    } else if (selectedSplitedArea == 3) {
      chart.container("container3");
      chart.draw();
      setSelectedFullChart(chart)
      setFullChart3(chart)
    } else if (selectedSplitedArea == 4) {
      chart.container("container4");
      chart.draw();
      setSelectedFullChart(chart)
      setFullChart4(chart)
    }
    var chartContainer = document.getElementById('container');
    var isResizing = false;
    chartContainer.addEventListener('mousedown', function (e) {
      if (e.offsetX > chartContainer.offsetWidth - 10) {
        isResizing = true;
      }
    });
    document.addEventListener('mousemove', function (e) {
      if (isResizing) {
        chartContainer.style.width = e.clientX - chartContainer.getBoundingClientRect().left + 'px';
      }
    });
    document.addEventListener('mouseup', function () {
      isResizing = false;
    });

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }



  const handleMouseWheel=(event)=>{
    var delta = event.deltaY > 0 ? 2 : -2;
    startDate.setDate(startDate.getDate() + delta);
    endDate.setDate(endDate.getDate() + delta);
    var formattedStartDate = formatDate(startDate);
    var formattedEndDate = formatDate(endDate);
    if (selectedFullChart && scrolar == 1 && splitedAreaType==1) {
      selectedFullChart?.selectRange(formattedStartDate, formattedEndDate);
    }
  } 
   


  useEffect(() => {
    debugger
    if (stockDetailValue.length > 0) {
      var chart = anychart.stock();
      let plot = chart.plot(0)
      if (scrolar==1) {
        updateChartType(selectedSeries.key, chart)
      }

    }
  }, [stockDetailValue]);
  if (fullChart) {
    fullChart.listen('click', function (event) {
      var waveNumber = fullChart.annotations().length + 1;
      var plot = fullChart?.plot(0) || fullChart;

      // access the annotations() object of the plot to work with annotations
      var controller = plot.annotations();
      // Draw a line annotation at the clicked point
      controller.line({ x: event.clientX, y: event.clientY });

      fullChart.draw(); // Redraw the chart after adding the annotation
    });
  }
  const handleChangeChartType = (value) => {
    // sriesTypeData.seriesType(value)
   
    let chart = anychart.stock()
    updateChartType(value, chart)
    if (fullChart) fullChart.dispose()
    setSelectedSeries(chartTypeOption.filter((val) => val.key == value)[0])
  }
  // create annotations
  function selectDrawType(value) {
    var dropdown = document.querySelector(`#drwing_main`);
    if (dropdown.classList.contains('charting_show')) {
      dropdown.classList.remove('charting_show');
      dropdown.classList.add('charting_hide');
    }
    var strokeSettings = {
      thickness: drawWidth,
      color: drawCol,
      dash: drawDash
    };
    var drawingSettings = {
      type: value,
      fill: '#ffffff',
      color: '#ffffff'
    };
    // drawingSettings.fill = colorFill;
    drawingSettings.stroke = strokeSettings;
    drawingSettings.hovered = {
      stroke: strokeSettings
    };
    drawingSettings.selected = {
      stroke: strokeSettings
    };
    setShowEditTools({ ...showEditTools, drawingTool: true })
    let n = fullChart.annotations().startDrawing(drawingSettings);
  }
  const clickDrawType = (val) => {
    fullChart.annotations().startDrawing(val);
  }
  const handleHideEditTool = () => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation) fullChart.annotations().unselect();
    setShowEditTools({ textTool: false, makerTool: false, indicatorTool: false, drawingTool: false })
  }
  function removeSelectedAnnotation() {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation) fullChart.annotations().removeAnnotation(annotation);
    setShowEditTools({ textTool: false, makerTool: false, indicatorTool: false, drawingTool: false })
    return !!annotation;
  }
  function removeAll() {
    fullChart.annotations().removeAllAnnotations();
  }



  const saveChart = () => {
    fullChart.saveAsJpg({
      "width": window.innerWidth,
      "height": window.innerHeight,
      "quality": 1,
      "forceTransparentWhite": false,
      "filename": "My Chart JPG"
    });
  }
  let height = window.innerHeight;
  // document.body.style.height = `${height}px`;
  const handleShowColorPicker = (val) => {
    setColoerPicker({ type: val, showHide: !showHide })
  }
  const handleHideColorPicker = () => {
    setColoerPicker({ type: '', showHide: false })
  }
  function setAnnotationStrokeSettings(settings) {

    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation?.type == 'marker') {
      annotation.stroke(settings);

      if (annotation.hovered || annotation.selected) {
        annotation.hovered().stroke(settings);
        annotation.selected().stroke(settings);
      }
    } else if (annotation?.type == 'label') {

      annotation?.background().stroke(settings);

      if (annotation?.hovered || annotation?.selected) {
        annotation.hovered().stroke(settings);
        annotation.selected().stroke(settings);
      }
    } else {
      annotation.stroke(settings);
      if (annotation?.hovered || annotation?.selected) {
        annotation.hovered().stroke(settings);
        annotation.selected().stroke(settings);
      }
    }
  }
  const handleLockUnlockDrawing = () => {
    var annotation = fullChart?.annotations()?.getSelectedAnnotation();
    if (drawLock) {
      annotation.allowEdit(!drawLock)
    }
    setDrawingVal({ ...drawingVal, drawLock: !drawLock })
  }
  const handleChangeMakerBorder = (e) => {
    let val = e.target.value
    //  const [makerVal, setMakerVal] = useState({ makerCol: '#DA2828', makerBorderCol: '#DA2828', makerBorder: 1, makerLock: false, makerDash: 'none', makerSize: 100, makerDashIcon: <TfiLayoutLineSolid /> })
    var settings = {
      thickness: val,
      color: makerBorderCol,
      dash: makerDash
    };
    setMakerVal({ ...makerVal, makerBorder: val })
    setAnnotationStrokeSettings(settings)
  }
  const handleChangeLabelBorder = (e) => {
    let val = e.target.value
    //  const [makerVal, setMakerVal] = useState({ makerCol: '#DA2828', makerBorderCol: '#DA2828', makerBorder: 1, makerLock: false, makerDash: 'none', makerSize: 100, makerDashIcon: <TfiLayoutLineSolid /> })
    var settings = {
      thickness: val,
      color: series,
      dash: stroke
    };
    setLabelBorderSize(val)
    setAnnotationStrokeSettings(settings)
  }
  // labelBorderSide
  const handleColorChange = (val, key) => {

    var annotation = fullChart?.annotations()?.getSelectedAnnotation();

    if (annotation?.type === 'label') {

      var annotationLabel = document.querySelector('#annotation-label');
      annotationLabel.focus();
      if (key == 'series') {
        setColors({ ...colors, series: val.hex })
        annotation = annotation.background();
        annotation.fill(val.hex);
      } else if (key == 'stroke') {
        setColors({ ...colors, stroke: val.hex })
        var settings = {
          thickness: labelBorderSide,
          color: val.hex,
          dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
        };
        setAnnotationStrokeSettings(settings)
      } else if (key == 'fontCol') {
        setColors({ ...colors, fontCol: val.hex })
        annotation.fontColor(val.hex)
      }
    } else if (annotation?.type === 'marker') {
      if (key == 'series') {
        annotation.fill(val.hex);
        setMakerVal({ ...makerVal, makerCol: val.hex })
        // const { makerCol, makerBorder, makerLock,makerSize, makerBorderCol,  makerDash } = MakerVal
      } else if (key == 'stroke') {
        setMakerVal({ ...makerVal, makerBorderCol: val.hex })
        var settings = {
          thickness: makerBorder,
          color: val.hex,
          dash: makerDash
        };
        setAnnotationStrokeSettings(settings)
      } else {
        setColors({ ...colors, fontCol: val.hex })
        annotation.fontColor(val.hex)
      }
    } else {
      if (key == 'series') {
        // const [drawingVal,setDrawingVal]=useState({drawCol:'#DA2828', drawWidth:5})
        setDrawingVal({ ...drawingVal, drawCol: val.hex })
        var settings = {
          thickness: drawWidth,
          color: val.hex,
          dash: drawDash
        };
        setAnnotationStrokeSettings(settings)
      } else if (key == 'stroke') {
        setDrawingVal({ ...drawingVal, drawWidth: val })
        var settings = {
          thickness: val,
          color: drawCol,
          dash: drawDash
        };
        setAnnotationStrokeSettings(settings)
      }
    }


  }
  const handleSelectCursor = () => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    fullChart.annotations().unselect(annotation).cancelDrawing();
  }
  const createMaker = (makerSetting) => {

    fullChart.annotations().startDrawing(makerSetting);
  }
  const handleChangechoosemarker = (val) => {

    {/* makerCol, makerBorder, makerLock, makerBorderCol,  makerDash */ }
    let type = val.value
    setMakerType(type)
    var strokeSettings = {
      thickness: makerBorder,
      color: makerBorderCol,
      dash: makerDash
    };
    let background = {
      fill: makerCol,
      stroke: strokeSettings
    }
    let background1 = {
      stroke: strokeSettings
    }

    let makerSetting = {
      type: "marker",
      markerType: type,
      fill: makerCol,
      anchor: anchor?.value || null,
      stroke: strokeSettings,
      fontColor: fontCol,
      fontDecoration: fontDecoration?.value || null,
      fontSize: makerSize,
      fontStyle: '',
      fontWeight: fontWeight?.value || null,
      hAlign: hAlign?.value || null,
      hovered: {
        stroke: background1
      },
      selected: {
        stroke: strokeSettings
      },
      size: makerSize,
      vAlign: vAlign?.value || null
    }
    setShowEditTools({ ...showEditTools, makerTool: true })
    createMaker(makerSetting)
    setShowMaker(false)
  }

  var annotation = fullChart?.annotations()?.getSelectedAnnotation();
  const addText = () => {
    setShowEditTools({ ...showEditTools, textTool: true })
    var annotationLabel = document.querySelector('#annotation-label');
    annotationLabel.focus()
    var strokeSettings = {
      thickness: sWidth.id,
      color: stroke,
      dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
    };
    let background = {
      fill: series,
      stroke: strokeSettings
    }
    let background1 = {
      stroke: strokeSettings
    }

    let labelSetting = {
      type: "label",
      anchor: anchor?.value || null,
      background: background,
      fontColor: fontCol,
      fontDecoration: fontDecoration?.value || null,
      fontSize: progressWidth,
      fontStyle: '',
      fontWeight: fontWeight?.value || null,
      hAlign: hAlign?.value || null,
      hovered: {
        background: background1
      },
      selected: {
        background: background1
      },
      size: '',
      vAlign: vAlign?.value || null
    }
    fullChart.annotations().startDrawing(labelSetting)
  }
  const handleAutoSizeAnnotation = () => {
    var annotation = fullChart.annotations().getSelectedAnnotation();

    if (annotation && annotation.type === 'label') {
      annotation.width(null);
      annotation.height(null);
    }
    document.querySelector('body').querySelector('#annotation-label').focus()
  }

  const handleSelectStroke = (value) => {

    let n = stockSelect
    if (value.id < 6) {
      n.sWidth.id = value.id
      n.sWidth.name = value.name
      setStockSelect(n)
    } else {
      n.sDash.id = value.id
      n.sDash.name = value.name
      setStockSelect(n)
    }
    setShowBorderSetting(false)
    if (annotation?.type == 'label') {
      var settings = {
        thickness: n.sWidth.id,
        color: stroke,
        dash: n.sDash.id == 8 ? '10 5' : n.sDash.id == 7 ? '1 1' : null
      };

      setAnnotationStrokeSettings(settings);
    }
  }
  const handleAddAnnomationText = (e) => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation && annotation.type === 'label') {
      if (e.keyCode === 46) return;

      try {
        annotation = fullChart.annotations().getSelectedAnnotation();
        annotation.enabled();
      } catch (err) {
        annotation = null;
      }

      if (annotation) {
        if (e.target.value) {
          annotation.text(e.target.value);
          setLabelText(e.target.value)
        } else {
          annotation.text(' ');
          setLabelText('')
        }
      }
    }
  }
  if (fullChart != undefined && fullChart != null) {

    fullChart?.listen('annotationDrawingFinish', function (e) {
      var annotationLabel = document.querySelector('#annotation-label');

      if (e.annotation.type === 'label') {
        let n = e.annotation.text()
        setLabelText(n)
        annotationLabel.focus();
        // const handleAddAnnomationText = (e) => {

        // }
        // annotationLabel.removeEventListener('change', handleAddAnnomationText);
        // annotationLabel.addEventListener('change', handleAddAnnomationText);


        fullChart.listen('annotationDrawingFinish', function (e) {
          if (e.annotation.type === 'label') {
            let n = e.annotation.text()
            setLabelText(n)
            annotationLabel.focus();
          }
        });

        fullChart.listen('annotationSelect', function (e) {
          if (e.annotation.type === 'label') {
            let n = e.annotation.text()
            setLabelText(n)
            annotationLabel.focus();
          }
        });

        fullChart.listen('annotationUnselect', function (e) {
          if (e.annotation.type === 'label') {
            annotationLabel.value = '';
          }
        });
      }
    });
  }
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleFontChange = (e) => {
    let type = 'label'
    updateProgress(e, type);
  };

  const handleMouseDownMaker = () => {
    setIsDraggingMaker(true);
  };

  const handleMouseUpMaker = () => {
    setIsDraggingMaker(false);
  };

  const handleMouseMoveMaker = (e) => {
    // 
    let type = 'marker'
    updateProgress(e, type);

  };
  const updateProgress = (e, type) => {

    var annotation = fullChart.annotations().getSelectedAnnotation();
    const percentage = e.target.value
    if (type == 'label') {

      setProgressWidth(Math.round(percentage));

      if (annotation?.type == 'label') {
        annotation.fontSize(Math.round(percentage))
      }


    } else if (type == 'marker') {
      setMakerVal({ ...makerVal, makerSize: percentage });

      if (annotation?.type == 'marker') {
        annotation.size(Math.round(percentage))
      }


    }
  };
  const handleSelectFontStyle = (val) => {

    var annotation = fullChart?.annotations()?.getSelectedAnnotation();
    let value = val.value
    if (val.type == '0') {
      if (fontWeight == null) {
        setFontStyling({ ...fontStyling, fontWeight: val })
        if (annotation?.type == 'label') {
          annotation.fontWeight(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, fontWeight: null })
        if (annotation?.type == 'label') {
          annotation.fontWeight(null)
        }
      }

    } else if (val.type == '1') {
      if (fontDecoration == null) {
        setFontStyling({ ...fontStyling, fontDecoration: val })
        if (annotation?.type == 'label') {
          annotation.fontDecoration(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, fontDecoration: null })
        if (annotation?.type == 'label') {
          annotation.fontDecoration(null)
        }
      }

    } else if (val.type == '2') {
      if (hAlign == null) {
        setFontStyling({ ...fontStyling, hAlign: val })
        if (annotation?.type == 'label') {
          annotation.hAlign(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, hAlign: null })
        if (annotation?.type == 'label') {
          annotation.hAlign(null)
        }
      }

    } else if (val.type == '3') {
      if (anchor == null) {
        setFontStyling({ ...fontStyling, anchor: val })
        if (annotation?.type == 'label') {
          annotation.anchor(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, anchor: null })
        if (annotation?.type == 'label') {
          annotation.anchor(null)
        }
      }

    } else if (val.type == '4') {
      if (vAlign == null) {
        setFontStyling({ ...fontStyling, vAlign: val })
        if (annotation?.type == 'label') {
          annotation.vAlign(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, vAlign: null })
        if (annotation?.type == 'label') {
          annotation.vAlign(null)
        }
      }

    }
  }
  const updateXMLChart = (key, chartValue, annotationXml,sName) => {

    let chart = anychart.stock()
    const dataTable = anychart.data.table();
    let datas = chartValue.map((val) => ([val.x, val.open, val.high, val.low, val.close, val.volume]))
    dataTable.addData(datas);
    const mapping = dataTable.mapAs({
      'open': '1',
      'high': '2',
      'low': '3',
      'close': '4',
      'volume': '5',
      'x': '0',
      'value': { column: 4, type: 'close' }
    });

    setMappingValue(mapping)
    const VolumeMapping = dataTable.mapAs({
      'x': '0',
      'value': '5'
    });

    if (key === "line") {
      let chartsSetting = chart.plot(0).line(mapping);
      chartsSetting.name(sName);
    } else if (key === "area") {
      let chartsSetting = chart.plot(0).area(mapping);
      chartsSetting.name(sName);
    } else if (key === "candlestick") {

      // Add a candlestick series with risingStroke and risingFill settings
      let chartsSetting = chart.plot().candlestick(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(sName);

    } else if (key === "step-line") {
      let chartsSetting = chart.plot(0).stepLine(mapping);
      chartsSetting.name(sName);
    } else if (key === "column") {
      let chartsSetting = chart.plot(0).column(mapping);
      chartsSetting.name(sName);
    } else if (key === "ohlc") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).ohlc(mapping)
      chartsSetting.risingStroke('#089981')
      chartsSetting.risingFill('#089981');
      chartsSetting.fallingStroke('#ec2d3c')
      chartsSetting.fallingFill('#ec2d3c');
      chartsSetting.name(sName);
    } else if (key === "hilo") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).hilo(mapping)
      chartsSetting.name(sName);
    } else if (key === "range-area") {
      // Add an OHLC series with risingStroke and risingFill settings
      let chartsSetting = chart.plot(0).rangeArea(mapping)
      chartsSetting.name(sName);
    }
    const xAxis = chart.plot(0).xAxis();
    xAxis.labels().enabled(false);
    xAxis.minorLabels().enabled(false);
    const volumeSeries = chart.plot(1).column(VolumeMapping);
    volumeSeries.name('Volume')
    volumeSeries.risingStroke('#92d2cc')
    volumeSeries.risingFill('#92d2cc');
    volumeSeries.fallingStroke('#f7a9a7')
    volumeSeries.fallingFill('#f7a9a7');
    chart.padding(0, 80, 0, 5);
    var currentScroller = chart.scroller();
    // chart.plot(0).yScroller().enabled(true);
    currentScroller.line(mapping);
    chart.plot(1).height(100);
    const yAxis0 = chart.plot(0).yAxis();
    yAxis0.orientation('right');
    yAxis0.enabled(true);
    const yAxis1 = chart.plot(1).yAxis();
    yAxis1.orientation('right');
    chart.selectRange(startDate, endDate);

    if (selectedTheme == 'dark') {
      chart.background().enabled(chart.background().enabled());
      // Dark theme settings
      chart.background('#131722'); // Dark background color
      chart.plot().background('#131722'); // Dark plot background color
      chart.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme  
    } else {
      chart.background().enabled(!chart.background().enabled());
      // Light theme settings
      chart.background('#ffffff'); // Light background color
      chart.plot().background('#ffffff'); // Light plot background color
      chart.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme
    }
    chart.listen("annotationDrawingFinish", function () {
      // document.getElementById("typeSelect").value = "default";
    });
    chart.plot(0).annotations().fromXml(annotationXml);
    // console.log(chart.annotations()?.getSelectedAnnotation())
    chart.container("container");
    chart.draw();
    setSelectedFullChart(chart)
    setFullChart(chart)
    var chartContainer = document.getElementById('container');
    var isResizing = false;
    chartContainer.addEventListener('mousedown', function (e) {
      if (e.offsetX > chartContainer.offsetWidth - 10) {
        isResizing = true;
      }
    });
    document.addEventListener('mousemove', function (e) {
      if (isResizing) {
        chartContainer.style.width = e.clientX - chartContainer.getBoundingClientRect().left + 'px';
      }
    });
    document.addEventListener('mouseup', function () {
      isResizing = false;
    });
    document.getElementById('stockListModal').click()
    setStockDetailValue(chartValue)
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }
  const ViewXMLChart = (value) => {
    setDisableSeriesType(true)
    // fullChart.saveAsJson("pancakes_chart_json");
    // fullChart.saveAsXml("chart_xml1");
    // fullChart.annotations().applyDrawing()

    // stockPriceList('1', value.stockCode)
    setSelectedChartType(value)
    setScrolar(0)
    setStockCode(value.stockCode)
    try {
      getXmlsChartData(value.chartingLevelId).then((res) => {
       
        if (res?.status === 200) {
          const parser = new DOMParser();
          // const xmlDoc = parser.parseFromString(res.data.chartFile, 'text/json');
          const xmlAnnotationDoc = parser.parseFromString(res.data.chartAnnotationFile, 'text/xml');
       
          fullChart.dispose();
          const jsonUrl = '/' + res.data.chartFile;
          // Fetch XML data from the URL
          fetch(jsonUrl)
            .then(response => {
         
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.text();
            })
            .then(xmlData => {
              let jsonData = JSON.parse(xmlData)
              updateXMLChart(res.data.chartType, jsonData, xmlAnnotationDoc, value.stockCode)
              // if (jsonData) {
              //   // Use anychart.fromXml directly for stock chart
              //   var stockChart = anychart.stock()
              //   stockChart.plot(0).candlestick(mappingValue)
              //   stockChart.plot(0).annotations().fromXml(xmlAnnotationDoc);
              //   // Assuming "container" is the ID of the HTML element where the chart will be displayed
              //   stockChart.container("container");
              //   stockChart.draw();

              //   // Optionally, set the created chart as the full chart
              //   setFullChart(stockChart);

              //   // Optionally, trigger some action (e.g., opening a modal)
              //   document.getElementById('stockListModal').click();
              // } else {
              //   console.error('Invalid XML content.');
              // }
            })
            .catch(error => {
              console.error('Error fetching XML:', error);
            });


        }
      })
    } catch (error) {

    }
    // fullChart.dispose();

    // var chartFromXml = anychart.fromXml(xml);
    // chartFromXml.annotations().fromXml(xmlAnnotation);
    // chartFromXml.container("container");
    // setFullChart(chartFromXml);
    // chartFromXml.draw();
  }

  const viewXMLchartData = () => {

    fullChart.saveAsXml('ChartXML', (xml) => {
      debugger
     
    });


  }
  // setSelectedTab
  //  style={{ 'height': height, 'overflow': 'auto' }}
  function getMinimumAtrValue(data) {

    let minAtr = Number.POSITIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      let atrValue = data[i].close; // Replace 'atr' with the actual property name in your data
      if (!isNaN(atrValue)) {
        minAtr = Math.min(minAtr, atrValue);
      }
    }
    return minAtr;
  }

  function getMaximumAtrValue(data) {
    let maxAtr = Number.NEGATIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      let atrValue = data[i].close; // Replace 'atr' with the actual property name in your data
      if (!isNaN(atrValue)) {
        maxAtr = Math.max(maxAtr, atrValue);
      }
    }
    return maxAtr;
  }
  function getMinimumDmiValue(data) {

    let minDmi = Number.POSITIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      let dmiValue = data[i].volume; // Replace 'Dmi' with the actual property name in your data
      if (!isNaN(dmiValue)) {
        minDmi = Math.min(minDmi, dmiValue);
      }
    }
    return minDmi;
  }

  function getMaximumDmiValue(data) {
    let maxDmi = Number.NEGATIVE_INFINITY;
    for (let i = 0; i < data.length; i++) {
      let dmiValue = data[i].volume; // Replace 'Dmi' with the actual property name in your data
      if (!isNaN(dmiValue)) {
        maxDmi = Math.max(maxDmi, dmiValue);
      }
    }
    return maxDmi;
  }
  const changeIndicators = (value, checkType, passedChart) => {

    //  mappingValue
    document.getElementById('IndicatorListModal').click()
    if (passedChart) {
      let totalIndicator = selectedIndicators
      let checkSelectedIndicator = selectedIndicators.filter((val) => val.type == value.type)
      if ((checkSelectedIndicator.length == 0 && checkType == true) || checkType == false) {
        debugger
        if (checkSelectedIndicator.length > 0) {
          checkSelectedIndicator[0].indData.dispose()
        }
        let plot_0 = passedChart.plot(0)
        let plot_1 = passedChart.plot(1)
        // let plot_2=passedChart.plot(2)
        switch (value.type) {
          case 'ema':
            var ema20 = plot_0.ema(mappingValue, value.val1, value.chartType);
            ema20.series().stroke(value.col1);
            // ema20.series().fill(value.col1);
            let valueIndicatorEma = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: ema20,
              plot: 0,
              col1: value.col1,
              svType: value.svType,
              colWidth: value.colWidth,
              chartType: value.chartType
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorEma])
            } else {
              let newIndListema20 = selectedIndicators.map(item => item.type == value.type ? valueIndicatorEma : item);
              setSelectedIndicators([...newIndListema20])
            }

            break;
          case 'adl':
            var adl = plot_1.adl(mappingValue, value.chartType);
            adl.series().stroke(value.col1);
            let valueIndicatorAdl = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: adl,
              plot: 1,
              col1: value.col1,
              chartType: value.chartType
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorAdl])
            } else {
              let newIndListadl = selectedIndicators.map(item => item.type == value.type ? valueIndicatorAdl : item);
              setSelectedIndicators([...newIndListadl])
            }
            break;
          case 'ama':
            var ama_1 = plot_0.ama(mappingValue, value.val1, value.val2, value.val3, value.chartType);
            ama_1.series().risingStroke(value.col1)
            ama_1.series().fallingStroke(value.col2)
            ama_1.series().stroke(value.col3)
            let valueIndicatorAdlama_1 = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val3: value?.val3,
              show: value?.show,
              indData: ama_1,
              plot: 0,
              col1: value.col1,
              col2: value.col2,
              col3: value.col3,
              chartType: value.chartType
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorAdlama_1])
            } else {
              let newIndListama_1 = selectedIndicators.map(item => item.type == value.type ? valueIndicatorAdlama_1 : item);
              setSelectedIndicators([...newIndListama_1])
            }

            break;
          case 'bbands':
            var bbands = plot_0.bbands(mappingValue, value.val1, value.val2, value.chartType1, value.chartType, value.chartType2);

            // color the series
            bbands.upperSeries().stroke(value.col1);
            bbands.middleSeries().stroke(value.col2);
            bbands.lowerSeries().stroke(value.col3);
            bbands.rangeSeries().fill('#ffd54f 0.2');
            let valueIndicatorbbands = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: bbands,
              plot: 0,
              col1: value.col1,
              col2: value.col2,
              col3: value.col3,
              chartType: value.chartType,
              chartType1: value.chartType1,
              chartType2: value.chartType2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorbbands])
            } else {
              let newIndListbbands = selectedIndicators.map(item => item.type == value.type ? valueIndicatorbbands : item);
              setSelectedIndicators([...newIndListbbands])
            }

            break;
          case 'dmi':
            let plot_02Dmi = passedChart.plot(2)
            var dmi2 = plot_02Dmi.dmi(mappingValue, value.val1, value.val2, false, value.chartType1, value.chartType, value.chartType2);
            dmi2.adxSeries().stroke(value.col1);
            dmi2.ndiSeries().stroke(value.col2);
            dmi2.pdiSeries().stroke(value.col3);
            let valueIndicatordmi2 = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: dmi2,
              plot: 2,
              col1: value.col1,
              col2: value.col2,
              col3: value.col3,
              chartType: value.chartType,
              chartType1: value.chartType1,
              chartType2: value.chartType2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatordmi2])
            } else {
              let newIndListdmi2 = selectedIndicators.map(item => item.type == value.type ? valueIndicatordmi2 : item);
              setSelectedIndicators([...newIndListdmi2])
            }

            const yAxis01 = plot_02Dmi.yAxis();
            yAxis01.orientation('right');
            break;
          case 'env':
            var env_2 = plot_0.env(mappingValue, value?.val1, value?.val2, "ema", value.chartType, value.chartType1);
            env_2.upperSeries().stroke(value.col1);
            env_2.lowerSeries().stroke(value.col2);
            let valueIndicatorenv = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: env_2,
              plot: 0,
              col1: value.col1,
              col2: value.col2,
              chartType: value.chartType,
              chartType1: value.chartType1,
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorenv])
            } else {
              let newIndListenv = selectedIndicators.map(item => item.type == value.type ? valueIndicatorenv : item);
              setSelectedIndicators([...newIndListenv])
            }
            break;
          case 'macd':
            let plot_02Macd = passedChart.plot(3)
            var macd_all_lines = plot_02Macd.macd(mappingValue, value?.val1, value?.val2, value?.val3, value?.chartType, value?.chartType1, value?.chartType2);
            macd_all_lines.macdSeries().stroke(value?.col1);
            macd_all_lines.signalSeries().stroke(value.col2);
            macd_all_lines.histogramSeries().stroke(value.col2);
            macd_all_lines.histogramSeries().fill((d) => {
              return d.value >= 0 ? 'green' : 'red';
            });
            let valueIndicatorMacd = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val3: value?.val3,
              show: value?.show,
              indData: macd_all_lines,
              plot: 3,
              col1: value?.col1,
              col2: value?.col2,
              col3: value?.col3,
              chartType: value?.chartType,
              chartType1: value?.chartType1,
              chartType2: value?.chartType2,
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorMacd])
            } else {
              let newIndListMacd = selectedIndicators.map(item => item.type == value.type ? valueIndicatorMacd : item);
              setSelectedIndicators([...newIndListMacd])
            }

            const yAxisMacd = plot_02Macd.yAxis();
            yAxisMacd.orientation('right');
            break;
          case 'ha':
            let plot_02Ha = passedChart.plot(4)
            var ha_1 = plot_02Ha.ha(mappingValue, value.chartType);
            ha_1.series().risingFill(value.col1);
            ha_1.series().fallingFill(value.col2);
            ha_1.series().risingStroke(null);
            ha_1.series().fallingStroke(null);
            let valueIndicatorHa = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: ha_1,
              chartType: value.chartType,
              plot: 4,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorHa])
            } else {
              let newIndListHa = selectedIndicators.map(item => item.type == value.type ? valueIndicatorHa : item);
              setSelectedIndicators([...newIndListHa])
            }

            const yAxisHa = plot_02Ha.yAxis();
            yAxisHa.orientation('right');
            break;
          case 'psar':
            var psar_1 = plot_0.psar(mappingValue, value?.val1, value?.val2, value?.val3)
            psar_1.series().stroke("0.5 lightGray");
            psar_1.series().fill("blue");
            psar_1.series().risingFill(value.col1);
            psar_1.series().fallingFill(value.col2);
            psar_1.series().risingStroke(null);
            psar_1.series().fallingStroke(null);
            psar_1.series().type("circle");
            psar_1.series().size(2);
            let valueIndicatorPsar = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val3: value?.val3,
              show: value?.show,
              indData: psar_1,
              chartType: value?.chartType,
              plot: 0,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorPsar])
            } else {
              let newIndListPsar = selectedIndicators.map(item => item.type == value.type ? valueIndicatorPsar : item);
              setSelectedIndicators([...newIndListPsar])
            }
            break;
          case 'priceChannels':
            var priceChannels_0 = plot_0.priceChannels(mappingValue, value?.val1, value?.chartType)
            priceChannels_0.middleSeries().stroke("2 " + value.col1);
            priceChannels_0.rangeSeries().fill(value.col2 + " 0.2");
            let valueIndicatorpriceChannels = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: priceChannels_0,
              chartType: value?.chartType,
              plot: 0,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorpriceChannels])
            } else {
              let newIndListpriceChannels = selectedIndicators.map(item => item.type == value.type ? valueIndicatorpriceChannels : item);
              setSelectedIndicators([...newIndListpriceChannels])
            }
            break;
          case 'ppo':
            let plot_02ppo = passedChart.plot(5)
            var ppo_1 = plot_02ppo.ppo(mappingValue, value?.val1, value?.val2, value?.val3, value.chartType, value.chartType1, value.chartType2);
            ppo_1.ppoSeries().stroke("0.5 " + value.col1);
            ppo_1.signalSeries().stroke("0.5 " + value.col2);
            ppo_1.histogramSeries().stroke("0.5 " + value.col3);
            let valueIndicatorppo = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val3: value?.val3,
              show: value?.show,
              indData: ppo_1,
              chartType: value.chartType,
              chartType1: value.chartType1,
              chartType2: value.chartType2,
              plot: 5,
              col1: value.col1,
              col2: value.col2,
              col3: value.col3
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorppo])
            } else {
              let newIndListPPO = selectedIndicators.map(item => item.type == value.type ? valueIndicatorppo : item);
              setSelectedIndicators([...newIndListPPO])
            }

            const yAxisppo = plot_02ppo.yAxis();
            yAxisppo.orientation('right');
            break;
          case 'psy':
            let plot_02psy = passedChart.plot(6)
            var psy_1 = plot_02psy.psy(mappingValue, value?.val1, value.chartType);
            let psy_1_series = psy_1.series()
            psy_1_series.stroke("0.5 " + value.col1);
            psy_1_series.fill(value.col2 + ' 0.5');
            let valueIndicatorpsy = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: psy_1,
              chartType: value.chartType,
              plot: 6
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorpsy])
            } else {
              let newIndListpsy = selectedIndicators.map(item => item.type == value.type ? valueIndicatorpsy : item);
              setSelectedIndicators([...newIndListpsy])
            }

            const yAxispsy = plot_02psy.yAxis();
            yAxispsy.orientation('right');
            break;
          case 'rci':

            let plot_02rci = passedChart.plot(7)
            var rci_1 = plot_02rci.rci(mappingValue, value?.val1, value.chartType);
            rci_1.series().stroke("1 " + value.col1);
            if (value.chartType == 'area' || value.chartType == 'column') {
              rci_1.series().fill("blue");
              rci_1.series().risingFill(value.col1);
              rci_1.series().fallingFill(value.col2);
              rci_1.series().risingStroke(null);
              rci_1.series().fallingStroke(null);
            }
            let valueIndicatorrci = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: rci_1,
              chartType: value.chartType,
              plot: 7,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorrci])
            } else {
              let newIndListrci = selectedIndicators.map(item => item.type == value.type ? valueIndicatorrci : item);
              setSelectedIndicators([...newIndListrci])
            }

            const yAxisrci = plot_02rci.yAxis();
            yAxisrci.orientation('right');
            break;
          case 'atr':
            let plot_2 = passedChart.plot(8)
            var atr_1 = plot_2.atr(mappingValue, value.val1, value.chartType);
            atr_1.series().stroke(value.col1);
            const yAxis0 = plot_2.yAxis();
            yAxis0.orientation('right');
            let valueIndicatoratr_1 = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: atr_1,
              plot: 8,
              chartType: value.chartType,
              col1: value.col1
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatoratr_1])
            } else {
              let newIndListatr_1 = selectedIndicators.map(item => item.type == value.type ? valueIndicatoratr_1 : item);
              setSelectedIndicators([...newIndListatr_1])
            }

            break;
          case 'roc':
            let plot_02roc = passedChart.plot(9)
            var roc_1 = plot_02roc.roc(mappingValue, value?.val1, value.chartType);
            roc_1.series().stroke(value.col1);
            if (value.chartType == 'area' || value.chartType == 'column') {
              roc_1.series().fill("blue");
              roc_1.series().risingFill(value.col1);
              roc_1.series().fallingFill(value.col2);
              roc_1.series().risingStroke(null);
              roc_1.series().fallingStroke(null);
            }
            let valueIndicatorroc = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: roc_1,
              chartType: value.chartType,
              plot: 9,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorroc])
            } else {
              let newIndListroc = selectedIndicators.map(item => item.type == value.type ? valueIndicatorroc : item);
              setSelectedIndicators([...newIndListroc])
            }

            const yAxisroc = plot_02roc.yAxis();
            yAxisroc.orientation('right');
            break;
          case 'rsi':

            let plot_02rsi = passedChart.plot(10)
            var rsi_1 = plot_02rsi.rsi(mappingValue, value?.val1, value.chartType);
            rsi_1.series().stroke(value.col1);
            if (value.chartType == 'area' || value.chartType == 'column') {
              rsi_1.series().fill("blue");
              rsi_1.series().risingFill(value.col1);
              rsi_1.series().fallingFill(value.col2);
              rsi_1.series().risingStroke(null);
              rsi_1.series().fallingStroke(null);
            }
            let valueIndicatorrsi = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: rsi_1,
              chartType: value.chartType,
              plot: 10,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorrsi])
            } else {
              let newIndListrsi = selectedIndicators.map(item => item.type == value.type ? valueIndicatorrsi : item);
              setSelectedIndicators([...newIndListrsi])
            }

            const yAxisrsi = plot_02rsi.yAxis();
            yAxisrsi.orientation('right');
            break;
          case 'sma':
            var sma = plot_0.sma(mappingValue, value.val1, value.chartType);
            sma.series().stroke(value.col1);
            if (value.chartType == 'area' || value.chartType == 'column') {
              sma.series().fill("blue" + ' 0.4');
              sma.series().risingFill(value.col1 + ' 0.4');
              sma.series().fallingFill(value.col2 + ' 0.4');
              sma.series().risingStroke(null);
              sma.series().fallingStroke(null);
            }
            let valueIndicatorSma = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: sma,
              plot: 0,
              chartType: value.chartType,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorSma])
            } else {
              let newIndListsma = selectedIndicators.map(item => item.type == value.type ? valueIndicatorSma : item);
              setSelectedIndicators([...newIndListsma])
            }

            break;
          case 'stochastic':

            let plot_02stochastic = passedChart.plot(11)
            var stochastic_1 = plot_02stochastic.stochastic(mappingValue, value.val1, "EMA", value.val2, "SMA", value.val3);
            let stochastic_k = stochastic_1.kSeries();
            stochastic_k.stroke(value.col1);
            let stochastic_d = stochastic_1.dSeries();
            stochastic_d.stroke(value.col2);
            let valueIndicatorstochastic = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val3: value?.val3,
              show: value?.show,
              indData: stochastic_1,
              chartType: value.chartType,
              plot: 11,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorstochastic])
            } else {
              let newIndListstochastic = selectedIndicators.map(item => item.type == value.type ? valueIndicatorstochastic : item);
              setSelectedIndicators([...newIndListstochastic])
            }

            const yAxisstochastic = plot_02stochastic.yAxis();
            yAxisstochastic.orientation('right');
            break;
          case 'trix':

            let plot_02trix = passedChart.plot(12)
            var trix_1 = plot_02trix.trix(mappingValue, value.val1, value.val2, "ema", "ema", "line", "stick");
            trix_1.trixSeries().stroke("1.5 " + value.col1);
            //trix_1.trixSeries().fill("#00838f 0.4");
            trix_1.signalSeries().stroke("1.5 " + value.col2);
            let valueIndicatortrix = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              show: value?.show,
              indData: trix_1,
              chartType: value.chartType,
              plot: 12,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatortrix])
            } else {
              let newIndListtrix = selectedIndicators.map(item => item.type == value.type ? valueIndicatortrix : item);
              setSelectedIndicators([...newIndListtrix])
            }

            const yAxistrix = plot_02trix.yAxis();
            yAxistrix.orientation('right');
            break;
          case 'volumeMa':
            let plot_02volumeMa = passedChart.plot(13)
            var volumeMa_1 = plot_02volumeMa.volumeMa(mappingValue, value.val1, "sma", "area", "stepLine");
            volumeMa_1.volumeSeries().stroke(null);
            volumeMa_1.volumeSeries().fill(value.col1 + " 0.4");
            volumeMa_1.maSeries().stroke("1.5 " + value.col2);
            let valueIndicatorvolumeMa = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: volumeMa_1,
              chartType: value.chartType,
              plot: 13,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorvolumeMa])
            } else {
              let newIndListvolumeMa = selectedIndicators.map(item => item.type == value.type ? valueIndicatorvolumeMa : item);
              setSelectedIndicators([...newIndListvolumeMa])
            }
            const yAxis01volumeMa = plot_02volumeMa.yAxis();
            yAxis01volumeMa.orientation('right');
            break;
          case 'williamsR':
            let plot_02williamsR = passedChart.plot(14)
            var williamsR_1 = plot_02williamsR.williamsR(mappingValue, value.val1, value.chartType);
            williamsR_1.series().stroke("0.5 " + value.col1);
            williamsR_1.series().fill(value.col2 + ' 0.5');
            let valueIndicatorwilliamsR = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: williamsR_1,
              chartType: value.chartType,
              plot: 14,
              col1: value.col1,
              col2: value.col2
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorwilliamsR])
            } else {
              let newIndListwilliamsR = selectedIndicators.map(item => item.type == value.type ? valueIndicatorwilliamsR : item);
              setSelectedIndicators([...newIndListwilliamsR])
            }
            const yAxis01williamsR = plot_02williamsR.yAxis();
            yAxis01williamsR.orientation('right');
            break;
          case 'aroon':
            let plot_02aroon = passedChart.plot(15)
            var aroon_1 = plot_02aroon.aroon(mappingValue, value.val1, "area");
            let valueIndicatoraroon = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: aroon_1,
              chartType: value.chartType,
              plot: 15
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatoraroon])
            } else {
              let newIndListaroon = selectedIndicators.map(item => item.type == value.type ? valueIndicatoraroon : item);
              setSelectedIndicators([...newIndListaroon])
            }
            const yAxis01aroon = plot_02aroon.yAxis();
            yAxis01aroon.orientation('right');
            break;
          case 'ao':
            let plot_02ao = passedChart.plot(16)
            var ao_1 = plot_02ao.ao(mappingValue, value.val1, value.val2, "sma", "stick");
            ao_1.series().risingStroke("#089981");
            ao_1.series().fallingStroke("#ec2d3c");
            ao_1.series().risingFill("#089981");
            ao_1.series().fallingFill("#ec2d3c");
            let valueIndicatorao = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: ao_1,
              chartType: value.chartType,
              plot: 16
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorao])
            } else {
              let newIndListao = selectedIndicators.map(item => item.type == value.type ? valueIndicatorao : item);
              setSelectedIndicators([...newIndListao])
            }
            const yAxis01ao = plot_02ao.yAxis();
            yAxis01ao.orientation('right');
            break;
          case 'bbandsB':
            let plot_02bbandsB = passedChart.plot(17)
            var bbandsB_1 = plot_02bbandsB.bbandsB(mappingValue, value.val1, value.val2, "spline");
            let valueIndicatorbbandsB = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: bbandsB_1,
              chartType: value.chartType,
              plot: 17
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorbbandsB])
            } else {
              let newIndListbbandsB = selectedIndicators.map(item => item.type == value.type ? valueIndicatorbbandsB : item);
              setSelectedIndicators([...newIndListbbandsB])
            }
            const yAxis01bbandsB = plot_02bbandsB.yAxis();
            yAxis01bbandsB.orientation('right');
            break;
          case 'bbandsWidth':
            let plot_02bbandsWidth = passedChart.plot(18)
            var bbandsWidth_1 = plot_02bbandsWidth.bbandsWidth(mappingValue, value.val1, value.val2, "spline");
            let valueIndicatorbbandsWidth = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: bbandsWidth_1,
              chartType: value.chartType,
              plot: 18
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorbbandsWidth])
            } else {
              let newIndListbbandsWidth = selectedIndicators.map(item => item.type == value.type ? valueIndicatorbbandsWidth : item);
              setSelectedIndicators([...newIndListbbandsWidth])
            }
            const yAxis01bbandsWidth = plot_02bbandsWidth.yAxis();
            yAxis01bbandsWidth.orientation('right');
            break;
          case 'cmf':
            let plot_02cmf = passedChart.plot(19)
            var cmf_1 = plot_02cmf.cmf(mappingValue, value.val1, "line");
            let valueIndicatorcmf = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: cmf_1,
              chartType: value.chartType,
              plot: 19
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorcmf])
            } else {
              let newIndListcmf = selectedIndicators.map(item => item.type == value.type ? valueIndicatorcmf : item);
              setSelectedIndicators([...newIndListcmf])
            }
            const yAxis01cmf = plot_02cmf.yAxis();
            yAxis01cmf.orientation('right');
            break;
          case 'cho':
            let plot_02cho = passedChart.plot(20)
            var cho_1 = plot_02cho.cho(mappingValue, value.val1, value.val2, "line");
            let valueIndicatorcho = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: cho_1,
              chartType: value.chartType,
              plot: 20
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorcho])
            } else {
              let newIndListcho = selectedIndicators.map(item => item.type == value.type ? valueIndicatorcho : item);
              setSelectedIndicators([...newIndListcho])
            }
            const yAxis01cho = plot_02cho.yAxis();
            yAxis01cho.orientation('right');
            break;
          case 'ikh':
            var ikh_1 = plot_0.ikh(mappingValue, value.val1, value.val2, value.val3, "line", "line", "range-area", "line");
            let valueIndicatorikh = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: ikh_1,
              chartType: value.chartType,
              plot: 0
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorikh])
            } else {
              let newIndListikh = selectedIndicators.map(item => item.type == value.type ? valueIndicatorikh : item);
              setSelectedIndicators([...newIndListikh])
            }
            break;
          case 'kdj':
            let plot_02kdj = passedChart.plot(21)
            var kdj_1 = plot_02kdj.kdj(mappingValue, value.val1, "EMA", value.val2, "SMA", value.val3);
            let valueIndicatorkdj = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: kdj_1,
              chartType: value.chartType,
              plot: 21
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorkdj])
            } else {
              let newIndListkdj = selectedIndicators.map(item => item.type == value.type ? valueIndicatorkdj : item);
              setSelectedIndicators([...newIndListkdj])
            }
            const yAxis01kdj = plot_02kdj.yAxis();
            yAxis01kdj.orientation('right');
            break;
          case 'mma':
            var mma_1 = plot_0.mma(mappingValue, value.val1);
            let valueIndicatormma = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: mma_1,
              chartType: value.chartType,
              plot: 0
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatormma])
            } else {
              let newIndListmma = selectedIndicators.map(item => item.type == value.type ? valueIndicatormma : item);
              setSelectedIndicators([...newIndListmma])
            }
            break;
          case 'momentum':
            let plot_02momentum = passedChart.plot(22)
            var momentum_1 = plot_02momentum.momentum(mappingValue, value.val1, "line");
            let valueIndicatormomentum = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: momentum_1,
              chartType: value.chartType,
              plot: 22
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatormomentum])
            } else {
              let newIndListmomentum = selectedIndicators.map(item => item.type == value.type ? valueIndicatormomentum : item);
              setSelectedIndicators([...newIndListmomentum])
            }
            const yAxis01momentum = plot_02momentum.yAxis();
            yAxis01momentum.orientation('right');
            break;
          case 'mfi':
            let plot_02mfi = passedChart.plot(23)
            var mfi_1 = plot_02mfi.mfi(mappingValue, value.val1, "line");
            let valueIndicatormfi = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: mfi_1,
              chartType: value.chartType,
              plot: 23
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatormfi])
            } else {
              let newIndListmfi = selectedIndicators.map(item => item.type == value.type ? valueIndicatormfi : item);
              setSelectedIndicators([...newIndListmfi])
            }
            const yAxis01mfi = plot_02mfi.yAxis();
            yAxis01mfi.orientation('right');
            break;
          case 'obv':
            let plot_02obv = passedChart.plot(24)
            var obv_1 = plot_02obv.obv(mappingValue, value.val1, "line");
            let valueIndicatorobv = {
              type: value.type,
              name: value.name,
              val1: value?.val1,
              val2: value?.val2,
              val2: value?.val3,
              show: value?.show,
              indData: obv_1,
              chartType: value.chartType,
              plot: 24
            }
            if (checkType) {
              setSelectedIndicators([...selectedIndicators, valueIndicatorobv])
            } else {
              let newIndListobv = selectedIndicators.map(item => item.type == value.type ? valueIndicatorobv : item);
              setSelectedIndicators([...newIndListobv])
            }
            const yAxis01obv = plot_02obv.yAxis();
            yAxis01obv.orientation('right');
            break;

          default:
          // code block
          // chartsSetting.risingStroke('#089981')
          // chartsSetting.risingFill('#089981');
          // chartsSetting.fallingStroke('#ec2d3c')
          // chartsSetting.fallingFill('#ec2d3c');
        }
      }
      passedChart.draw();
    }
  }
  const handleRemoveIndicator = (value) => {
    if (value.show && value.indData) {
      value.indData.dispose()
    }

    let newIndList = selectedIndicators.filter(item => item.type !== value.type);
    if (value.plot >= 2) {
      fullChart.plot(value.plot).dispose()
    }
    setSelectedIndicators(newIndList)
    fullChart.draw();
  }
  const handleShowHideIndicator = (value) => {
    let valueIndicator = {
      type: value.type,
      name: value.name,
      val1: value?.val1,
      val2: value?.val2,
      show: !value?.show,
      indData: value?.indData,
      plot: value.plot,
      col1: value?.col1,
      col2: value?.col2,
      col3: value?.col3,
      svType: value.svType,
      colWidth: value.colWidth,
      chartType: value.chartType
    }

    if (value?.show) {
      let newIndList = selectedIndicators.map(item => item.type == value.type ? valueIndicator : item);
      value.indData.dispose()
      if (value.plot >= 2) {
        fullChart.plot(value.plot).dispose()
      }

    
      setSelectedIndicators([...newIndList])
      fullChart.draw();
    } else {
      changeIndicators(valueIndicator, false, selectedFullChart)
    }

  }
  const handleOpenSettingIndicator = (value) => {
    setModalShowIndi(true)
    setSelectedIndicatorSetting(value)
  }
  const handleShowHideCountry = (id) => {
    var dropdown = document.querySelector(`#${id}`);
    if (dropdown.classList.contains('charting_show')) {
      dropdown.classList.remove('charting_show');
      dropdown.classList.add('charting_hide');
    } else {
      dropdown.classList.add('charting_show');
      dropdown.classList.remove('charting_hide');
    }
  }
  $(function () {

    $('.dropdown > .list > .item').on('click', function () {

      // Assuming you want to use this event to set user country and currency information
      // Update the UI by adding and removing classes
      // setShowHideCountry(!showHideCountry);
      $('.dropdown > .list > .item').removeClass('selected');
      $(this).addClass('selected').parent().parent().removeClass('open').children('.caption');
      let data_value = $(this).data('value')
      var settings = {
        thickness: drawWidth,
        color: drawCol,
        dash: data_value
      };
      // <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
      // <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
      {/* <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div> */ }
      let data_icon = data_value == '10 5' ? <CgBorderStyleDashed /> : data_value == '1 1' ? <RxBorderDotted /> : <TfiLayoutLineSolid />
      setDrawingVal({ ...drawingVal, drawDash: data_value, drawDashIcon: data_icon })
      setAnnotationStrokeSettings(settings)

      // Extract the selected value from the clicked item
      const selectedValue = $(this).text(); // You should set a data-value attribute on your HTML elements

      // Filter the country list based on the selected value
      // const selectedCountry = countryList.filter(val => val.key == selectedValue);

      // Set the user's selected country
      // setUserCountry(selectedValue);

      let symbolData;

      // if (selectedCountry.length > 0) {
      //     // Filter the currency data based on the selected country's code
      //     symbolData = CountryCurrencyList.filter(val => val.code == selectedCountry[0].value);
      // }
    });
    $('.dropdown1 > .list > .item').on('click', function () {

      // Assuming you want to use this event to set user country and currency information
      // Update the UI by adding and removing classes
      // setShowHideCountry(!showHideCountry);
      $('.dropdown > .list > .item').removeClass('selected');
      $(this).addClass('selected').parent().parent().removeClass('open').children('.caption');
      let data_value = $(this).data('value')
      var settings = {
        thickness: drawWidth,
        color: drawCol,
        dash: data_value
      };
      // <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
      // <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
      {/* <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div> */ }
      let data_icon = data_value == '10 5' ? <CgBorderStyleDashed /> : data_value == '1 1' ? <RxBorderDotted /> : <TfiLayoutLineSolid />
      setDrawingVal({ ...drawingVal, drawDash: data_value, drawDashIcon: data_icon })
      setAnnotationStrokeSettings(settings)

      // Extract the selected value from the clicked item
      const selectedValue = $(this).text(); // You should set a data-value attribute on your HTML elements

      // Filter the country list based on the selected value
      // const selectedCountry = countryList.filter(val => val.key == selectedValue);

      // Set the user's selected country
      // setUserCountry(selectedValue);

      let symbolData;

      // if (selectedCountry.length > 0) {
      //     // Filter the currency data based on the selected country's code
      //     symbolData = CountryCurrencyList.filter(val => val.code == selectedCountry[0].value);
      // }
    });
    $('.dropdown2 > .list > .item').on('click', function () {

      // Assuming you want to use this event to set user country and currency information
      // Update the UI by adding and removing classes
      // setShowHideCountry(!showHideCountry);
      $('.dropdown > .list > .item').removeClass('selected');
      $(this).addClass('selected').parent().parent().removeClass('open').children('.caption');
      let data_value = $(this).data('value')
      var settings = {
        thickness: makerBorder,
        color: makerBorderCol,
        dash: data_value
      };
      // <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
      // <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
      {/* <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div> */ }
      let data_icon = data_value == '10 5' ? <CgBorderStyleDashed /> : data_value == '1 1' ? <RxBorderDotted /> : <TfiLayoutLineSolid />
      setMakerVal({ ...makerVal, drawDash: data_value, makerDashIcon: data_icon })
      setAnnotationStrokeSettings(settings)
      const selectedValue = $(this).text();
      let symbolData;
    });
    // const [makerVal, setMakerVal] = useState({ makerCol: '#DA2828', makerBorderCol: '#DA2828', makerBorder: 1, makerLock: false, makerDash: 'none', makerSize: 100 })

    $(document).on('keyup', function (evt) {

      if ((evt.keyCode || evt.which) === 27) {
        // setShowHideCountry(!showHideCountry)
        $('.dropdown').removeClass('open');
      }
    });

    $(document).on('click', function (evt) {

      if ($(evt.target).closest(".dropdown > .caption").length === 0) {
        // setShowHideCountry(!showHideCountry)
        $('.dropdown').removeClass('open');
      }
    });

  });
  const showDashOptiondraWing = () => {
    var dropdown = document.querySelector('.dropdown');
    if (dropdown.classList.contains('open')) {
      dropdown.classList.remove('open');
    } else {
      dropdown.classList.add('open');
    }
  }
  $(function () {
    function dragElement(elmnt) {

      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("move_button")) {
        // if present, the move_button is where you move the DIV from:
        document.getElementById("move_button").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        setToolEditPosition({
          top: (elmnt.offsetTop - pos2) + "px",
          left: (elmnt.offsetLeft - pos1) + "px"
        })
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    if (document.getElementById("tools_edit")) {
      dragElement(document.getElementById("tools_edit"));
    }
    if (document.getElementById("textTools")) {
      dragElement(document.getElementById("textTools"));
    }
    if (document.getElementById("makerTool")) {
      dragElement(document.getElementById("makerTool"));
    }
  })

  const toggleTheme = (val) => {
    setThemeToggle(false)
    setSelectedTheme(val)
    // Set relevant theme settings based on the selected theme
    if (val === 'dark') {
      fullChart.background().enabled(fullChart.background().enabled());
      // Dark theme settings
      fullChart.background('#131722'); // Dark background color
      fullChart.plot().background('#131722'); // Dark plot background color
      fullChart.title().fontColor('#ffffff'); // White font color
      // ... other settings for dark theme
    } else {
      fullChart.background().enabled(!fullChart.background().enabled());
      // Light theme settings
      fullChart.background('#ffffff'); // Light background color
      fullChart.plot().background('#ffffff'); // Light plot background color
      fullChart.title().fontColor('#000000'); // Black font color
      // ... other settings for light theme
    }
    if (area >= 2) {
      if (val === 'dark') {
        fullChart2.background().enabled(fullChart2?.background().enabled());
        // Dark theme settings
        fullChart2.background('#131722'); // Dark background color
        fullChart2.plot().background('#131722'); // Dark plot background color
        fullChart2.title().fontColor('#ffffff'); // White font color
        // ... other settings for dark theme
      } else {
        fullChart2.background().enabled(!fullChart2?.background().enabled());
        // Light theme settings
        fullChart2.background('#ffffff'); // Light background color
        fullChart2.plot().background('#ffffff'); // Light plot background color
        fullChart2.title().fontColor('#000000'); // Black font color
        // ... other settings for light theme
      }
    }
    if (area >= 3) {
      if (val === 'dark') {
        fullChart3.background().enabled(fullChart3?.background().enabled());
        // Dark theme settings
        fullChart3.background('#131722'); // Dark background color
        fullChart3.plot().background('#131722'); // Dark plot background color
        fullChart3.title().fontColor('#ffffff'); // White font color
        // ... other settings for dark theme
      } else {
        fullChart3.background().enabled(!fullChart3?.background().enabled());
        // Light theme settings
        fullChart3.background('#ffffff'); // Light background color
        fullChart3.plot().background('#ffffff'); // Light plot background color
        fullChart3.title().fontColor('#000000'); // Black font color
        // ... other settings for light theme
      }
    }
    if (area >= 4) {
      if (val === 'dark') {
        fullChart4.background().enabled(fullChart4.background().enabled());
        // Dark theme settings
        fullChart4.background('#131722'); // Dark background color
        fullChart4.plot().background('#131722'); // Dark plot background color
        fullChart4.title().fontColor('#ffffff'); // White font color
        // ... other settings for dark theme
      } else {
        fullChart4.background().enabled(!fullChart4.background().enabled());
        // Light theme settings
        fullChart4.background('#ffffff'); // Light background color
        fullChart4.plot().background('#ffffff'); // Light plot background color
        fullChart4.title().fontColor('#000000'); // Black font color
        // ... other settings for light theme
      }
    }

    // Toggle background enabled state


    fullChart.draw(); // Redraw the chart after changing the theme
  };

  // Example of using the toggleTheme function
  const handleSplitArea = (are, typ) => {
    setSelectedFullChart(fullChart)
    setSplitedArea({
      area: are,
      splitedAreaType: typ
    })
  }
  useEffect(() => {
    if (stockDetailValue.length > 0) {
      CopysameCharts(selectedSeries.key)
    }
  }, [splitedAreaType]);

  const handleActive = (val, chart) => {
    setSelectedFullChart(chart)
    setSelectedSplitedArea(val)
  }

  // var container = document.getElementById("main_container");

  // Get the width of the container

  // const [containerWidthDragg, setContainerWidthDragg] = useState(containerWidth / 2);
  const [containerWidth, setContainerWidth] = useState();
  const [isResizing, setIsResizing] = useState(false);
  const [lastDownX, setLastDownX] = useState(0);


  $(function () {
    function dragElementChart1(elmnt) {
      debugger
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("containerWidthChange1")) {
        // if present, the move_button is where you move the DIV from:
        document.getElementById("containerWidthChange1").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        const deltaX = e.clientX;
        const newWidth = containerWidth - (deltaX - 54);
      
        if (containerWidth > newWidth) {

          document.getElementsByClassName('width_1')[0].style.width = (containerWidth - newWidth) + 'px'
          document.getElementsByClassName('width_2')[0].style.width = newWidth + 'px'
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    if (document.getElementById("containerWidthChange1")) {
      dragElementChart1(document.getElementById("containerWidthChange1"));
    }


    function dragElementChart2(elmnt) {

      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("containerWidthChange2")) {
        // if present, the move_button is where you move the DIV from:
        document.getElementById("containerWidthChange2").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        const deltaX = e.clientX;
        const newWidth = containerWidth - (deltaX - 54);
   
        if (containerWidth > newWidth) {

          document.getElementsByClassName('width_3')[0].style.width = (containerWidth - newWidth) + 'px'
          document.getElementsByClassName('width_4')[0].style.width = newWidth + 'px'
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    if (document.getElementById("containerWidthChange2")) {
      dragElementChart2(document.getElementById("containerWidthChange2"));
    }

    function dragElementChart3(elmnt) {

      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById("containerWidthChange3")) {
        // if present, the move_button is where you move the DIV from:
        document.getElementById("containerWidthChange3").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        const deltaY = e.clientY;
        const newWidth = height - (deltaY - 54);
        let cont1 = document.querySelectorAll('.heigh_1')
        let cont2 = document.querySelectorAll('.heigh_2')
        cont1.forEach(function (container) {
          container.style.height = (height - newWidth) + 'px'
        });
        cont2.forEach(function (container) {
          container.style.height = (newWidth - 101) + 'px'
        });
        // document.querySelectorAll('.heigh_1').style.height = (height - newWidth) + 'px'
        // document.querySelectorAll('.heigh_2').style.height = (newWidth - 101) + 'px'

      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    if (document.getElementById("containerWidthChange3")) {
      dragElementChart3(document.getElementById("containerWidthChange3"));
    }
  })

  var container = document.getElementById('main_container');
  useEffect(() => {
    if (container) {
      setContainerWidth(container.offsetWidth)
    }
    // Get the width of the container
    // var containerWidth = container.offsetWidth;
  }, [container?.offsetWidth])

  const handleMouseMoveSplit = (e) => {
    if (!isResizing) return;

    const deltaX = e.clientX - lastDownX;
    const newWidth = containerWidth + deltaX;


  };

  const handleFullScreen = () => {
    let mainDiv = document.getElementById('main_chart_lightWeight')
    mainDiv.requestFullscreen();
    setFullScreen(true)
  }
  const handleExitFullScreen = () => {
    let mainDiv = document.getElementById('main_chart_lightWeight')
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false)
    }
  }
  // const handleSaveXml=()=>{
  //   let n=selectedFullChart.toXml(true)

  // }
  const handleClickSearchOption=()=>{
    if(document.getElementById('searchStockInput')){

        document.getElementById('searchStockInput').focus()

    }
  }
  return (
    <>
      <div className={selectedTheme == 'dark' && 'anyChart_dark'} id='main_chart_lightWeight' >
        <div class="section">
          <div class="container-fluid g-0 anychartview top-nav">
            <div class="row">
              <div class="col-sm-9 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <div class="row">
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div class="form_serach">
                      <form class="form-inline my-2 my-lg-0 d-flex">
                        <input class="form-control mr-sm-2" type="search" data-bs-toggle="modal" onClick={handleClickSearchOption} data-bs-target="#viewStockList" placeholder="Search" aria-label="Search" />
                        <button class="btn btn-outline-success my-0 my-sm-0" data-bs-toggle="modal" onClick={handleClickSearchOption} data-bs-target="#viewStockList" type="button"><RiSearch2Line /></button>
                      </form>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-10 col-lg-4 col-xl-8 col-xxl-6">
                    <div class="btn_allbutton">

                      <div class="header_main">
                        <div className="window_screen">
                          <ul className="d-flex">

                            <li>
                              <div style={{ display: 'block', }}>
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Charts type
                                  </Tooltip>
                                )}
                                  placement="bottom">
                                  <button className='btn ' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#viewXMLs" ><RxPieChart />  Save chart</button>
                                </OverlayTrigger>
                              </div>
                            </li>

                            <li>
                              <div style={{ display: 'block' }}>
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Creat
                                  </Tooltip>
                                )}
                                  placement="bottom">
                                  <button className='btn ms-2 btn_lr_load' onClick={() => setSelectedTab('stock')} data-bs-toggle="modal" data-bs-target="#viewStockList" ><svg width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><rect width="32" height="32"></rect><path fill="currentColor" d="M9,9 L15.154,25 L17.52,17.521 L25,15.154 L9,9 Z M10.742,10.742 L21.975,15.062 L17.219,16.567 L16.724,16.724 L16.567,17.219 L15.062,21.976 L10.742,10.742 Z"></path></g></svg>  Select Stock </button>
                                </OverlayTrigger>
                              </div>
                            </li>

                            <li>
                              <div style={{ display: 'block', }}>
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Indicators view
                                  </Tooltip>
                                )} 
                                  placement="bottom">
                                  <button className='btn ms-2 btn_lr_line' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#indicators_view" > <AiOutlineBarChart />Indicators </button>
                                </OverlayTrigger>
                              </div>
                            </li>

                            <li>
                              <div style={{ display: 'block', }}>
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Chart Types
                                  </Tooltip>
                                )}
                                  placement="bottom">
                                  <ChartToolDropdown handleChangeChartType={handleChangeChartType} chartTypeOption={chartTypeOption} />
                                </OverlayTrigger>
                              </div>
                            </li>



                          </ul>
                        </div>
                      </div>




                      {/*<button className='btn ' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#viewXMLs" ><RxPieChart />  Save chart</button>*/}
                      {/* <button className='btn ms-2 btn_lr_load' onClick={() => setSelectedTab('stock')} data-bs-toggle="modal" data-bs-target="#viewXMLs" ><GrSelect />  Select Stock </button>*/}
                      {/* <button className='btn ms-auto' onClick={()=>setSelectedTab('watch')} data-bs-toggle="modal" data-bs-target="#viewXMLs" >View XML</button> */}

                      {/* <button className='btn ms-2 btn_lr_line' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#indicators_view" > <AiOutlineBarChart />Indicators </button>*/}


                      {/*  <ChartToolDropdown />*/}


                    </div>
                  </div>



                  {/* <div className='col-sm-6 col-md-3 col-lg-6 col-xl-6 col-xxl-6 d-flex'>

                  <div class="tools_sec d-none d-md-none d-lg-block">
                    <div class="toolbar">
                      <div class="btn-group hidden-xs">
                        {
                          chartSymbolButton.map((val, ind) => {
                            if (val.type == 1) {
                              return (
                                <>

                                  {['bottom'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`${placement}`}>
                                          {val.tooltip}
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" onClick={() => selectDrawType(val.value)} > {val.icon} </Button>
                                    </OverlayTrigger>
                                  ))}

                                </>
                              )
                            }
                          })
                        }



                      </div>
                      <div class="btn-group hidden-xs">
                        {
                          chartSymbolButton.map((val, ind) => {
                            if (val.type == 2) {
                              return (
                                <>

                                  {['bottom'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`${placement}`}>
                                          {val.tooltip}
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" onClick={() => selectDrawType(val.value)}> {val.icon} </Button>
                                    </OverlayTrigger>
                                  ))}

                                </>
                              )
                            }
                          })
                        }



                      </div>
                      <div class="btn-group hidden-xs">
                        {
                          chartSymbolButton.map((val, ind) => {
                            if (val.type == 3) {
                              return (
                                <>

                                  {['bottom'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`${placement}`}>
                                          {val.tooltip}
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" onClick={() => selectDrawType(val.value)}> {val.icon} </Button>
                                    </OverlayTrigger>
                                  ))}

                                </>
                              )
                            }
                          })
                        }



                      </div>
                      <div class="btn-group hidden-xs">
                        {
                          chartSymbolButton.map((val, ind) => {
                            if (val.type == 4) {
                              return (
                                <>

                                  {['bottom'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`${placement}`}>
                                          {val.tooltip}
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" onClick={() => selectDrawType(val.value)}>  {val.icon} </Button>
                                    </OverlayTrigger>
                                  ))}

                                </>
                              )
                            }
                          })
                        }



                      </div>
                      <div class="btn-group hidden-xs">
                        {
                          chartSymbolButton.map((val, ind) => {
                            if (val.type == 5) {
                              return (
                                <>

                                  {['bottom'].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`${placement}`}>
                                          {val.tooltip}
                                        </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary"> {val.icon} </Button>
                                    </OverlayTrigger>
                                  ))}

                                </>
                              )
                            }
                          })
                        }



                      </div>




                    </div>
                  </div>



                  {<select class="selectpicker choose-drawing-tools anyChartSelect d-block d-md-block d-lg-none" id='typeSelect' onChange={selectDrawType} title="Drawing tools" data-style="btn-lg" data-width="133"
                    data-max-options="1">
                    <optgroup label="Lines, Trend lines and Rays:">
                      <option value="default" selected>Annotation Type</option>
                      <option data-icon="ac-line" data-annotation-type="line" value={'line'}> Line Segment</option>
                      <option data-icon="ac-horizontal-line" data-annotation-type="horizontal-line" value='horizontal-line'> Horizontal
                        Line
                      </option>
                      <option data-icon="ac-vertical-line" data-annotation-type="vertical-line" value='vertical-line' >Vertical Line
                      </option>
                      <option data-icon="ac-infinite-line" data-annotation-type="infinite-line" value='infinite-line'>Infinite Line
                      </option>
                      <option data-icon="ac-ray" data-annotation-type="ray" value={'ray'} >Ray</option>
                    </optgroup>
                    <optgroup label="Geometric shapes:">
                      <option data-icon="ac-triangle" data-annotation-type="triangle" value={'triangle'}>Triangle</option>
                      <option data-icon="ac-rectangle" data-annotation-type="rectangle" value={'rectangle'}>Rectangle</option>
                      <option data-icon="ac-ellipse" data-annotation-type="ellipse" value={'ellipse'} >Ellipse</option>
                    </optgroup>
                    <optgroup label="Other tools:">
                      <option data-icon="ac-horizontal-range" data-annotation-type="horizontal-range" value={'horizontal-range'} >
                        Horizontal Range
                      </option>
                      <option data-icon="ac-vertical-range" data-annotation-type="vertical-range" value={'vertical-range'} >
                        Vertical Range
                      </option>
                    </optgroup>
                    <optgroup label="Other tools:">
                      <option data-icon="ac-trend-channel" data-annotation-type="trend-channel" value={'trend-channel'} >Trend Channel
                      </option>
                      <option data-icon="ac-finite-trend-channel" data-annotation-type="finite-trend-channel" value={'finite-trend-channel'} >Trend Channel
                      </option>
                      <option data-icon="ac-andrews-pitchfork" data-annotation-type="andrews-pitchfork" value={'andrews-pitchfork'} >
                        Andrew's
                        Pitchfork
                      </option>
                    </optgroup>
                    <optgroup label="Fibonacci tools:">
                      <option data-icon="ac-fibonacci-fan" data-annotation-type="fibonacci-fan" value={'fibonacci-fan'} >Fibonacci Fan
                      </option>
                      <option data-icon="ac-fibonacci-arc" data-annotation-type="fibonacci-arc" value={'fibonacci-arc'} >Fibonacci Arc
                      </option>
                      <option data-icon="ac-fibonacci-retracement" data-annotation-type="fibonacci-retracement" value={'fibonacci-retracement'}>Fibonacci
                        Retracement
                      </option>
                      <option data-icon="ac-fibonacci-timezones" data-annotation-type="fibonacci-timezones" value={'fibonacci-timezones'}>
                        Fibonacci Time
                        Zones
                      </option>
                    </optgroup>
                  </select>}

                </div> */}
                </div>
              </div>

              <div class="col-sm-3 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <div className='w-100'>
                  {/* <input type='text' placeholder='Search' className='search-input' /> */}
                  <div className='d-flex'>
                    <div className='topnav_anychart'>
                      <button data-action-type="removeAllAnnotations" type="button" class="btn btn_anyChart btn-default" data-bs-toggle="modal" data-bs-target="#saveChart"
                        aria-label="Center Align" data-toggle="tooltip" title="Save XML">
                        <BiSave />
                      </button>

                      <button type="button" class="btn btn_anyChart btn-default" onClick={handleAutoSizeAnnotation} id="annotation-label-autosize" aria-label="Auto-resize"
                        data-toggle="tooltip" title="Auto-resize">
                        <MdOutlineZoomOutMap />
                        {/* <i class="ac ac-enlarge" aria-hidden="true"></i> */}
                      </button>

                      <button data-action-type="removeAllAnnotations" type="button" onClick={removeAll} class="btn btn_anyChart btn-default"
                        aria-label="Center Align" data-toggle="tooltip" title="Delete all drawings">
                        <RiDeleteBin6Line />
                      </button>

                      <button data-action-type="removeSelectedAnnotation" onClick={removeSelectedAnnotation} type="button" class="btn btn_anyChart btn-default"
                        aria-label="Center Align" data-toggle="tooltip" title="Delete selected drawing">
                        <i class="ac ac-remove-thin" aria-hidden="true"></i>
                      </button>

                      {/* <button data-action-type="removeSelectedAnnotation" onClick={removeSelectedAnnotation} type="button" class="btn btn_publish btn-primary"
                              aria-label="Center Align" data-toggle="tooltip" title="Delete selected drawing">  
                              Publish
                            </button> */}

                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
        <div className='container-fluid g-0'>
          <div className=" d-flex">
            <div className='sidenav'>
              <textarea id='annotation-label' value={labelText} onChange={handleAddAnnomationText} ></textarea>

              <ul >
                <li>
                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                    <Tooltip {...props}>
                      Cursor
                    </Tooltip>
                  )}
                    placement="right">
                    <button onClick={handleSelectCursor} type="button" data-action-type="unSelectedAnnotation" class="btn charting_level_btn_main btn_anyChart btn-default"
                      aria-label="Center Align" data-toggle="tooltip" title="">
                      <i class="ac ac-mouse-pointer" aria-hidden="true"></i>
                    </button>
                  </OverlayTrigger>
                </li>
                {
                  leftSideOpen.map((val, ind) => {
                    return (

                      <li className='position-relative' >
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            {val.toolTipName}
                          </Tooltip>
                        )}
                          placement="right">
                          <button type="button" onClick={() => handleShowHideCountry(val.id)} data-action-type="unSelectedAnnotation" class=" charting_level_btn_main btn btn_anyChart btn-default"
                            aria-label="Center Align" id='drwing_main_button' data-toggle="tooltip" title="">
                            {val.icon}
                          </button>
                        </OverlayTrigger>
                        {val.options}
                      </li>
                    )
                  })
                }
                <li>
                  <div style={{ display: 'block', }}>
                    <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                      <Tooltip {...props}>
                        Indicators view
                      </Tooltip>
                    )}
                      placement="right">
                      <button className='charting_level_btn_main btn btn_anyChart btn-default' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#indicators_view" > <AiOutlineBarChart /> </button>
                    </OverlayTrigger>
                  </div>
                </li>
                <li>
                  <div style={{ display: 'block', }}>
                    <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                      <Tooltip {...props}>
                        Text
                      </Tooltip>
                    )}
                      placement="right">
                      <button className='charting_level_btn_main btn btn_anyChart btn-default' onClick={addText} > <BiText /> </button>
                    </OverlayTrigger>
                  </div>
                </li>




                {/* <li>
                <button onMouseEnter={() => handleShowColorPicker('stroke')}
                  onMouseLeave={() => handleHideColorPicker('stroke')} type="button" class="charting_level_btn_main position-relative btn btn_anyChart btn-default color-picker" data-style="btn-lg" data-color="stroke" data-toggle="tooltip"
                  title="Сhoose drawing stroke color"><span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': stroke }}></span>&nbsp;<b
                    class="caret"></b>
                  {type == 'stroke' && showHide && (
                    <div className="color-picker-popup">
                      <SketchPicker color={stroke} onChangeComplete={(val) => handleColorChange(val, 'stroke')} />

                    </div>
                  )}
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowBorderSetting(true)} onMouseLeave={() => setShowBorderSetting(false)} class="charting_level_btn_main btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                  data-toggle="popover"><BsBorderOuter />
                  {
                    showBorderSetting &&
                    <div className='multipalSelect_option'>
                      {
                        options.map((val, ind) => {
                          return (
                            <div className='d-inline' onClick={() => handleSelectStroke(val)}>
                              {
                                val?.stroke && <h6 className='mt-2'>{val?.stroke}</h6>
                              }
                              {
                                val.id < 6 && <button className={sWidth.id == val.id ? 'selected_strock button_border_size' : 'button_border_size'}>{val.name}</button>
                              }
                              {
                                val.id > 5 && <button className={sDash.id == val.id ? 'selected_strock button_border_size' : 'button_border_size'}>{val.name}</button>
                              }

                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </button>
              </li> */}
                <li>
                  <button type="button" onClick={() => setShowMaker(!showMaker)} class="charting_level_btn_main btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                    data-toggle="popover"><SiMakerbot />
                    {
                      showMaker &&
                      <div className='multipalSelect_option makerSelect_option'>
                        {/* makerOption */}
                        <h6>Select Maker</h6>
                        {
                          makerOption.map((val, ind) => {
                            return (
                              <div className={makerType == 'val.value' ? 'd-inline col-2 selected_strock' : 'd-inline col-2'} onClick={() => handleChangechoosemarker(val)}>

                                <button ><img src={val.key} style={{ 'max-width': '70%' }} class="img-fluid" /></button>

                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </button>
                </li>
                {/* <li>
                <button type="button" onMouseEnter={() => setShowFontDragerMaker(true)} onMouseLeave={() => setShowFontDragerMaker(false)} class="charting_level_btn_main btn btn_anyChart position-relative btn-default volume-btn" data-el-size="marker" data-volume="20"
                  data-toggle="popover"><BiPencil />
                  {
                    showFontDragerMaker &&
                    <div className='progressBar_font'>
                      <p>{progressWidthMaker} px</p>
                      <div className="progress-container" onMouseDown={handleMouseDownMaker} onMouseUp={handleMouseUpMaker} onMouseMove={handleMouseMoveMaker}>
                        <div className="progress-bar" style={{ width: progressWidthMaker + '%' }}></div>
                      </div>
                    </div>
                  }
                </button>
              </li> */}

              </ul>
            </div>
            <div className='flex-lg-fill'>
              <div onWheel={handleMouseWheel} style={{ 'height': `${height - 98}px` }} id='main_container' >
                {
                  drawingTool &&
                  <div className="modal-body blackout-bx tools_edit" style={{ 'top': top, 'left': left }} id='tools_edit' >
                    <div class="edit_main" id='move_button'>
                      <div class="edit_main_move">
                        <BiMove />
                      </div>
                    </div>
                    <div class="edit_main">
                      <div class="edit_main_child">
                        <button class="iocn_edit_option">
                          <MdDataSaverOn />
                        </button>
                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('drawing')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': drawCol }}></span>
                          <b
                            class="caret"></b>
                          {type == 'drawing' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={drawCol} onChangeComplete={(val) => handleColorChange(val, 'series')} />

                            </div>
                          )}
                        </button>

                        <button class="iocn_edit_option">
                          <div class="select_option_dropDown container">
                            <select value={drawWidth} onChange={(e) => handleColorChange(e.target.value, 'stroke')} >
                              <option value={1} >1</option>
                              <option value={2} >2</option>
                              <option value={3} >3</option>
                            </select>
                          </div>
                        </button>

                        <button class="iocn_edit_option">
                          <div class="select_option_dropDown container">
                            <div class="select_option_dropDown dropdown" id='showDropDownCountry'>
                              <div class="select_option_dropDown caption" onClick={showDashOptiondraWing}>{drawDashIcon}</div>
                              <div class="select_option_dropDown list">
                                <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
                                <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
                                <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div>
                              </div>
                            </div>
                          </div>
                        </button>
                        {/* <button class="iocn_edit_option"><AiOutlineEdit /></button> */}
                        {/* <button class="iocn_edit_option"><BiCreditCardFront /></button>
                        <button class="iocn_edit_option"><BiMessageSquareEdit /></button> */}
                        <button class="iocn_edit_option"><AiOutlineSetting /></button>
                        <button class="iocn_edit_option" onClick={handleLockUnlockDrawing}  > {drawLock ? <BiLockOpen /> : <BiLock />}  </button>
                        <button class="iocn_edit_option"  ><MdOutlineDeleteForever onClick={removeSelectedAnnotation} /></button>
                        <button class="iocn_edit_option"><BiDotsHorizontal /></button>
                        <button class="iocn_edit_option" onClick={handleHideEditTool} ><AiOutlineCloseSquare /></button>

                      </div>
                    </div>

                  </div>

                }

                {
                  textTool && <div className="modal-body blackout-bx tools_edit" style={{ 'top': top, 'left': left }} id='textTools'>
                    <div class="edit_main" id='move_button'>
                      <div class="edit_main_move">
                        <BiMove />
                      </div>
                    </div>
                    <div class="edit_main">
                      <div class="edit_main_child">
                        <button class="iocn_edit_option">
                          <MdDataSaverOn />
                        </button>
                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('background')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': series }}></span>
                          <b
                            class="caret"></b>
                          {type == 'background' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={series} onChangeComplete={(val) => handleColorChange(val, 'series')} />

                            </div>
                          )}
                        </button>
                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('text')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': fontCol }}></span>
                          <b
                            class="caret"></b>
                          {type == 'text' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={fontCol} onChangeComplete={(val) => handleColorChange(val, 'fontCol')} />

                            </div>
                          )}
                        </button>
                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('border')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': stroke }}></span>
                          <b
                            class="caret"></b>
                          {type == 'border' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={stroke} onChange={(val) => handleColorChange(val, 'stroke')} />
                            </div>
                          )}
                        </button>
                        <button class="iocn_edit_option">
                          <div class="select_option_dropDown container">
                            <select value={labelBorderSide} onChange={handleChangeLabelBorder} >
                              <option value={1} >1</option>
                              <option value={2} >2</option>
                              <option value={3} >3</option>
                            </select>
                          </div>
                        </button>
                        <button class="iocn_edit_option">
                          <div class="select_option_dropDown container">
                            <div class="select_option_dropDown dropdown dropdown1" id='showDropDownCountry'>
                              <div class="select_option_dropDown caption" onClick={showDashOptiondraWing}>{drawDashIcon}</div>
                              <div class="select_option_dropDown list">
                                <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
                                <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
                                <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div>
                              </div>
                            </div>
                          </div>
                        </button>
                        <button class="iocn_edit_option">
                          <div class="input_counting">
                            <input value={progressWidth} onChange={handleFontChange} />
                          </div>
                        </button>

                        <button type="button" onClick={() => setShowFontStyle(!showFontStyle)} class="iocn_edit_option" data-el-size="label" data-volume="15"
                          data-toggle="popover"><SiAdobefonts />
                          {
                            showFontStyle &&
                            <div className='multipalSelect_option'>
                              {
                                fontStyle.map((val) => {
                                  return (
                                    <div onClick={() => handleSelectFontStyle(val)}>
                                      {
                                        val?.group && <h6>{val?.group}</h6>
                                      }
                                      <p className={val.type == 0 ? fontWeight?.value == val?.value && 'selected_strock' : val.type == 1 ? fontDecoration?.value == val?.value && 'selected_strock' : val.type == 2 ? hAlign?.value == val?.value && 'selected_strock' : val.type == 3 ? anchor?.value == val?.value && 'selected_strock' : val.type == 4 ? vAlign?.value == val?.value && 'selected_strock' : ''}>{val.icon} {val.name}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }
                        </button>
                        {/* <button class="iocn_edit_option"><AiOutlineSetting /></button> */}
                        <button class="iocn_edit_option" onClick={handleLockUnlockDrawing}  > {drawLock ? <BiLockOpen /> : <BiLock />}  </button>
                        <button class="iocn_edit_option"  ><MdOutlineDeleteForever onClick={removeSelectedAnnotation} /></button>
                        <button class="iocn_edit_option" onClick={handleHideEditTool}><AiOutlineCloseSquare /></button>
                      </div>
                    </div>

                  </div>
                }

                {
                  makerTool && <div className="modal-body blackout-bx tools_edit" style={{ 'top': top, 'left': left }} id='makerTool'>
                    <div class="edit_main" id='move_button'>
                      <div class="edit_main_move">
                        <BiMove />
                      </div>
                    </div>
                    <div class="edit_main">
                      <div class="edit_main_child">
                        <button class="iocn_edit_option">
                          <MdDataSaverOn />
                        </button>
                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('makerCol')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': makerCol }}></span>
                          <b
                            class="caret"></b>
                          {type == 'makerCol' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={makerCol} onChangeComplete={(val) => handleColorChange(val, 'series')} />

                            </div>
                          )}
                        </button>

                        <button class="iocn_edit_option" onClick={() => handleShowColorPicker('makerBorderCol')} >
                          <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': makerBorderCol }}></span>
                          <b
                            class="caret"></b>
                          {type == 'makerBorderCol' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={makerBorderCol} onChangeComplete={(val) => handleColorChange(val, 'stroke')} />
                            </div>
                          )}
                        </button>
                        <select value={makerBorder} onChange={handleChangeMakerBorder} >
                          <option value={1} >1</option>
                          <option value={2} >2</option>
                          <option value={3} >3</option>
                        </select>
                        <button class="iocn_edit_option">
                          <div class="select_option_dropDown container">
                            <div class="select_option_dropDown dropdown dropdown2" id='showDropDownCountry'>
                              <div class="select_option_dropDown caption" onClick={showDashOptiondraWing}>{makerDashIcon}</div>
                              <div class="select_option_dropDown list">
                                <div class="select_option_dropDown item" data-value='null'><TfiLayoutLineSolid /></div>
                                <div class="select_option_dropDown item" data-value='1 1'><RxBorderDotted /></div>
                                <div class="select_option_dropDown item" data-value='10 5'><CgBorderStyleDashed /></div>
                              </div>
                            </div>
                          </div>
                        </button>
                        <button class="iocn_edit_option">
                          <div class="input_counting">
                            <input value={makerSize} onChange={handleMouseMoveMaker} />
                          </div>
                        </button>

                        {/* <button class="iocn_edit_option"><AiOutlineSetting /></button> */}
                        <button class="iocn_edit_option" onClick={handleLockUnlockDrawing}  > {drawLock ? <BiLockOpen /> : <BiLock />}  </button>
                        <button class="iocn_edit_option"  ><MdOutlineDeleteForever onClick={removeSelectedAnnotation} /></button>
                        <button class="iocn_edit_option" onClick={handleHideEditTool}><AiOutlineCloseSquare /></button>
                      </div>
                    </div>

                  </div>
                }

                <div className='selectedIndicators_main' >
                  {
                    selectedIndicators.map((val, ind) => {
                      return (
                        <div class="list_indicator">
                          {val.name}
                          <span className='show_icons_indicator ms-2 inline-block' >
                            {
                              val.show ? <span onClick={() => handleShowHideIndicator(val)}><AiOutlineEye size={20} /></span> : <span onClick={() => handleShowHideIndicator(val)}><AiOutlineEyeInvisible size={20} /></span>
                            }

                            <span onClick={() => handleOpenSettingIndicator(val)} ><AiOutlineSetting size={18} /></span>
                            <span onClick={() => handleRemoveIndicator(val)} ><RxCross2 size={20} /></span>
                          </span>
                        </div>
                      )
                    })
                  }
                  <IndicatorModal chartTypeOption={chartTypeOption} selectedFullChart={selectedFullChart} changeIndicators={changeIndicators} setModalShowIndi={setModalShowIndi} modalShowIndi={modalShowIndi} selectedIndicatorSetting={selectedIndicatorSetting} />
                </div>
                {
                  splitedAreaType == 1 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
                {
                  splitedAreaType == 2 &&
                  <div>
                    <div id="container" onClick={() => handleActive(1, fullChart)} className={selectedSplitedArea == 1 ? 'position-relative active_chart heigh_1' : 'position-relative heigh_1'} style={{ 'height': `${(height - 105) / 2}px` }}>
                    </div>
                    <div className='css-2q7ldg-Resizer-HorizontalResizer' id='containerWidthChange3' ></div>
                    <div id="container2" onClick={() => handleActive(2, fullChart2)} className={selectedSplitedArea == 2 ? 'position-relative active_chart heigh_2' : 'position-relative heigh_2'} style={{ 'height': `${(height - 105) / 2}px` }}>
                    </div>
                  </div>
                }
                {
                  splitedAreaType == 3 &&
                  <div className='d-flex'>
                    <div
                      id="container"
                      onClick={() => handleActive(1, fullChart)}
                      className={selectedSplitedArea === 1 ? 'position-relative active_chart width_1' : 'position-relative width_1'}
                      style={{ 'height': `${height - 105}px`, 'width': `${(containerWidth - 15) / 2}px` }}
                    ></div>

                    <div className='handle css-1aponxl-Resizer-VerticalResizer' id='containerWidthChange1' ></div>

                    <div
                      id="container2"
                      onClick={() => handleActive(2, fullChart2)}
                      className={selectedSplitedArea === 2 ? 'position-relative active_chart width_2' : 'position-relative width_2'}
                      style={{ 'height': `${height - 105}px`, 'width': `${(containerWidth - 15) / 2}px` }}
                    ></div>
                  </div>
                }
                {
                  splitedAreaType == 4 &&
                  <div>
                    <div className='d-flex heigh_1' style={{ 'height': `${(height - 105) / 2}px` }}>
                      <div id="container" onClick={() => handleActive(1, fullChart)} className={selectedSplitedArea === 1 ? 'position-relative active_chart width_1 heigh_1' : 'position-relative width_1 heigh_1'} style={{ 'height': `${(height - 105) / 2}px`, 'width': `${containerWidth / 2}px` }}>
                      </div>
                      <div className='handle css-1aponxl-Resizer-VerticalResizer' id='containerWidthChange1' ></div>

                      <div id="container2" onClick={() => handleActive(2, fullChart2)} className={selectedSplitedArea === 2 ? 'position-relative active_chart width_2 heigh_1' : 'position-relative width_2 heigh_1'} style={{ 'height': `${(height - 105) / 2}px`, 'width': `${containerWidth / 2}px` }}>
                      </div>
                    </div>
                    <div className='css-2q7ldg-Resizer-HorizontalResizer' id='containerWidthChange3' ></div>
                    <div className='d-flex heigh_2' style={{ 'height': `${(height - 105) / 2}px` }}>
                      <div id="container3" onClick={() => handleActive(3, fullChart3)} className={selectedSplitedArea === 3 ? 'position-relative active_chart width_3 heigh_2' : 'position-relative width_3 heigh_2'} style={{ 'height': `${(height - 105) / 2}px`, 'width': `${containerWidth / 2}px` }}>
                      </div>
                      <div className='handle css-1aponxl-Resizer-VerticalResizer' id='containerWidthChange2' ></div>

                      <div id="container4" onClick={() => handleActive(4, fullChart4)} className={selectedSplitedArea === 4 ? 'position-relative active_chart width_4 heigh_2' : 'position-relative width_4 heigh_2'} style={{ 'height': `${(height - 105) / 2}px`, 'width': `${containerWidth / 2}px` }}>
                      </div>
                    </div>
                  </div>

                }
                {
                  splitedAreaType == 5 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
                {
                  splitedAreaType == 6 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
                {
                  splitedAreaType == 7 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
                {
                  splitedAreaType == 8 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
                {
                  splitedAreaType == 9 &&
                  <div id="container" className='position-relative' style={{ 'height': `${height - 98}px` }}>
                  </div>
                }
              </div>
              <SaveChart selectedChartType={selectedChartType} scrolar={scrolar} selectedSeries={selectedSeries} viewXMLchartData={viewXMLchartData} stockDetailValue={stockDetailValue} stockCode={stockCode} fullChart={selectedFullChart} setRefressChartList={setRefressChartList} />
              <XmlChartList setScrolar={setScrolar} setStockFullDetails={setStockFullDetails} stockPriceList={stockPriceList} setReRenderChart={setReRenderChart} refressChartList={refressChartList} setRefressChartList={setRefressChartList} selectedTab={selectedTab} setStockCode={setStockCode} stockCode={stockCode} ViewXMLChart={ViewXMLChart} />
              <StocksList setScrolar={setScrolar} setStockFullDetails={setStockFullDetails} stockPriceList={stockPriceList} setReRenderChart={setReRenderChart} refressChartList={refressChartList} setRefressChartList={setRefressChartList} selectedTab={selectedTab} setStockCode={setStockCode} stockCode={stockCode} ViewXMLChart={ViewXMLChart} />
              {/* <div id="container"></div> */}
              <Indicators changeIndicators={changeIndicators} selectedFullChart={selectedFullChart} />
            </div>


            <AnychartSlideNav setReRenderChart={setReRenderChart} setStockCode={setStockCode} stockFullDetails={stockFullDetails} />

          </div>
        </div>

        <div class="footer_sec">
          <div class="container-fluid g-0 anychartview top-nav">
            <div class="row">
              <div class="col-sm-9 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                    <div class="chat_window">
                      <ul className="d-flex">
                        <li className='border_right_2_charting' >
                          <div className="window_screen">
                            <ul className="d-flex">

                              <li>
                                <div style={{ display: 'block', }}>
                                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                    <Tooltip {...props}>
                                      Full Chart [ Ctrl + 1]
                                    </Tooltip>
                                  )}
                                    placement="top"><Button variant="warning" onClick={() => handleSplitArea(1, 1)} >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21"><rect width="22" height="20" fill="none" stroke="currentColor" rx="2" transform="translate(.5 .5)"></rect></svg>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </li>
                              <li>
                                <div style={{ display: 'block', }}>
                                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                    <Tooltip {...props}>
                                      Two Row Chart
                                    </Tooltip>
                                  )}
                                    placement="top"><Button variant="warning" onClick={() => handleSplitArea(2, 2)} >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21"><g fill="none" stroke="currentColor" transform="translate(.5 .5)"><rect width="22" height="20" rx="2"></rect><path d="M0 10h22"></path></g></svg>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </li>
                              <li>
                                <div style={{ display: 'block', }}>
                                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                    <Tooltip {...props}>
                                      Two Column Chart [ Ctrl + 2]
                                    </Tooltip>
                                  )}
                                    placement="top"><Button variant="warning" onClick={() => handleSplitArea(2, 3)} >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21"><g fill="none" stroke="currentColor" transform="translate(.5 .5)"><rect width="22" height="20" rx="2"></rect><path d="M11 0v20"></path></g></svg>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </li>
                              <li>
                                <div style={{ display: 'block', }}>
                                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                    <Tooltip {...props}>
                                      Four Chart [ Ctrl + 4]
                                    </Tooltip>
                                  )}
                                    placement="top"><Button variant="warning" onClick={() => handleSplitArea(4, 4)} >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21"><g fill="none" stroke="currentColor" transform="translate(.5 .5)"><rect width="22" height="20" rx="2"></rect><path d="M0 10h22M11 0v20"></path></g></svg>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </li>
                              {/* <li>
                                <div style={{ display: 'block', }}>
                                  <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                    <Tooltip {...props}>
                                      More Layouts
                                    </Tooltip>
                                  )}
                                    placement="top"><Button variant="warning" className='bottom_icon'>
                                      <MdOutlineMoreHoriz />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                        </li>
                        <li className='border_right_2_charting ms-2'>
                          <div style={{ display: 'block', }} className='position-relative'>
                            <div className='theme_option_main' style={{ display: themeToggle ? 'block' : 'none' }} >
                              <p onClick={() => toggleTheme('dark')}>
                                {
                                  selectedTheme == 'dark' &&
                                  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" width="17" height="17"><polyline points="20 6 9 17 4 12"></polyline><title></title></svg>
                                }
                                &nbsp;
                                Dark Theme</p>
                              <p onClick={() => toggleTheme('light')}>
                                {
                                  selectedTheme == 'light' &&
                                  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" width="17" height="17"><polyline points="20 6 9 17 4 12"></polyline><title></title></svg>
                                }
                                &nbsp;Light Theme</p>
                            </div>
                            <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                              <Tooltip {...props}>
                                Theme
                              </Tooltip>
                            )}
                              placement="top">
                              <Button variant="warning" className='bottom_icon' onClick={() => setThemeToggle(!themeToggle)} >
                                <IoColorPaletteOutline />
                              </Button>


                            </OverlayTrigger>
                          </div>
                        </li>
                        <li>
                          <div style={{ display: 'block', }}>
                            {
                              !fullScreen ?
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Full Screen
                                  </Tooltip>
                                )}
                                  placement="top"><Button variant="warning" onClick={handleFullScreen} className='bottom_icon'>
                                    <BsFullscreen />
                                  </Button>
                                </OverlayTrigger> :
                                <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                                  <Tooltip {...props}>
                                    Exit Full Screen
                                  </Tooltip>
                                )}
                                  placement="top"><Button variant="warning" onClick={handleExitFullScreen} className='bottom_icon'>
                                    <BiExitFullscreen />
                                  </Button>
                                </OverlayTrigger>
                            }
                          </div>
                        </li>
                        {/* 
                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              Fullscreen [F11]
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                          </OverlayTrigger>
                        </div>
                      </li>
                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              Theme
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                          </OverlayTrigger>
                        </div>
                      </li>

                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              Save to cloud [Crtl + s ]
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> Save </Button>
                          </OverlayTrigger>
                        </div>
                      </li>

                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              Stock Fundamentals
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> Financial </Button>
                          </OverlayTrigger>
                        </div>
                      </li>

                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              Lenger
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> Lenger </Button>
                          </OverlayTrigger>
                        </div>
                      </li>

                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              New Order
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> Trade </Button>
                          </OverlayTrigger>
                        </div>
                      </li>

                      <li>
                        <div style={{ display: 'block', }}>
                          <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                            <Tooltip {...props}>
                              One Click Trade [F5]
                            </Tooltip>
                          )}
                            placement="top"><Button variant="warning"> <RxPieChart /> One Click </Button>
                          </OverlayTrigger>
                        </div>
                      </li> */}
                      </ul>
                    </div>
                  </div>


                </div>
              </div>

              {/* <div class="col-sm-3 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
              <div class="chat_window publish">
                <div className="window_screen">
                  <ul className="d-flex">
                    <li>
                      <div style={{ display: 'block', }}>
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            Support
                          </Tooltip>
                        )}
                          placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                        </OverlayTrigger>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: 'block', }}>
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            Hotkeys [F1]
                          </Tooltip>
                        )}
                          placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                        </OverlayTrigger>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: 'block', }}>
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            Feedback Report
                          </Tooltip>
                        )}
                          placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                        </OverlayTrigger>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: 'block', }}>
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            Record
                          </Tooltip>
                        )}
                          placement="top"><Button variant="warning"> <RxPieChart /> </Button>
                        </OverlayTrigger>
                      </div>
                    </li>
                    <li>

                      <div style={{ display: 'block', }}>
                        <OverlayTrigger delay={{ hide: 100, show: 100 }} overlay={(props) => (
                          <Tooltip {...props}>
                            Publish [Alt + p]
                          </Tooltip>
                        )}
                          placement="top"><Button variant="warning"> <RxPieChart /> Publish</Button>
                        </OverlayTrigger>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div> */}

            </div>
          </div>
        </div >
      </div>

    </>
  )
}

export default AnyChartView

