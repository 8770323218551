import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { IoAnalyticsOutline } from 'react-icons/io5';
import $ from 'jquery'
import IndicatorsChartType from './IndicatorsChartType';


function MyVerticallyCenteredModalEMA(props) {

  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      col1: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      col1: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  const handleCancelSetting = () => {
    props.onHide()
    changeIndicators(selectedIndicatorSetting, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Length
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Source
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select value={selectedIndicatorSetting?.svType} aria-label="Default select example">
                        <option value="open">Open</option>
                        <option value="high">High</option>
                        <option value="low">Low</option>
                        <option value="close">Close</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      <Form.Check label="EMA" />
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={handleCancelSetting}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalADL(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      col1: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      col1: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      <Form.Check label="EMA" />
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalAMA(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Length
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Fast EMA Length
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Slow EMA Length
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val3' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val3} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Label column sm={4}>
                    <Form.Check label="EMA" />
                  </Form.Label>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 3
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col3' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col3} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalATR(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      col1: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      col1: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Length
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Source
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select value={selectedIndicatorSetting?.svType} aria-label="Default select example">
                        <option value="open">Open</option>
                        <option value="high">High</option>
                        <option value="low">Low</option>
                        <option value="close">Close</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      <Form.Check label="EMA" />
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalBBD(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])
  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false,selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Length
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      StDev
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>





                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Upper
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Basic
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Lower
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col3' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col3} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType2'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalDMI(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])
  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Length
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      StDev
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>





                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      ADX
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      +DI
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      -DI
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col3' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col3} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType2'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalENV(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])
  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Length
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      StDev
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>





                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Upper
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Lower
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalHA(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])
  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })
    // console.log(e.target.value)
    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  // console.log(oldIndicatorValue.col1)
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec'>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Upper
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    {/* <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col> */}
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Lower
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    {/* <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col> */}
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalMACD(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Slow Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Signal Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val3' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val3} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Label column sm={4}>
                    <Form.Check label="EMA" />
                  </Form.Label>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 3
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col3' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col3} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType types={'chartType2'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalPSAR(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Slow Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Signal Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val3' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val3} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Rise
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Fall
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>
                  

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalpriceChannels(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false,selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Rise
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Fall
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>
                  

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalPPO(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Slow Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Signal Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val3' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val3} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType1'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 3
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col3' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col3} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType2'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalPSY(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalRCI(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

function MyVerticallyCenteredModalROC(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

function MyVerticallyCenteredModalRSI(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

function MyVerticallyCenteredModalSMA(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalSTOCHASTIC(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Slow Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Signal Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val3' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val3} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      {/* <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} /> */}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalTRIX(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Slow Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val2' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val2} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      {/* <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} /> */}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function MyVerticallyCenteredModalVOLUMEMA(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      {/* <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} /> */}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}

function MyVerticallyCenteredModalWILLIAMSR(props) {
  const [oldIndicatorValue, setOldIndicatorValue] = useState({
    name: '',
    type: '',
    icon: '',
    val1: '',
    val2: '',
    val3: '',
    show: true,
    col1: '',
    col2: '',
    col3: ''
  })
  const { changeIndicators,selectedFullChart, selectedIndicatorSetting, chartTypeOption } = props
  useEffect(() => {
    setOldIndicatorValue(selectedIndicatorSetting)
  }, [selectedIndicatorSetting])

  const handleChangeIndVal = (e) => {
    setOldIndicatorValue({ ...oldIndicatorValue, [e.target.name]: e.target.value })
  }
  const handleUpdateSetting = () => {
    changeIndicators(oldIndicatorValue, false, selectedFullChart)
  }
  const handleChangeStrockColor = (e) => {
    setOldIndicatorValue({
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    })

    let funVal = {
      ...oldIndicatorValue,
      [e.target.name]: e.target.value
    }
    changeIndicators(funVal, false, selectedFullChart)
  }
  return (
    <div class="modal_indigater_section">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal_indigater_sec modal-dialog-centered'>
        <Modal.Header closeButton >
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='mb-0 white_text'>{selectedIndicatorSetting.name}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="indigator_body">

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab className='white_text' eventKey="home" title="Inputs">
                <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                    Fast Period
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control type="number" name='val1' onBlur={handleUpdateSetting} onChange={handleChangeIndVal} value={oldIndicatorValue?.val1} placeholder="0" />
                    </Col>
                  </Form.Group>



                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Text className="text-muted">
                      Multi timeframe
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      Timeframe
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Select aria-label="Default select example">
                        <option>Chart Time Frame</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>



                  {/* <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 9, offset: 3 }}>
                      <Button type="submit">Sign in</Button>
                    </Col>
                  </Form.Group> */}
                </Form>

              </Tab>
              <Tab eventKey="profile" title="Style">
                <Form>
                  
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 1
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col1' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col1} title="Choose your color" />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} />
                    </Col>
                    <Col sm={2} class="icondrop_selct">

                      {/* <IndicatorsChartType selectedFullChart={selectedFullChart} types={'chartType'} changeIndicators={changeIndicators} setOldIndicatorValue={setOldIndicatorValue} oldIndicatorValue={oldIndicatorValue} chartTypeOption={chartTypeOption} /> */}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Color 2
                    </Form.Label>
                    <Col sm={2}>
                      <Form.Control type="color" name='col2' id="exampleColorInput" onChange={handleChangeStrockColor} value={oldIndicatorValue.col2} title="Choose your color" />
                    </Col>
                  </Form.Group>

                </Form>

              </Tab>

            </Tabs>

          </div>

        </Modal.Body>
        <Modal.Footer class="footer_md">
          <Form.Group as={Row} className="" controlId="formHorizontalEmail">
            <Col column sm={5}>
              <Form.Select aria-label="Default select example">
                <option>Defaults</option>
                <option value="1">Reset settings</option>
                <option value="2">Save as defaul</option>

              </Form.Select>
            </Col>
            <Form.Label column sm={7} className='btn_okcencle'>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={props.onHide}>Ok</Button>
            </Form.Label>

          </Form.Group>

        </Modal.Footer>
      </Modal>
    </div>
  );
}
function IndicatorModal({selectedFullChart, setModalShowIndi, chartTypeOption, modalShowIndi, changeIndicators, selectedIndicatorSetting }) {
  const [toolEditPosition, setToolEditPosition] = useState({
    top: '10px',
    left: '0px'
  })

  return (
    <>
      {/* <Button variant="primary" onClick={() => setModalShowIndi(true)}>
        Launch vertically centered modal
      </Button> */}
      {
        selectedIndicatorSetting?.type == 'ema' &&
        <MyVerticallyCenteredModalEMA
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'adl' &&
        <MyVerticallyCenteredModalADL
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'ama' &&
        <MyVerticallyCenteredModalAMA
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'atr' &&
        <MyVerticallyCenteredModalATR
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'bbands' &&
        <MyVerticallyCenteredModalBBD
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'dmi' &&
        <MyVerticallyCenteredModalDMI
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'env' &&
        <MyVerticallyCenteredModalENV
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'ha' &&
        <MyVerticallyCenteredModalHA
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'macd' &&
        <MyVerticallyCenteredModalMACD
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'psar' &&
        <MyVerticallyCenteredModalPSAR
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'priceChannels' &&
        <MyVerticallyCenteredModalpriceChannels
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'ppo' &&
        <MyVerticallyCenteredModalPPO
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'psy' &&
        <MyVerticallyCenteredModalPSY
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'rci' &&
        <MyVerticallyCenteredModalRCI
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'roc' &&
        <MyVerticallyCenteredModalROC
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'rsi' &&
        <MyVerticallyCenteredModalRSI
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'sma' &&
        <MyVerticallyCenteredModalSMA
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'stochastic' &&
        <MyVerticallyCenteredModalSTOCHASTIC
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'trix' &&
        <MyVerticallyCenteredModalTRIX
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'volumeMa' &&
        <MyVerticallyCenteredModalVOLUMEMA
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
      {
        selectedIndicatorSetting?.type == 'williamsR' &&
        <MyVerticallyCenteredModalWILLIAMSR
          chartTypeOption={chartTypeOption}
          changeIndicators={changeIndicators}
          selectedIndicatorSetting={selectedIndicatorSetting}
          show={modalShowIndi}
          onHide={() => setModalShowIndi(false)}
          selectedFullChart={selectedFullChart}
        />
      }
    </>
  );
}

export default IndicatorModal;
