import React, { useState, useEffect } from "react";
import { getNews } from "../../services/commonServices";

const NewsLeftSidebar = ({ setNewsData, currentDate, formatedendDate ,setIsLoading }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category, setCategory] = useState([
    { id: 1, text: "Blockchain", apiValue: "blockchain", checked: false },
    { id: 2, text: "Earnings", apiValue: "earnings", checked: false },
    { id: 3, text: "IPO", apiValue: "ipo", checked: false },
    { id: 4, text: "Mergers & Acquisitions", apiValue: "mergers_and_acquisitions", checked: false },
    { id: 5, text: "Financial Markets", apiValue: "financial_markets", checked: false },
    { id: 6, text: "Economy - Fiscal Policy", apiValue: "economy_fiscal", checked: false },
    { id: 7, text: "Economy - Monetary Policy ", apiValue: "economy_monetary", checked: false },
    { id: 8, text: "Economy - Macro/Overall", apiValue: "economy_macro", checked: false },
    { id: 9, text: "Energy & Transportation", apiValue: "energy_transportation", checked: false },
    { id: 10, text: "Finance", apiValue: "finance", checked: false },
    { id: 11, text: "Life Sciences", apiValue: "life_sciences", checked: false },
    { id: 12, text: "Manufacturing", apiValue: "manufacturing", checked: false },
    { id: 13, text: "Real Estate & Construction", apiValue: "real_estate", checked: false },
    { id: 14, text: "Retail & Wholesale", apiValue: "retail_wholesale", checked: false },
    { id: 15, text: "Technology", apiValue: "technology", checked: false },
  ]);

  const handleCheckboxChange = (itemId) => {
    setCategory((prevItems) =>
      prevItems.map((item) => {
        if (item.id === itemId) {
          const updatedItem = { ...item, checked: !item.checked };
          if (updatedItem.checked) {
            setSelectedCategories((prevSelected) => [...prevSelected, updatedItem.apiValue]);
          } else {
            setSelectedCategories((prevSelected) =>
              prevSelected.filter((apiValue) => apiValue !== updatedItem.apiValue)
            );
          }
          return updatedItem;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    setIsLoading(true);


    const categoryParam = selectedCategories.length > 0 ? selectedCategories.join(',') : '';
    const fromParam = formatedendDate || '';
    const toParam = currentDate || '';

    getNews(categoryParam, fromParam, toParam)
        .then((data) => {
          // console.log(data.data.feed ,"ffjdfhdfj")
        //  console.log(data.data.feed.length , "length ")
            setNewsData(data.data.feed);
        })
        .catch((error) => {
            console.error("API error:", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
}, [selectedCategories, formatedendDate, currentDate]);
 
  return (
    <div className="list" style={style.list}>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {category.map((category) => (
          <li key={category.id} style={style.categoryList}>
            <input
              type="checkbox"
              id={`item${category.id}`}
              checked={category.checked}
              onChange={() => handleCheckboxChange(category.id)}
              style={style.checkbox_bx}
            />
            <label htmlFor={`item${category.id}`}>{category.text}</label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NewsLeftSidebar

const style = {
  list: {
    borderWidth: 1,
    padding: 10,
    marginTop: 21,
  },
  categoryList: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    color: '#fff',
    fontFamily: 'sans-serif',
   
  },
  checkbox_bx : {
    marginRight: 10,
    height: 16, 
    width: "16px",
  }
}
