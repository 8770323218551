import React from 'react'
import '../assets/css/anyChart.css'
import anychart from 'anychart'
import '../assets/scss/anychart.css'
import { useEffect } from 'react'
import { useState } from 'react'
import $ from 'jquery'
import { SketchPicker } from 'react-color'
import Multiselect from 'multiselect-react-dropdown';
import { AiOutlineItalic, AiOutlineAlignCenter, AiOutlineVerticalAlignBottom, AiOutlineAlignLeft, AiOutlineVerticalAlignMiddle, AiOutlineAlignRight, AiOutlineVerticalAlignTop } from 'react-icons/ai'
import { FaBold, FaUnderline, FaStrikethrough } from 'react-icons/fa'
import { MdFormatOverline, MdStart, MdTrendingFlat } from 'react-icons/md'
import { BiDownArrow } from 'react-icons/bi'
import SaveChart from '../components/AnyChart/SaveChart'
import XmlChartList from '../components/AnyChart/XmlChartList'
import process from "process";
import { getStockDetails, getXmlsChartData } from '../services/TradeService'
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineSaveAlt } from "react-icons/md"
import { AiOutlineFontSize, AiOutlineBorder } from "react-icons/ai"
import { BiPencil, BiSave } from "react-icons/bi"
import { BsBorderOuter } from "react-icons/bs"
import { SiAdobefonts, SiMakerbot } from "react-icons/si"
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_BASE_URL


const AnyChartView = () => {
  const [reRenderChart, setReRenderChart] = useState(true)
  const [disableSeriesType, setDisableSeriesType] = useState(false)
  const [currentPrice, setCurrentPrice] = useState('')
  const [fullChart, setFullChart] = useState()
  const [colorPicker, setColoerPicker] = useState({ type: '', showHide: false })
  const [colors, setColors] = useState({ series: '#DA2828', stroke: '#EBE2E2', fontCol: '#E6D0D0' })
  const [selectedTab, setSelectedTab] = useState('stock')
  const { series, stroke, fontCol } = colors
  const [stockCode, setStockCode] = useState('')
  const [stockDetailValue, setStockDetailValue] = useState([])
  const { type, showHide } = colorPicker
  const [isDragging, setIsDragging] = useState(false);
  const [progressWidth, setProgressWidth] = useState(15);
  const [isDraggingMaker, setIsDraggingMaker] = useState(false);
  const [progressWidthMaker, setProgressWidthMaker] = useState(40);
  const [showFontDrager, setShowFontDrager] = useState(false)
  const [showFontDragerMaker, setShowFontDragerMaker] = useState(false)
  const [stockSelect, setStockSelect] = useState({
    sWidth: {
      name: '2 px',
      id: '2'
    },
    sDash: {
      stroke: 'Strok dash',
      name: 'Solid',
      id: '6'
    }
  })
  const { sWidth, sDash } = stockSelect
  const [showMulti, setShowMulti] = useState(false)
  const [showFontStyle, setShowFontStyle] = useState(false)
  const [fontStyling, setFontStyling] = useState({ anchor: null, fontDecoration: null, fontWeight: null, hAlign: null, vAlign: null })
  const { anchor, fontDecoration, fontWeight, hAlign, vAlign } = fontStyling
  const [makerType, setMakerType] = useState('')
  const [refressChartList, setRefressChartList] = useState(true)
  const [labelText, setLabelText] = useState('')
  const [showBorderSetting, setShowBorderSetting] = useState(false)
  const [showMaker, setShowMaker] = useState(false)
  const [sriesTypeData, setSeriesTypeData] = useState()
  const [selectedSeries, setSelectedSeries] = useState({
    name: 'line',
    key: 'line',
    icon: '',
    type: 'area'
  })
  const options = [

    {
      stroke: 'Strok Width',
      name: '0 px',
      id: '0'
    },
    {
      name: '1 px',
      id: '1'
    },
    {
      name: '2 px',
      id: '2'
    },
    {
      name: '3 px',
      id: '3'
    },
    {
      name: '4 px',
      id: '4'
    },
    {
      name: '5 px',
      id: '5'
    },
    {
      stroke: 'Strok dash',
      name: 'Solid',
      id: '6'
    },
    {
      name: 'Dotted',
      id: '7'
    },
    {
      name: 'Dashed',
      id: '8'
    }
  ]
  const fontStyle = [
    {
      name: 'Italic',
      value: 'italic',
      icon: <AiOutlineItalic />,
      group: 'Font style',
      type: '0'
    },
    {
      name: 'Bold',
      value: 'bold',
      icon: <FaBold />,
      type: '0'
    },
    {
      name: 'Line-through',
      value: 'line-through',
      icon: <FaStrikethrough />,
      group: 'Font decoration',
      type: '1'
    },
    {
      name: 'Overline',
      value: 'overline',
      icon: <MdFormatOverline />,
      type: '1'
    },
    {
      name: 'Underline',
      value: 'underline',
      icon: <FaUnderline />,
      type: '1'
    },
    {
      name: 'Center',
      value: 'center',
      icon: <AiOutlineAlignCenter />,
      group: 'Horizontal align',
      type: '2'
    },
    {
      name: 'Left',
      value: 'left',
      icon: <AiOutlineAlignLeft />,
      type: '2'
    },
    {
      name: 'Right',
      value: 'right',
      icon: <AiOutlineAlignRight />,
      type: '2'
    },
    {
      name: 'Start',
      value: 'start',
      icon: <MdStart />,
      type: '2'
    },
    {
      name: 'End',
      value: 'end',
      icon: <MdTrendingFlat />,
      type: '2'
    },
    {
      name: 'Center bottom',
      value: 'center-bottom',
      icon: ' ',
      group: 'Anchor',
      type: '3'
    },
    {
      name: 'Center top',
      value: 'center-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left bottom',
      value: 'left-bottom',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left center',
      value: 'left-center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Left top',
      value: 'left-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right bottom',
      value: 'right-bottom',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right center',
      value: 'right-center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Right top',
      value: 'right-top',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Center',
      value: 'center',
      icon: ' ',
      type: '3'
    },
    {
      name: 'Top',
      value: 'top',
      icon: <AiOutlineVerticalAlignTop />,
      group: 'Vertical align',
      type: '4'
    },
    {
      name: 'Middle',
      value: 'middle',
      icon: <AiOutlineVerticalAlignMiddle />,
      type: '4'
    },
    {
      name: 'Bottom',
      value: 'bottom',
      icon: <AiOutlineVerticalAlignBottom />,
      type: '4'
    }
  ]
  const makerOption = [
    {
      key: 'assets/image/icons/uparrow.png',
      value: 'arrowUp'
    },
    {
      key: 'assets/image/icons/downarrow.png',
      value: 'arrow-down'
    },
    {
      key: 'assets/image/icons/left-arrow.png',
      value: 'arrow-left'
    },
    {
      key: 'assets/image/icons/rightarrow.png',
      value: 'arrow-right'
    },
    {
      key: 'assets/image/icons/arrowHead.png',
      value: 'arrowHead'
    },
    {
      key: 'assets/image/icons/addition.png',
      value: 'cross'
    },
    {
      key: 'assets/image/icons/cross.png',
      value: 'diagonal-cross'
    },
    {
      key: 'assets/image/icons/diamond.png',
      value: 'diamond'
    },
    {
      key: 'assets/image/icons/pentagon.png',
      value: 'pentagon'
    },
    {
      key: 'assets/image/icons/square.png',
      value: 'square'
    },
    {
      key: 'assets/image/icons/star10.png',
      value: 'star10'
    },
    {
      key: 'assets/image/icons/star4.png',
      value: 'star4'
    },
    {
      key: 'assets/image/icons/star5.png',
      value: 'star5'
    },
    {
      key: 'assets/image/icons/star6.png',
      value: 'star6'
    },
    {
      key: 'assets/image/icons/star7.png',
      value: 'star7'
    },
    {
      key: 'assets/image/icons/trapezium.png',
      value: 'trapezium'
    },
    {
      key: 'assets/image/icons/tup.png',
      value: 'triangle-up'
    },
    {
      key: 'assets/image/icons/tdown.png',
      value: 'triangle-down'
    },
    {
      key: 'assets/image/icons/tleft.png',
      value: 'triangle-left'
    },
    {
      key: 'assets/image/icons/tright.png',
      value: 'triangle-right'
    },
  ]
  const chartTypeOption = [
    {
      name: 'Area',
      key: 'area',
      icon: '',
      type: 'area'
    },
    {
      name: 'Line',
      key: 'line',
      icon: '',
      type: 'area'
    },
    {
      name: 'Step Line',
      key: 'step-line',
      icon: '',
      type: 'area'
    },
    {
      name: 'Column',
      key: 'column',
      icon: '',
      type: 'column'
    },
    {
      name: 'Bars',
      key: 'ohlc',
      icon: '',
      type: 'column'
    },
    {
      name: 'Candlestick',
      key: 'candlestick',
      icon: '',
      type: 'column'
    },
    {
      name: 'Hilo',
      key: 'hilo',
      icon: '',
      type: 'column'
    },
    {
      name: 'range-area',
      key: 'range-area',
      icon: '',
      type: 'column'
    },
  ]
  const indicatorTypeOption = [
    {
      name: 'Moving Average',
      type: '',
      icon: ''
    },
    {
      name: '',
      type: '',
      icon: ''
    },
    {
      name: '',
      type: '',
      icon: ''
    },
    {
      name: '',
      type: '',
      icon: ''
    },
  ]

  const stockPriceList = (check, code) => {
    let data = {
      tradeCode: code,
      stockDate: 1
    }
    if (data?.tradeCode?.length > 0) {

      try {
        getStockDetails(data).then((res) => {
          const timeSeriesData = res.data["Time Series (Daily)"];
          // console.log(timeSeriesData)
          const transformedData = [];
          for (const date in timeSeriesData) {
            if (timeSeriesData.hasOwnProperty(date)) {
              const record = timeSeriesData[date];
              transformedData.push({
                x: date,
                open: parseFloat(record["1. open"]),
                high: parseFloat(record["2. high"]),
                low: parseFloat(record["3. low"]),
                close: parseFloat(record["4. close"]), // Convert close price to a floating-point number
                volume: parseFloat(record["5. volume"]),
                value: parseFloat(record["4. close"])
              });
            }
          }
          // Sort the data by date
          transformedData.sort((a, b) => new Date(a.x) - new Date(b.x));
          if (transformedData.length > 0 && fullChart) {
            fullChart.dispose();
          }
          if (check == 0) {
            setDisableSeriesType(false)
            setStockDetailValue(transformedData);
          }
          setCurrentPrice(transformedData[transformedData?.length - 1]?.value || '')
          setReRenderChart(false)
          // console.log(transformedData);
        })
      } catch (error) {

      }
    }
  }


  useEffect(() => {
    if (reRenderChart == true) {
      stockPriceList('0', stockCode)
    }
  }, [stockCode])

  const handleChangePreSelectedChartType = (val, series) => {
    let value = val.key
    series.seriesType(value)
  }

  useEffect(() => {

    // createPageColorPicker();
    // create a stock chart
    if (stockDetailValue.length > 0) {
      const dataTable = anychart.data.table();
      dataTable.addData(stockDetailValue);
      const mapping = dataTable.mapAs({
        'open': 'open',
        'high': 'high',
        'low': 'low',
        'close': 'close',
        'volume': 'volume',
        'x': 'x',
        'value': 'value'
      });
      var chart = anychart.stock();
      var lineSeries = chart.plot(0).line(stockDetailValue.map((val) => ([val.x, val.value])));
      setSeriesTypeData(lineSeries)
      if (selectedSeries.key !== 'line') {
        handleChangePreSelectedChartType(selectedSeries, lineSeries)
      }
      lineSeries.risingStroke('#089981');
      lineSeries.risingFill('#089981');

      lineSeries.fallingStroke('#f23645');
      lineSeries.fallingFill('#f23645');
      lineSeries.name("CSCO");
      // chart.plot(0).ohlc(mapping).name('ACME Corp.');

      // add sma indicator
      chart.plot(0).sma(stockDetailValue.map((val) => ([val.x, val.value])), 10);
      // var xml = chart.plot(0).line().toXml();
      chart.draw();
      chart.container("container");

      setFullChart(chart)
      chart.listen("annotationDrawingFinish", function () {
        document.getElementById("typeSelect").value = "default";
      });

      return () => {
        if (chart) {
          chart.dispose();
        }
      };
    }
    // if (stockDetailValue.length > 0) {
    //   const dataTable = anychart.data.table();
    //   dataTable.addData(stockDetailValue);
    //   const mapping = dataTable.mapAs({
    //     'open': 'open',
    //     'high': 'high',
    //     'low': 'low',
    //     'close': 'close',
    //     'volume': 'volume',
    //     'x': 'x',
    //     'value': 'value'
    //   });
    //   var chart = anychart.line();
    //   var lineSeries = chart.line(stockDetailValue);
    //   setSeriesTypeData(lineSeries)
    //   if (selectedSeries.key !== 'line') {
    //     handleChangePreSelectedChartType(selectedSeries, lineSeries)
    //   }
    //   lineSeries.risingStroke('#089981');
    //   lineSeries.risingFill('#089981');

    //   lineSeries.fallingStroke('#f23645');
    //   lineSeries.fallingFill('#f23645');
    //   lineSeries.name("CSCO");
    //   // chart.title("Initiating Drawing");
    //   var currentIndicator;
    //   // if (indicatorType === 'sma') {
    //     // currentIndicator = anychart.plot.sma;
    //     // console.log(chart.plot)
    //     // let smaSeries = chart.plot(0).sma(lineSeries);
    //     // smaSeries.period(10);
    //     // currentIndicator.period(10); // Set the SMA period
    //   // }
    //   chart.container("container");
    //   console.log(chart.toXml())
    //   // chart.xZoom().setToPointsCount(20);
    //   chart.xScroller().enabled(true);

    //   // Manually set the zoom range to show the latest data
    //   // chart.xZoom().setTo(stockDetailValue.length - stockDetailValue.length, stockDetailValue.length - 1);

    //   // Set the default zoom level to display the latest data
    //   //   var xAxis = chart.xAxis();

    //   // // Custom function to format x-axis labels
    //   // function formatXAxisLabels(ind) {
    //   //   let index=ind.index
    //   //   console.log(index)
    //   //   const currentPoint = stockDetailValue[index];
    //   //   const previousPoint = stockDetailValue[index - 1];

    //   //   // Check if the previous point's month or year is different
    //   //   if (
    //   //     index === 0 || // First point
    //   //     new Date(currentPoint.x).getMonth() !== new Date(previousPoint.x).getMonth() ||
    //   //     new Date(currentPoint.x).getFullYear() !== new Date(previousPoint.x).getFullYear()
    //   //   ) {
    //   //     // Show the full date
    //   //     return currentPoint.x;
    //   //   } else {
    //   //     // Show only the day
    //   //     return currentPoint.x.split('-')[2];
    //   //   }
    //   // }

    //   // // Apply the custom function to format x-axis labels
    //   // xAxis.labels().format(formatXAxisLabels);
    //   chart.draw();

    //   // var latestDataPoint = stockDetailValue[stockDetailValue.length - 1];
    //   // chart.xScale().setMinimum(latestDataPoint);
    //   // chart.xScale().setMaximum(stockDetailValue[stockDetailValue.length - 1].x);

    //   setFullChart(chart)
    //   chart.listen("annotationDrawingFinish", function () {
    //     document.getElementById("typeSelect").value = "default";
    //   });

    //   return () => {
    //     if (chart) {
    //       chart.dispose();
    //     }
    //   };
    // }
  }, [stockDetailValue]);

  // create annotations
  function selectDrawType(e) {
    let n = fullChart.annotations().startDrawing(e.target.value);
  }
  const clickDrawType = (val) => {
    fullChart.annotations().startDrawing(val);
  }
  function removeSelectedAnnotation() {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation) fullChart.annotations().removeAnnotation(annotation);

    return !!annotation;
  }
  function removeAll() {
    fullChart.annotations().removeAllAnnotations();
  }



  const saveChart = () => {
    fullChart.saveAsJpg({
      "width": window.innerWidth,
      "height": window.innerHeight,
      "quality": 1,
      "forceTransparentWhite": false,
      "filename": "My Chart JPG"
    });
  }
  let height = window.innerHeight;
  document.body.style.height = `${height}px`;

  const handleShowColorPicker = (val) => {
    setColoerPicker({ type: val, showHide: true })
  }
  const handleHideColorPicker = () => {
    setColoerPicker({ type: '', showHide: false })
  }
  function setAnnotationStrokeSettings(settings) {
    debugger
    var annotation = fullChart.annotations().getSelectedAnnotation();
    // console.log(annotation)
    if (annotation?.type == 'marker') {
      annotation.stroke(settings);

      if (annotation.hovered || annotation.selected) {
        annotation.hovered().stroke(settings);
        annotation.selected().stroke(settings);
      }
    } else {

      annotation?.background().stroke(settings);

      if (annotation?.hovered || annotation?.selected) {
        annotation.hovered().stroke(settings);
        annotation.selected().stroke(settings);
      }
    }
  }
  // console.log(fullChart)
  const handleColorChange = (val, key) => {
    debugger
    var annotationLabel = document.querySelector('#annotation-label');
    var annotation = fullChart?.annotations()?.getSelectedAnnotation();

    if (annotation?.type === 'label') {
      annotationLabel.focus();
      if (key == 'series') {
        setColors({ ...colors, series: val.hex })
        annotation = annotation.background();
        annotation.fill(val.hex);
      } else if (key == 'stroke') {
        setColors({ ...colors, stroke: val.hex })
        var settings = {
          thickness: sWidth.id,
          color: val.hex,
          dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
        };
        setAnnotationStrokeSettings(settings)
      } else {
        setColors({ ...colors, fontCol: val.hex })
        annotation.fontColor(val.hex)
      }
    } else if (annotation?.type === 'marker') {
      if (key == 'series') {
        setColors({ ...colors, series: val.hex })
        annotation.fill(val.hex);
      } else if (key == 'stroke') {
        setColors({ ...colors, stroke: val.hex })
        var settings = {
          thickness: sWidth.id,
          color: val.hex,
          dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
        };
        setAnnotationStrokeSettings(settings)
      } else {
        setColors({ ...colors, fontCol: val.hex })
        annotation.fontColor(val.hex)
      }
    } else {
      if (key == 'series') {
        setColors({ ...colors, series: val.hex })
        // annotation = annotation.background();
        // annotation.fill(val.hex);
      } else if (key == 'stroke') {
        setColors({ ...colors, stroke: val.hex })
        var settings = {
          thickness: sWidth.id,
          color: stroke,
          dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
        };
        setAnnotationStrokeSettings(settings)
      } else {
        setColors({ ...colors, fontCol: val.hex })
        // annotation.fontColor(val.hex)
      }
    }


  }
  const handleSelectCursor = () => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    fullChart.annotations().unselect(annotation).cancelDrawing();
  }
  const createMaker = (makerSetting) => {
    fullChart.annotations().startDrawing(makerSetting);
  }
  const handleChangechoosemarker = (val) => {
    let type = val.value
    setMakerType(type)
    var strokeSettings = {
      thickness: sWidth.id,
      color: stroke,
      dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
    };
    let background = {
      fill: series,
      stroke: strokeSettings
    }
    let background1 = {
      stroke: strokeSettings
    }

    let makerSetting = {
      type: "marker",
      markerType: type,
      fill: series,
      anchor: anchor?.value || null,
      stroke: background,
      fontColor: fontCol,
      fontDecoration: fontDecoration?.value || null,
      fontSize: progressWidth,
      fontStyle: '',
      fontWeight: fontWeight?.value || null,
      hAlign: hAlign?.value || null,
      hovered: {
        stroke: background1
      },
      selected: {
        stroke: background1
      },
      size: progressWidthMaker,
      vAlign: vAlign?.value || null
    }
    createMaker(makerSetting)
    setShowMaker(false)
  }

  var annotation = fullChart?.annotations()?.getSelectedAnnotation();
  const addText = () => {
    var annotationLabel = document.querySelector('#annotation-label');
    annotationLabel.focus()
    var strokeSettings = {
      thickness: sWidth.id,
      color: stroke,
      dash: sDash.id == 8 ? '10 5' : sDash.id == 7 ? '1 1' : null
    };
    let background = {
      fill: series,
      stroke: strokeSettings
    }
    let background1 = {
      stroke: strokeSettings
    }

    let labelSetting = {
      type: "label",
      anchor: anchor?.value || null,
      background: background,
      fontColor: fontCol,
      fontDecoration: fontDecoration?.value || null,
      fontSize: progressWidth,
      fontStyle: '',
      fontWeight: fontWeight?.value || null,
      hAlign: hAlign?.value || null,
      hovered: {
        background: background1
      },
      selected: {
        background: background1
      },
      size: '',
      vAlign: vAlign?.value || null
    }
    fullChart.annotations().startDrawing(labelSetting)
  }
  const handleAutoSizeAnnotation = () => {
    var annotation = fullChart.annotations().getSelectedAnnotation();

    if (annotation && annotation.type === 'label') {
      annotation.width(null);
      annotation.height(null);
    }
    document.querySelector('body').querySelector('#annotation-label').focus()
  }

  const handleSelectStroke = (value) => {

    let n = stockSelect
    if (value.id < 6) {
      n.sWidth.id = value.id
      n.sWidth.name = value.name
      setStockSelect(n)
    } else {
      n.sDash.id = value.id
      n.sDash.name = value.name
      setStockSelect(n)
    }
    setShowBorderSetting(false)
    if (annotation?.type == 'label') {
      var settings = {
        thickness: n.sWidth.id,
        color: stroke,
        dash: n.sDash.id == 8 ? '10 5' : n.sDash.id == 7 ? '1 1' : null
      };

      setAnnotationStrokeSettings(settings);
    }
  }
  const handleAddAnnomationText = (e) => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    if (annotation && annotation.type === 'label') {
      if (e.keyCode === 46) return;

      try {
        annotation = fullChart.annotations().getSelectedAnnotation();
        annotation.enabled();
      } catch (err) {
        annotation = null;
      }

      if (annotation) {
        if (e.target.value) {
          annotation.text(e.target.value);
          setLabelText(e.target.value)
        } else {
          annotation.text(' ');
          setLabelText('')
        }
      }
    }
  }
  if (fullChart != undefined && fullChart != null) {

    fullChart?.listen('annotationDrawingFinish', function (e) {
      var annotationLabel = document.querySelector('#annotation-label');
      // console.log(e.annotation.type)
      if (e.annotation.type === 'label') {
        let n = e.annotation.text()
        setLabelText(n)
        annotationLabel.focus();
        // const handleAddAnnomationText = (e) => {

        // }
        // annotationLabel.removeEventListener('change', handleAddAnnomationText);
        // annotationLabel.addEventListener('change', handleAddAnnomationText);


        fullChart.listen('annotationDrawingFinish', function (e) {
          if (e.annotation.type === 'label') {
            let n = e.annotation.text()
            setLabelText(n)
            annotationLabel.focus();
          }
        });

        fullChart.listen('annotationSelect', function (e) {
          if (e.annotation.type === 'label') {
            let n = e.annotation.text()
            setLabelText(n)
            annotationLabel.focus();
          }
        });

        fullChart.listen('annotationUnselect', function (e) {
          if (e.annotation.type === 'label') {
            annotationLabel.value = '';
          }
        });
      }
    });
  }
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    let type = 'label'
    if (isDragging) {
      updateProgress(e, type);
    }
  };

  const handleMouseDownMaker = () => {
    setIsDraggingMaker(true);
  };

  const handleMouseUpMaker = () => {
    setIsDraggingMaker(false);
  };

  const handleMouseMoveMaker = (e) => {
    let type = 'marker'
    if (isDraggingMaker) {
      updateProgress(e, type);
    }
  };
  const updateProgress = (e, type) => {
    var annotation = fullChart.annotations().getSelectedAnnotation();
    const container = document.querySelector('.progress-container');
    const containerRect = container.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const width = containerRect.width;

    const percentage = (x / width) * 100;
    if (type == 'label') {

      if (percentage >= 0 && percentage <= 100) {
        setProgressWidth(Math.round(percentage));

        if (annotation?.type == 'label') {
          annotation.fontSize(Math.round(percentage))
        }

      }
    } else if (type == 'marker') {
      if (percentage >= 0 && percentage <= 100) {
        setProgressWidthMaker(Math.round(percentage));

        if (annotation?.type == 'marker') {
          annotation.size(Math.round(percentage))
        }

      }
    }
  };
  const handleSelectFontStyle = (val) => {
    debugger
    var annotation = fullChart?.annotations()?.getSelectedAnnotation();
    let value = val.value
    if (val.type == '0') {
      if (fontWeight == null) {
        setFontStyling({ ...fontStyling, fontWeight: val })
        if (annotation?.type == 'label') {
          annotation.fontWeight(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, fontWeight: null })
        if (annotation?.type == 'label') {
          annotation.fontWeight(null)
        }
      }

    } else if (val.type == '1') {
      if (fontDecoration == null) {
        setFontStyling({ ...fontStyling, fontDecoration: val })
        if (annotation?.type == 'label') {
          annotation.fontDecoration(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, fontDecoration: null })
        if (annotation?.type == 'label') {
          annotation.fontDecoration(null)
        }
      }

    } else if (val.type == '2') {
      if (hAlign == null) {
        setFontStyling({ ...fontStyling, hAlign: val })
        if (annotation?.type == 'label') {
          annotation.hAlign(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, hAlign: null })
        if (annotation?.type == 'label') {
          annotation.hAlign(null)
        }
      }

    } else if (val.type == '3') {
      if (anchor == null) {
        setFontStyling({ ...fontStyling, anchor: val })
        if (annotation?.type == 'label') {
          annotation.anchor(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, anchor: null })
        if (annotation?.type == 'label') {
          annotation.anchor(null)
        }
      }

    } else if (val.type == '4') {
      if (vAlign == null) {
        setFontStyling({ ...fontStyling, vAlign: val })
        if (annotation?.type == 'label') {
          annotation.vAlign(val.value)
        }
      } else {
        setFontStyling({ ...fontStyling, vAlign: null })
        if (annotation?.type == 'label') {
          annotation.vAlign(null)
        }
      }

    }
  }
  const ViewXMLChart = (value) => {
    setDisableSeriesType(true)
    // fullChart.saveAsJson("pancakes_chart_json");
    // fullChart.saveAsXml("chart_xml1");
    // fullChart.annotations().applyDrawing()
    // console.log(value)
    stockPriceList('1', value.stockCode)
    setStockCode(value.stockCode)
    try {
      getXmlsChartData(value.chartingLevelId).then((res) => {
        debugger
        if (res?.status === 200) {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(res.data.chartFile, 'text/xml');
          const xmlAnnotationDoc = parser.parseFromString(res.data.chartAnnotationFile, 'text/xml');
          // console.log(res)
          // fullChart.dispose();

          var chartFromXml = anychart.fromXml(xmlDoc);
          chartFromXml.annotations().fromXml(xmlAnnotationDoc);
          chartFromXml.container("container");
          chartFromXml.draw();
          setFullChart(chartFromXml);
          document.getElementById('stockListModal').click()
        }
      })
    } catch (error) {

    }
    // fullChart.dispose();

    // var chartFromXml = anychart.fromXml(xml);
    // chartFromXml.annotations().fromXml(xmlAnnotation);
    // chartFromXml.container("container");
    // setFullChart(chartFromXml);
    // chartFromXml.draw();
  }
  // setSelectedTab
  //  style={{ 'height': height, 'overflow': 'auto' }}
  const handleChangeChartType = (e) => {
    let value = e.target.value
    sriesTypeData.seriesType(value)

    setSelectedSeries(chartTypeOption.filter((val) => val.key == value)[0])
  }
  return (
    <>
      <div className='container-fluid g-0'>
        <div className=" d-flex">
          <div className='sidenav'>
            <textarea id='annotation-label' value={labelText} onChange={handleAddAnnomationText} ></textarea>

            <ul>
              <li>
                <button onClick={handleSelectCursor} type="button" data-action-type="unSelectedAnnotation" class="btn btn_anyChart btn-default"
                  aria-label="Center Align" data-toggle="tooltip" title="Cursor">
                  <i class="ac ac-mouse-pointer" aria-hidden="true"></i>
                </button>
              </li>
              <li>
                <button onMouseEnter={() => handleShowColorPicker('series')}
                  onMouseLeave={() => handleHideColorPicker('series')} type="button" class="position-relative btn btn_anyChart btn-default color-picker" data-color="fill" data-toggle="tooltip"
                  title="Сhoose drawing fill color">

                  <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': series }}></span>
                  &nbsp;<b
                    class="caret"></b>
                  {type == 'series' && showHide && (
                    <div className="color-picker-popup">
                      <SketchPicker color={series} onChangeComplete={(val) => handleColorChange(val, 'series')} />

                    </div>
                  )}
                </button>
              </li>
              <li>
                <button onMouseEnter={() => handleShowColorPicker('fontCol')}
                  onMouseLeave={() => handleHideColorPicker('fontCol')} type="button" class="position-relative btn_anyChart btn btn-default color-picker" data-style="btn-lg" data-color="fontColor" data-toggle="tooltip"
                  title="Сhoose drawing Font color"><span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': fontCol }}></span>&nbsp;<b
                    class="caret"></b>
                  {type == 'fontCol' && showHide && (
                    <div className="color-picker-popup">
                      <SketchPicker color={fontCol} onChangeComplete={(val) => handleColorChange(val, 'fontCol')} />

                    </div>
                  )}
                </button>
              </li>
              <li>
                <button data-annotation-type="label" onClick={addText} type="button" class="btn btn_anyChart btn-default" aria-label="Center Align"
                  data-toggle="tooltip" title="Label">
                  <i class="ac ac-font"></i>
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowFontDrager(true)} onMouseLeave={() => setShowFontDrager(false)} class="btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                  data-toggle="popover"><AiOutlineFontSize />
                  {
                    showFontDrager &&
                    <div className='progressBar_font'>
                      <p>{progressWidth} px</p>
                      <div className="progress-container" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                        <div className="progress-bar" style={{ width: progressWidth + '%' }}></div>
                      </div>
                    </div>
                  }
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowFontStyle(true)} onMouseLeave={() => setShowFontStyle(false)} class="btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                  data-toggle="popover"><SiAdobefonts />
                  {
                    showFontStyle &&
                    <div className='multipalSelect_option'>

                      {
                        fontStyle.map((val) => {
                          return (
                            <div onClick={() => handleSelectFontStyle(val)}>
                              {
                                val?.group && <h6>{val?.group}</h6>
                              }
                              <p className={val.type == 0 ? fontWeight?.value == val?.value && 'selected_strock' : val.type == 1 ? fontDecoration?.value == val?.value && 'selected_strock' : val.type == 2 ? hAlign?.value == val?.value && 'selected_strock' : val.type == 3 ? anchor?.value == val?.value && 'selected_strock' : val.type == 4 ? vAlign?.value == val?.value && 'selected_strock' : ''}>{val.icon} {val.name}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </button>
              </li>
              <li>
                <button onMouseEnter={() => handleShowColorPicker('stroke')}
                  onMouseLeave={() => handleHideColorPicker('stroke')} type="button" class="position-relative btn btn_anyChart btn-default color-picker" data-style="btn-lg" data-color="stroke" data-toggle="tooltip"
                  title="Сhoose drawing stroke color"><span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': stroke }}></span>&nbsp;<b
                    class="caret"></b>
                  {type == 'stroke' && showHide && (
                    <div className="color-picker-popup">
                      <SketchPicker color={stroke} onChangeComplete={(val) => handleColorChange(val, 'stroke')} />

                    </div>
                  )}
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowBorderSetting(true)} onMouseLeave={() => setShowBorderSetting(false)} class="btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                  data-toggle="popover"><BsBorderOuter />
                  {
                    showBorderSetting &&
                    <div className='multipalSelect_option'>
                      {
                        options.map((val, ind) => {
                          return (
                            <div className='d-inline' onClick={() => handleSelectStroke(val)}>
                              {
                                val?.stroke && <h6 className='mt-2'>{val?.stroke}</h6>
                              }
                              {
                                val.id < 6 && <button className={sWidth.id == val.id ? 'selected_strock button_border_size' : 'button_border_size'}>{val.name}</button>
                              }
                              {
                                val.id > 5 && <button className={sDash.id == val.id ? 'selected_strock button_border_size' : 'button_border_size'}>{val.name}</button>
                              }

                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowMaker(true)} onMouseLeave={() => setShowMaker(false)} class="btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                  data-toggle="popover"><SiMakerbot />
                  {
                    showMaker &&
                    <div className='multipalSelect_option makerSelect_option'>
                      {/* makerOption */}
                      <h6>Select Maker</h6>
                      {
                        makerOption.map((val, ind) => {
                          return (
                            <div className={makerType == 'val.value' ? 'd-inline col-2 selected_strock' : 'd-inline col-2'} onClick={() => handleChangechoosemarker(val)}>

                              <button ><img src={val.key} class="img-fluid" /></button>

                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </button>
              </li>
              <li>
                <button type="button" onMouseEnter={() => setShowFontDragerMaker(true)} onMouseLeave={() => setShowFontDragerMaker(false)} class="btn btn_anyChart position-relative btn-default volume-btn" data-el-size="marker" data-volume="20"
                  data-toggle="popover"><BiPencil />
                  {
                    showFontDragerMaker &&
                    <div className='progressBar_font'>
                      <p>{progressWidthMaker} px</p>
                      <div className="progress-container" onMouseDown={handleMouseDownMaker} onMouseUp={handleMouseUpMaker} onMouseMove={handleMouseMoveMaker}>
                        <div className="progress-bar" style={{ width: progressWidthMaker + '%' }}></div>
                      </div>
                    </div>
                  }
                </button>
              </li>

            </ul>
          </div>
          <div className='flex-lg-fill'>
            <div>
              <div class="container-fluid g-0 anychartview top-nav">
                <div class="row">
                  <div class="col-sm-4">
                    <button className='btn ' onClick={() => setSelectedTab('chart')} data-bs-toggle="modal" data-bs-target="#viewXMLs" >Saved Chart</button>
                    <button className='btn ms-2' onClick={() => setSelectedTab('stock')} data-bs-toggle="modal" data-bs-target="#viewXMLs" >Select Stock</button>
                    {/* <button className='btn ms-auto' onClick={()=>setSelectedTab('watch')} data-bs-toggle="modal" data-bs-target="#viewXMLs" >View XML</button> */}

                  </div>
                  <div class="col-sm-8">
                    <div className='d-flex gap-2 w-100'>
                      {/* <input type='text' placeholder='Search' className='search-input' /> */}
                      <select class="selectpicker choose-drawing-tools anyChartSelect" disabled={disableSeriesType} value={selectedSeries.key} onChange={handleChangeChartType} title="Drawing tools"
                      >
                        {/* <optgroup label="Lines, Trend lines and Rays:"> */}
                        {
                          chartTypeOption.map((val, ind) => {
                            return (
                              <option data-icon="ac-horizontal-line" data-annotation-type="horizontal-line" value={val.key}>
                                {val.name}
                              </option>
                            )
                          })
                        }
                        {/* </optgroup> */}
                      </select>
                      <div className='d-flex column_anyChrart gap-2 ms-2 w-100'>
                        <select class="selectpicker choose-drawing-tools anyChartSelect" id='typeSelect' onChange={selectDrawType} title="Drawing tools" data-style="btn-lg" data-width="133"
                          data-max-options="1">
                          <optgroup label="Lines, Trend lines and Rays:">
                            <option value="default" selected>Annotation Type</option>
                            <option data-icon="ac-line" data-annotation-type="line" value={'line'}> Line Segment</option>
                            <option data-icon="ac-horizontal-line" data-annotation-type="horizontal-line" value='horizontal-line'> Horizontal
                              Line
                            </option>
                            <option data-icon="ac-vertical-line" data-annotation-type="vertical-line" value='vertical-line' >Vertical Line
                            </option>
                            <option data-icon="ac-infinite-line" data-annotation-type="infinite-line" value='infinite-line'>Infinite Line
                            </option>
                            <option data-icon="ac-ray" data-annotation-type="ray" value={'ray'} >Ray</option>
                          </optgroup>
                          <optgroup label="Geometric shapes:">
                            <option data-icon="ac-triangle" data-annotation-type="triangle" value={'triangle'}>Triangle</option>
                            <option data-icon="ac-rectangle" data-annotation-type="rectangle" value={'rectangle'}>Rectangle</option>
                            <option data-icon="ac-ellipse" data-annotation-type="ellipse" value={'ellipse'} >Ellipse</option>
                          </optgroup>
                          <optgroup label="Other tools:">
                            <option data-icon="ac-horizontal-range" data-annotation-type="horizontal-range" value={'horizontal-range'} >
                              Horizontal Range
                            </option>
                            <option data-icon="ac-vertical-range" data-annotation-type="vertical-range" value={'vertical-range'} >
                              Vertical Range
                            </option>
                          </optgroup>
                          <optgroup label="Other tools:">
                            <option data-icon="ac-trend-channel" data-annotation-type="trend-channel" value={'trend-channel'} >Trend Channel
                            </option>
                            <option data-icon="ac-finite-trend-channel" data-annotation-type="finite-trend-channel" value={'finite-trend-channel'} >Trend Channel
                            </option>
                            <option data-icon="ac-andrews-pitchfork" data-annotation-type="andrews-pitchfork" value={'andrews-pitchfork'} >
                              Andrew's
                              Pitchfork
                            </option>
                          </optgroup>
                          <optgroup label="Fibonacci tools:">
                            <option data-icon="ac-fibonacci-fan" data-annotation-type="fibonacci-fan" value={'fibonacci-fan'} >Fibonacci Fan
                            </option>
                            <option data-icon="ac-fibonacci-arc" data-annotation-type="fibonacci-arc" value={'fibonacci-arc'} >Fibonacci Arc
                            </option>
                            <option data-icon="ac-fibonacci-retracement" data-annotation-type="fibonacci-retracement" value={'fibonacci-retracement'}>Fibonacci
                              Retracement
                            </option>
                            <option data-icon="ac-fibonacci-timezones" data-annotation-type="fibonacci-timezones" value={'fibonacci-timezones'}>
                              Fibonacci Time
                              Zones
                            </option>
                          </optgroup>
                        </select>
                        <div className='topnav_anychart'>

                          <button type="button" class="btn btn_anyChart btn-default" onClick={handleAutoSizeAnnotation} id="annotation-label-autosize" aria-label="Auto-resize"
                            data-toggle="tooltip" title="Auto-resize">
                            <i class="ac ac-enlarge" aria-hidden="true"></i>
                          </button>

                          <button data-action-type="removeSelectedAnnotation" onClick={removeSelectedAnnotation} type="button" class="btn btn_anyChart btn-default"
                            aria-label="Center Align" data-toggle="tooltip" title="Delete selected drawing">
                            <i class="ac ac-remove-thin" aria-hidden="true"></i>

                          </button>

                          <button data-action-type="removeAllAnnotations" type="button" onClick={removeAll} class="btn btn_anyChart btn-default"
                            aria-label="Center Align" data-toggle="tooltip" title="Delete all drawings"> <RiDeleteBin6Line />
                          </button>

                          <button data-action-type="removeAllAnnotations" type="button" class="btn btn_anyChart btn-default" data-bs-toggle="modal" data-bs-target="#saveChart"
                            aria-label="Center Align" data-toggle="tooltip" title="Save XML"><BiSave />
                          </button>

                        </div>
                        {/* <button onClick={handleSelectCursor} type="button" data-action-type="unSelectedAnnotation" class="btn btn_anyChart btn-default"
                        aria-label="Center Align" data-toggle="tooltip" title="Cursor">
                        <i class="ac ac-mouse-pointer" aria-hidden="true"></i>
                      </button>
                      <button onMouseEnter={() => handleShowColorPicker('series')}
                        onMouseLeave={() => handleHideColorPicker('series')} type="button" class="position-relative btn btn_anyChart btn-default color-picker" data-color="fill" data-toggle="tooltip"
                        title="Сhoose drawing fill color">

                        <span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': series }}></span>
                        &nbsp;<b
                          class="caret"></b>
                        {type == 'series' && showHide && (
                          <div className="color-picker-popup">
                            <SketchPicker color={series} onChangeComplete={(val) => handleColorChange(val, 'series')} />

                          </div>
                        )}
                      </button>

                      <button onMouseEnter={() => handleShowColorPicker('stroke')}
                        onMouseLeave={() => handleHideColorPicker('stroke')} type="button" class="position-relative btn btn_anyChart btn-default color-picker" data-style="btn-lg" data-color="stroke" data-toggle="tooltip"
                        title="Сhoose drawing stroke color"><span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': stroke }}></span>&nbsp;<b
                          class="caret"></b>
                        {type == 'stroke' && showHide && (
                          <div className="color-picker-popup">
                            <SketchPicker color={stroke} onChangeComplete={(val) => handleColorChange(val, 'stroke')} />

                          </div>
                        )}
                      </button> */}
                        {/* <div className='multiSelect_width'>
                        <input value={sWidth.name + ' , ' + sDash.name} onClick={() => setShowMulti(!showMulti)} />
                        {
                          showMulti &&
                          <div className='multipalSelect_option'>
                            {
                              options.map((val, ind) => {
                                return (
                                  <div onClick={() => handleSelectStroke(val)}>
                                    {
                                      val?.stroke && <h6>{val?.stroke}</h6>
                                    }
                                    <p className={val.id < 6 ? sWidth.id == val.id && 'selected_strock' : sDash.id == val.id && 'selected_strock'}>{val.name}</p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                      <select class="selectpicker choose-drawing-tools anyChartSelect" id='typeSelect' onChange={selectDrawType} title="Drawing tools" data-style="btn-lg" data-width="133"
                        data-max-options="1">
                        <optgroup label="Lines, Trend lines and Rays:">
                          <option value="default" selected>Annotation Type</option>
                          <option data-icon="ac-line" data-annotation-type="line" value={'line'}> Line Segment</option>
                          <option data-icon="ac-horizontal-line" data-annotation-type="horizontal-line" value='horizontal-line'> Horizontal
                            Line
                          </option>
                          <option data-icon="ac-vertical-line" data-annotation-type="vertical-line" value='vertical-line' >Vertical Line
                          </option>
                          <option data-icon="ac-infinite-line" data-annotation-type="infinite-line" value='infinite-line'>Infinite Line
                          </option>
                          <option data-icon="ac-ray" data-annotation-type="ray" value={'ray'} >Ray</option>
                        </optgroup>
                        <optgroup label="Geometric shapes:">
                          <option data-icon="ac-triangle" data-annotation-type="triangle" value={'triangle'}>Triangle</option>
                          <option data-icon="ac-rectangle" data-annotation-type="rectangle" value={'rectangle'}>Rectangle</option>
                          <option data-icon="ac-ellipse" data-annotation-type="ellipse" value={'ellipse'} >Ellipse</option>
                        </optgroup>
                        <optgroup label="Other tools:">
                          <option data-icon="ac-horizontal-range" data-annotation-type="horizontal-range" value={'horizontal-range'} >
                            Horizontal Range
                          </option>
                          <option data-icon="ac-vertical-range" data-annotation-type="vertical-range" value={'vertical-range'} >
                            Vertical Range
                          </option>
                        </optgroup>
                        <optgroup label="Other tools:">
                          <option data-icon="ac-trend-channel" data-annotation-type="trend-channel" value={'trend-channel'} >Trend Channel
                          </option>
                          <option data-icon="ac-finite-trend-channel" data-annotation-type="finite-trend-channel" value={'finite-trend-channel'} >Trend Channel
                          </option>
                          <option data-icon="ac-andrews-pitchfork" data-annotation-type="andrews-pitchfork" value={'andrews-pitchfork'} >
                            Andrew's
                            Pitchfork
                          </option>
                        </optgroup>
                        <optgroup label="Fibonacci tools:">
                          <option data-icon="ac-fibonacci-fan" data-annotation-type="fibonacci-fan" value={'fibonacci-fan'} >Fibonacci Fan
                          </option>
                          <option data-icon="ac-fibonacci-arc" data-annotation-type="fibonacci-arc" value={'fibonacci-arc'} >Fibonacci Arc
                          </option>
                          <option data-icon="ac-fibonacci-retracement" data-annotation-type="fibonacci-retracement" value={'fibonacci-retracement'}>Fibonacci
                            Retracement
                          </option>
                          <option data-icon="ac-fibonacci-timezones" data-annotation-type="fibonacci-timezones" value={'fibonacci-timezones'}>
                            Fibonacci Time
                            Zones
                          </option>
                        </optgroup>
                      </select>
                      <select class="selectpicker select choose-marker anyChartSelect" onChange={handleChangechoosemarker} id="select-marker-type" title="Marker"
                        data-style="btn-lg" data-width="90" data-max-options="1">
                        <option data-icon="ac-arrow-up-square" data-annotation-type="marker" value=""
                          data-marker-anchor="center-top">Select Marker
                        </option>
                        <option data-icon="ac-arrow-up-square" data-annotation-type="marker" value="arrowUp"
                          data-marker-anchor="center-top">Up Arrow
                        </option>
                        <option data-icon="ac-arrow-down-square" data-annotation-type="marker" value="arrow-down"
                          data-marker-anchor="center-bottom">Down Arrow
                        </option>
                        <option data-icon="ac-arrow-left-square" data-annotation-type="marker" value="arrow-left"
                          data-marker-anchor="left-center">Left Arrow
                        </option>
                        <option data-icon="ac-arrow-right-square" data-annotation-type="marker" value="arrow-right"
                          data-marker-anchor="right-center">Right Arrow
                        </option>
                        <option data-icon="ac-head-arrow" data-annotation-type="marker" value="arrowHead"
                          data-marker-anchor="right-center">Head Arrow
                        </option>
                        <option data-icon="ac-cross" data-annotation-type="marker" value="cross"
                          data-marker-anchor="center">Cross
                        </option>
                        <option data-icon="ac-diagonal-cros" data-annotation-type="marker" value="diagonal-cross"
                          data-marker-anchor="center">Diagonal cross
                        </option>
                        <option data-icon="ac-diamond" data-annotation-type="marker" value="diamond"
                          data-marker-anchor="center">Diamond
                        </option>
                        <option data-icon="ac-pentagon" data-annotation-type="marker" value="pentagon"
                          data-marker-anchor="center">Pentagon
                        </option>
                        <option data-icon="ac-square" data-annotation-type="marker" value="square"
                          data-marker-anchor="center">Square
                        </option>
                        <option data-icon="ac-star-1" data-annotation-type="marker" value="star10"
                          data-marker-anchor="center">Star 1
                        </option>
                        <option data-icon="ac-star-2" data-annotation-type="marker" value="star4"
                          data-marker-anchor="center">Star 2
                        </option>
                        <option data-icon="ac-star-3" data-annotation-type="marker" value="star5"
                          data-marker-anchor="center">Star 3
                        </option>
                        <option data-icon="ac-star-4" data-annotation-type="marker" value="star6"
                          data-marker-anchor="center">Star 4
                        </option>
                        <option data-icon="ac-star-5" data-annotation-type="marker" value="star7"
                          data-marker-anchor="center">Star 5
                        </option>
                        <option data-icon="ac-trapezium" data-annotation-type="marker" value="trapezium"
                          data-marker-anchor="center">Trapezium
                        </option>
                        <option data-icon="ac-triangle-up" data-annotation-type="marker" value="triangle-up"
                          data-marker-anchor="center-top">Triangle Up
                        </option>
                        <option data-icon="ac-triangle-down" data-annotation-type="marker" value="triangle-down"
                          data-marker-anchor="center-bottom">Triangle Down
                        </option>
                        <option data-icon="ac-triangle-left" data-annotation-type="marker" value="triangle-left"
                          data-marker-anchor="left-center">Triangle Left
                        </option>
                        <option data-icon="ac-triangle-right" data-annotation-type="marker" value="triangle-right"
                          data-marker-anchor="right-center">Triangle Right
                        </option>
                      </select> */}

                        {/* <button type="button" onMouseEnter={() => setShowFontDragerMaker(true)} onMouseLeave={() => setShowFontDragerMaker(false)} class="btn btn_anyChart position-relative btn-default volume-btn" data-el-size="marker" data-volume="20"
                        data-toggle="popover">Marker size
                        {
                          showFontDragerMaker &&
                          <div className='progressBar_font'>
                            <p>{progressWidthMaker} px</p>
                            <div className="progress-container" onMouseDown={handleMouseDownMaker} onMouseUp={handleMouseUpMaker} onMouseMove={handleMouseMoveMaker}>
                              <div className="progress-bar" style={{ width: progressWidthMaker + '%' }}></div>
                            </div>
                          </div>
                        }
                      </button> */}
                      </div>
                      <div className='d-flex column_anyChrart_secondchart gap-2' >
                        {/* <button data-annotation-type="label" onClick={addText} type="button" class="btn btn_anyChart btn-default" aria-label="Center Align"
                          data-toggle="tooltip" title="Label">
                          <i class="ac ac-font"></i>
                        </button>
                        <button type="button" class="btn btn_anyChart btn-default" onClick={handleAutoSizeAnnotation} id="annotation-label-autosize" aria-label="Auto-resize"
                          data-toggle="tooltip" title="Auto-resize">
                          <i class="ac ac-enlarge" aria-hidden="true"></i>
                        </button>
                        <button onMouseEnter={() => handleShowColorPicker('fontCol')}
                          onMouseLeave={() => handleHideColorPicker('fontCol')} type="button" class="position-relative btn_anyChart btn btn-default color-picker" data-style="btn-lg" data-color="fontColor" data-toggle="tooltip"
                          title="Сhoose drawing Font color"><span class="color-fill-icon dropdown-color-fill-icon" style={{ 'background-color': fontCol }}></span>&nbsp;<b
                            class="caret"></b>
                          {type == 'fontCol' && showHide && (
                            <div className="color-picker-popup">
                              <SketchPicker color={fontCol} onChangeComplete={(val) => handleColorChange(val, 'fontCol')} />

                            </div>
                          )}
                        </button>

                        <button type="button" onMouseEnter={() => setShowFontDrager(true)} onMouseLeave={() => setShowFontDrager(false)} class="btn btn_anyChart btn-default position-relative volume-btn" data-el-size="label" data-volume="15"
                          data-toggle="popover">Font size
                          {
                            showFontDrager &&
                            <div className='progressBar_font'>
                              <p>{progressWidth} px</p>
                              <div className="progress-container" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                                <div className="progress-bar" style={{ width: progressWidth + '%' }}></div>
                              </div>
                            </div>
                          }
                        </button> */}
                        {/* <span className='multiSelect_width' onClick={() => setShowFontStyle(!showFontStyle)} >
                          <input placeholder='Font Setting' />
                          
                          {
                            showFontStyle &&
                            <div className='multipalSelect_option'>

                              {
                                fontStyle.map((val) => {
                                  return (
                                    <div onClick={() => handleSelectFontStyle(val)}>
                                      {
                                        val?.group && <h6>{val?.group}</h6>
                                      }
                                      <p className={val.type == 0 ? fontWeight?.value == val?.value && 'selected_strock' : val.type == 1 ? fontDecoration?.value == val?.value && 'selected_strock' : val.type == 2 ? hAlign?.value == val?.value && 'selected_strock' : val.type == 3 ? anchor?.value == val?.value && 'selected_strock' : val.type == 4 ? vAlign?.value == val?.value && 'selected_strock' : ''}>{val.icon} {val.name}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }
                        </span> */}
                        {/* <button data-action-type="removeSelectedAnnotation" onClick={removeSelectedAnnotation} type="button" class="btn btn_anyChart btn-default"
                          aria-label="Center Align" data-toggle="tooltip" title="Delete selected drawing">
                          <i class="ac ac-remove-thin" aria-hidden="true"></i>
                        </button>
                        <button data-action-type="removeAllAnnotations" type="button" onClick={removeAll} class="btn btn_anyChart btn-default"
                          aria-label="Center Align" data-toggle="tooltip" title="Delete all drawings">Remove All
                        </button>
                        <button data-action-type="removeAllAnnotations" type="button" class="btn btn_anyChart btn-default" data-bs-toggle="modal" data-bs-target="#saveChart"
                          aria-label="Center Align" data-toggle="tooltip" title="Save XML">save
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="container" style={{ 'height': `${height - 110}px` }}>
                <h3 className='ms-3' >{stockCode}- {currentPrice}</h3>
              </div>
            </div>
            <SaveChart stockDetailValue={stockDetailValue} stockCode={stockCode} fullChart={fullChart} setRefressChartList={setRefressChartList} />
            <XmlChartList stockPriceList={stockPriceList} setReRenderChart={setReRenderChart} refressChartList={refressChartList} setRefressChartList={setRefressChartList} selectedTab={selectedTab} setStockCode={setStockCode} stockCode={stockCode} ViewXMLChart={ViewXMLChart} />
            {/* <div id="container"></div> */}
          </div>
        </div>
      </div>

    </>
  )
}

export default AnyChartView