import React, { useState, useEffect } from "react";
import Loader from "../../components/commonComponent/Loader";
import moment from "moment";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NewsBody = ({ newsData, isLoading }) => {
  const handleNewsClick = (newsUrl) => {

    window.open(newsUrl, "_blank");
  };
  return (
    <div className="News_body row">
      {isLoading ? (
        <div className="loader"><Loader /></div>
      ) : newsData.length > 0 ? (
        newsData.map((e) => {
          const dateString = e.time_published;
          const n = moment(dateString, "YYYY-MM-DDTHH:mm:ss"); // Adjust the date format
          const timeAgo = n.fromNow();
          return (
          
          
         
        

            <div className="col-12 col-xm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6" >
                <div class="newsbody" onClick={() => handleNewsClick(e.url)} style={styles.newsbody}>
              <div class="image_news" style={{
               
              }}>
                {e.banner_image ? (
                  <img src={e.banner_image} alt="My Image" style={styles.img} />
                ) : (
                  <img
                    src="https://fl-1.cdn.flockler.com/embed/no-image.svg"
                    alt="Default Image"
                    style={styles.img}
                  />
                )}
              </div>

                <div style={{ flex: 2 }} className="ps-5">
                  <h4 className="fontSize_20 font_sec_h1">{e.title} </h4>
                  <p className="fontSize_14 font_sec_p" style={{
                    lineHeight: 2,
                  }}>{e.summary}</p>
                  <span className="font_sec_span">{timeAgo} .</span>
                </div>
              </div>
            </div>

          )
        })
      ) : (
        <div className="no-news-message" style={{ textAlign: "center", marginTop: "13%" }}>No news available for the selected filters </div>
      )}
    </div>
  );
};
export default NewsBody

const styles = {
  
  newsbody: {
    display: "flex", marginBottom: 16,
    border: "1px solid #ccc",
    borderRadius: 10,
    padding: 16,
  }
   
};