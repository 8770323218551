import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const getStockDailyFullDetails = async (tradeCode) => {
    let withoutSpaces = tradeCode.replace(/\s+/g, '')
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockFullDetails/${withoutSpaces}/1`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
        
    }
}