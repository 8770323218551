import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getStockNews } from '../../../../services/TradeService'
import moment from 'moment'

const StockNews = ({ typeData, tradeCode }) => {
	const [stockNewsList, setStockNewsList] = useState([])
	const [stockNewsListStatus, setStockNewsListStatus] = useState(false)
	const [newsDate, setNewDate] = useState('1D')
	useEffect(() => {
		if (typeData === 'News') {
			try {
				getStockNews(newsDate, tradeCode).then((res) => {

					setStockNewsList(res?.data?.feed || [])
					setStockNewsListStatus(true)
				})
			} catch (error) {

			}
		}
	}, [typeData, newsDate])
	return (
		<>
			<div class="tab-pane fade news-ul" id="pills-news" role="tabpanel"
				aria-labelledby="pills-news-tab">
				{
					(stockNewsList.length > 0 || newsDate != '1D') &&
					<div className='row'>
						<div class="row ">
							<div class="col-sm-12 ">
								<ul class="slec-dta-ch text-end">
									<li className={newsDate === '1D' && 'day-active white'} onClick={() => setNewDate('1D')}><Link className={newsDate === '1D' && ' white'}>1D</Link></li>
									<li className={newsDate === '1W' && 'day-active white'} onClick={() => setNewDate('1W')}><Link className={newsDate === '1W' && ' white'}>1W</Link></li>
									<li className={newsDate === '1M' && 'day-active white'} onClick={() => setNewDate('1M')}><Link className={newsDate === '1M' && ' white'}>1M</Link></li>
									{/* <li ><Link className={newsDate==='1Y'&&'day-active white'} onClick={()=>setNewDate('1Y')}>1Y</Link></li>
									<li ><Link className={newsDate==='3Y'&&'day-active white'} onClick={()=>setNewDate('3Y')}>3Y</Link></li>
									<li ><Link className={newsDate==='5Y'&&'day-active white'} onClick={()=>setNewDate('5Y')}>5Y</Link></li> */}
									{/* <li><Link onclick={handleSelectDate}>max</Link></li>
									<li><Link onclick={handleSelectDate}>Sip</Link></li> */}
								</ul>
							</div>

						</div>
					</div>
				}
				{
					stockNewsListStatus == false ?
						<div class="container-fluid white_text">
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="preloader1">
											{/* <div class="loader loader-inner-1"> */}
											<div class="loader loader-inner-2 border_white">
												<div class="loader loader-inner-3 border_white">
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						</div> : stockNewsList.length > 0 ?
							<div class="row ">
								<div class="col-sm-12 mt-5">

									<div class="row white_text">
										{
											stockNewsList?.map((val, ind) => {
												const dateString = val.time_published;
												const n = moment(dateString, "YYYY-MM-DDTHH:mm:ss"); // Adjust the date format
												const timeAgo = n.fromNow();
												return (
													<Link to={val.url} target='_blank'>
														<div class="col-sm-12 summary-news-div">


															<div class="new-bx">
																<div class="d-flex position-relative">
																	<img src={val.banner_image} class="flex-shrink-0 h-127 img-fluid me-3 col-lg-2"
																		alt="..." />
																	<div className='white_text' >
																		<h5 class="mt-0 white_text">{val.title}</h5>
																		<span className='white_text'>{timeAgo} .</span>
																		<spn className='sroce-nm white_text'>{val.source}</spn>
																		{/* <p className='summary-news'>{val.summary}</p> */}
																	</div>
																</div>
															</div>
														</div>
													</Link>
												)
											})
										}


									</div>
								</div>




							</div> : <div className='w-100 text-center white_text'>
								<h4 className='no-data white_text'>Data Not Available</h4>
							</div>
				}


				{/* <ul class="slec-dta-ch new-ul">
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>

				</ul> */}
			</div>
		</>
	)
}

export default StockNews