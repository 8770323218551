import React, { useState } from 'react'
import { addProvider, updateProviders } from '../../../services/ProviderServices'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";

const AddUpdateModel = ({ addUpdateSelect, serGetProviderRun, setInputData, inputData }) => {


    const { providerName, parentCompany, yearAsCFDProvider, platform } = inputData

    const handleProviderInput = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
    let cancleAddProvider = document.getElementById('closeProvider')
    const handleAddEditPortfolio = (value) => {
        if (addUpdateSelect === 'Add') {
            try {
                addProvider(value).then((res) => {
                    if (res?.status === 200) {

                        cancleAddProvider.click()
                        setInputData('')
                        serGetProviderRun(true)
                    }
                })
            } catch (error) {
            }
        } else {
            try {
                updateProviders(value).then((res) => {
                    if (res?.status === 200) {
                        
                        // setEditTrue(false)
                        cancleAddProvider.click()
                        serGetProviderRun(true)
                    }
                })
            } catch (error) {
            }
        }
    }
    let validationSchema = Yup.object().shape({
        providerName: Yup.string().required('Provider name is required'),
        parentCompany: Yup.string().required('Parent company is required'),
        yearAsCFDProvider: Yup.string().required('Year as CFD provider is required'),
        platform: Yup.string().required('Platform is required'),
    });
    
    const handleRemoveInputData = () => {
        setInputData('')
    }

    const handleBlurInputProvider=()=>{
   
    }
    return (
        <>
            <div className="modal fade show backdrop" data-bs-backdrop="static" id="addProvider" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">{addUpdateSelect} Provider</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" id='closeProvider' onClick={handleRemoveInputData} aria-label="Close">×</button>
                        </div>

                        <div className="modal-body blackout-bx">
                            <Formik
                                initialValues={inputData}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(value) => {
                                    handleAddEditPortfolio(value)
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form className='row p-3'>
                                        {/* <div class="col-sm-12"> */}
                                        <div class="col-12 col-sm-6">
                                            <label>Provider Name <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" class="form-control input_font-Size" name='providerName' value={providerName} required="" id="autoSizingInput" placeholder="" onBlur={handleBlurInputProvider} onChange={handleProviderInput}/>
                                            {errors.providerName && touched.providerName ? (
                                                <div className='color-msg-err'>{errors.providerName}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 ms-auto">
                                            <label>Parent Company <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" name='parentCompany' value={parentCompany} class="form-control input_font-Size" required="" id="autoSizingInput" placeholder="" onBlur={handleBlurInputProvider} onChange={handleProviderInput}/>
                                            {errors.parentCompany && touched.parentCompany ? (
                                                <div className='color-msg-err'>{errors.parentCompany}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 mt-3">
                                            <label>Years As CFD Provider <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" class="form-control input_font-Size" name='yearAsCFDProvider' value={yearAsCFDProvider} required="" id="autoSizingInput" placeholder="" onBlur={handleBlurInputProvider} onChange={handleProviderInput}/>
                                            {errors.yearAsCFDProvider && touched.yearAsCFDProvider ? (
                                                <div className='color-msg-err'>{errors.yearAsCFDProvider}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 ms-auto mt-3">
                                            <label>Platforms <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" class="form-control input_font-Size" name='platform' value={platform} required="" id="autoSizingInput" placeholder="" onBlur={handleBlurInputProvider} onChange={handleProviderInput}/>
                                            {errors.platform && touched.platform ? (
                                                <div className='color-msg-err'>{errors.platform}</div>
                                            ) : null}
                                        </div>


                                        <div className='row'>
                                        <div className='col-md-6'>
                                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' > {addUpdateSelect === 'Add' ? <>Save</> : 'Update'}   </button>
                                        </div>
                                        <div className='col-md-6'>
                                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-provider' type='button' onClick={handleRemoveInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                        </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUpdateModel