import React from 'react'
import { useEffect } from 'react'
import { deleteXmlsChart, getXmlsChart } from '../../services/TradeService'
import { useState } from 'react'
import moment from 'moment'
import { BiSearchAlt } from 'react-icons/bi'
import { GetProviderstocks, getProviders } from '../../services/ProviderServices'

const StocksList = ({setScrolar,setStockFullDetails, stockPriceList, setReRenderChart, ViewXMLChart, setStockCode, stockCode, selectedTab, setRefressChartList, refressChartList }) => {
    const [chartList, setChartList] = useState([])
    const [stocksList, setStocksList] = useState([])
    const [pagination, setPagination] = useState({ PageNumber: 1, PageSize: 100, search: '', providerId: '' })
    const { PageNumber, PageSize, search, providerId } = pagination
    const [providerDataList, setPrividerDataList] = useState([])

    useEffect(() => {
        let data = {
            PageNumber: 1,
            PageSize: 1000
        }
        try {
            getProviders(data).then((res) => {
                setPrividerDataList(res.data.data)
                setPagination({ ...pagination, providerId: res.data.data[0]?.providerId })
            })
        } catch (error) {

        }
    }, [])
    const getSearchStockListByProvider = () => {
        if (providerId !== '' && providerId !== undefined) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                id: providerId,
                search: search
            }
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setStocksList(res.data.data)
                        if (stockCode?.length == 0) {
                            setStockCode(res.data.data[0].stockCode)
                            setStockFullDetails(res.data.data[0])
                        }
                    }
                })
            } catch (error) {

            }

            // try {
            //     GetProviderCummission(providerId).then((res) => {
            //         if (res?.status === 200) {
            //             setProviderCummission(res.data)
            //         }
            //     })
            // } catch (error) {

            // }

        }
    }
    useEffect(() => {
        getSearchStockListByProvider()
    }, [providerId, PageSize])
    useEffect(() => {
        if (search.length > 2) {
            getSearchStockListByProvider()
        } else if (search.length == 0) {
            getSearchStockListByProvider()
        }
    }, [search])
    useEffect(() => {
        if (localStorage.getItem('userDetails') && refressChartList) {
            let userDetail = JSON.parse(localStorage.getItem('userDetails'))
            try {
                getXmlsChart(userDetail.userId).then((res) => {
                    if (res?.status === 200) {
                        setChartList(res.data)
                        setRefressChartList(false)
                    }
                })
            } catch (error) {

            }
        }
    }, [refressChartList])
    const selectStockCode = (value) => {
        setScrolar(1)
        setStockFullDetails(value)
        stockPriceList('0', value.stockCode)
        setPagination({
            ...pagination, search: ''
        })
        setStockCode(value.stockCode)
        document.getElementById('stockListModal').click()
    }
    const handleChangeInputSearch = (e) => {
        setPagination({
            ...pagination, search: e.target.value
        })
    }
    const handleDeleteChart=(value)=>{
        // console.log(value)
        try {
            deleteXmlsChart(value.chartingLevelId).then((res) => {
                if (res?.status === 200) {
                    setRefressChartList(true)
                }
            })
        } catch (error) {

        }
    }
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="viewStockList" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg  modal-dialog-centered">
                <div className="modal-content common_active_div">
                    <div className="modal-header p-0 border-bottom-0">
                        <button type="button" className="btn-close btn-close-provider" id='stockListModal' data-bs-dismiss="modal" aria-label="Close">×</button>
                    </div>
                   
                    <div class="tab-content mt-3" id="pills-tabContent">
                        <div class={selectedTab == 'stock' ? "tab-pane fade show active" : "tab-pane fade show"} id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div className='d-flex'>
                                <select className='select_stock_provider' onChange={(e) => setPagination({ ...pagination, providerId: e.target.value })} >
                                    {
                                        providerDataList.map((val, ind) => {
                                            return (
                                                <option value={val.providerId} >{val.providerName}</option>
                                            )
                                        })
                                    }
                                </select>
                                <button class="nav-link search-input select_stock_input_field_search  ms-3">
                                    <input name='search' value={search} onChange={handleChangeInputSearch} className='search-input-main' placeholder='search by code...' />
                                    <span ><BiSearchAlt /></span>
                                </button>
                            </div>
                            <div class="Fundamentals-bx mt-3 stockListScrollar">
                                {/* <!-- <h5 class="mb-3">Tata Power Co Ltd Key Indicators</h5> --> */}

                                <div class="table-responsive">
                                    <div class="table-input1 text-center provider-datafd position-relative">
                                        <table class="table text-center table-input-data1 mb-4">

                                            <tr class="border-bottom-0">
                                                <th scope="row" class="ad-tab-b col-2 border-end">stockCode</th>
                                                <th scope="row" class="ad-tab-b col-2 border-end">Country</th>
                                                {/* <th scope="row" class="ad-tab-b col-2 border-end">View</th> */}
                                            </tr>

                                            <tbody>
                                                {
                                                    stocksList.map((val, ind) => {
                                                        return (

                                                            <tr key={ind} className='stockList_charting' onClick={() => selectStockCode(val)}>
                                                                <td class="text-center">{val.stockCode}</td>
                                                                <td class="text-center">{val.country}</td>
                                                                {/* <td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100"  title='view'> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td> */}
                                                             </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StocksList