import React from 'react'
import { useEffect } from 'react'
import { deleteXmlsChart, getXmlsChart } from '../../services/TradeService'
import { useState } from 'react'
import moment from 'moment'
import { BiSearchAlt } from 'react-icons/bi'
import { GetProviderstocks, getProviders } from '../../services/ProviderServices'

const XmlChartList = ({setScrolar,setStockFullDetails, stockPriceList, setReRenderChart, ViewXMLChart, setStockCode, stockCode, selectedTab, setRefressChartList, refressChartList }) => {
    const [chartList, setChartList] = useState([])
    const [stocksList, setStocksList] = useState([])
    const [pagination, setPagination] = useState({ PageNumber: 1, PageSize: 100, search: '', providerId: '' })
    const { PageNumber, PageSize, search, providerId } = pagination
    const [providerDataList, setPrividerDataList] = useState([])

    useEffect(() => {
        let data = {
            PageNumber: 1,
            PageSize: 1000
        }
        try {
            getProviders(data).then((res) => {
                setPrividerDataList(res.data.data)
                setPagination({ ...pagination, providerId: res.data.data[0]?.providerId })
            })
        } catch (error) {

        }
    }, [])
    const getSearchStockListByProvider = () => {
        if (providerId !== '' && providerId !== undefined) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                id: providerId,
                search: search
            }
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setStocksList(res.data.data)
                        if (stockCode?.length == 0) {
                            setStockCode(res.data.data[0].stockCode)
                            setStockFullDetails(res.data.data[0])
                        }
                    }
                })
            } catch (error) {

            }

            // try {
            //     GetProviderCummission(providerId).then((res) => {
            //         if (res?.status === 200) {
            //             setProviderCummission(res.data)
            //         }
            //     })
            // } catch (error) {

            // }

        }
    }
    useEffect(() => {
        getSearchStockListByProvider()
    }, [providerId, PageSize])
    useEffect(() => {
        if (search.length > 2) {
            getSearchStockListByProvider()
        } else if (search.length == 0) {
            getSearchStockListByProvider()
        }
    }, [search])
    useEffect(() => {
        if (localStorage.getItem('userDetails') && refressChartList) {
            let userDetail = JSON.parse(localStorage.getItem('userDetails'))
            try {
                getXmlsChart(userDetail.userId).then((res) => {
                    if (res?.status === 200) {
                        setChartList(res.data)
                        setRefressChartList(false)
                    }
                })
            } catch (error) {

            }
        }
    }, [refressChartList])
    const selectStockCode = (value) => {
        setScrolar(1)
        setStockFullDetails(value)
        stockPriceList('0', value.stockCode)
        setPagination({
            ...pagination, search: ''
        })
        setStockCode(value.stockCode)
        document.getElementById('xmlChartListModal').click()
    }
    const handleChangeInputSearch = (e) => {
        setPagination({
            ...pagination, search: e.target.value
        })
    }
    const handleDeleteChart=(value)=>{
        // console.log(value)
        try {
            deleteXmlsChart(value.chartingLevelId).then((res) => {
                if (res?.status === 200) {
                    setRefressChartList(true)
                }
            })
        } catch (error) {

        }
    }
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="viewXMLs" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg  modal-dialog-centered">
                <div className="modal-content common_active_div">
                    <div className="modal-header p-0 border-bottom-0">
                        <button type="button" className="btn-close btn-close-provider" id='xmlChartListModal' data-bs-dismiss="modal" aria-label="Close">×</button>
                    </div>
                    
                    <div class="tab-content mt-3" id="pills-tabContent">
                       
                        <div class={"tab-pane fade show active"} id="pills-strategy" role="tabpanel"
                            aria-labelledby="pills-profile-tab">

                        </div>
                        <div class={selectedTab == 'chart' ? "tab-pane fade show active" : "tab-pane fade show"} id="pills-Trade" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div className="modal-body blackout-bx">

                                <div class="table-responsive">
                                    <table class="table table-input1 table-bordered mt-3 text-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='color-grey'>Date</th>
                                                <th scope="col" className='color-grey'>Chart Name</th>
                                                <th scope="col" className='color-grey'>Description</th>
                                                <th scope="col" className='color-grey'>View</th>
                                                <th scope="col" className='color-grey'>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                chartList.map((val, ind) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <th scope="row">{moment(val.createdDate).format('DD MMM YYYY')} </th>
                                                            <td>{val.chartName}</td>
                                                            {/* <td>{val.provider.providerName}</td> */}
                                                            <td>{val.description}</td>
                                                            {/* <td><button class="btn padding-btn-dtd open-btn w-100" title='Open' data-bs-toggle="modal" data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td> */}
                                                            <td><button class="btn padding-btn-dtd delete-btn w-100" onClick={() => ViewXMLChart(val)} title='View'> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td>
                                                            <td><button class="btn padding-btn-dtd delete-admin" onClick={() => handleDeleteChart(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                           
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default XmlChartList