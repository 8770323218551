import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Loader from '../components/commonComponent/Loader';
import moment from 'moment';
import '../assets/css/style.css'
import '../assets/css/myStyle.css'
import '../assets/css/animetion.css'
import '../assets/css/loading.css'
// import CustomPineScript from '../components/commonComponent/CustomPineScript';

const CandleChartView = () => {
    const [chartLoader, setChartLoader] = useState(true)
    const chartRef = useRef()
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    const stockCode = params.get('tradeCode');
    // let stockCode = location.state?.tradeCode
    let tvScriptLoadingPromise;
    let dateTime = new Date()
    let timeZoneMinut = dateTime.getTimezoneOffset()
    let timeZone = 'UTC' + (timeZoneMinut / 60)
    // let n=moment.tz.zone(timeZone).countries()[0];

    useEffect(
        () => {
            chartRef.current = createWidget;
           
            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;
                    document.head.appendChild(script);
                });
            }
            tvScriptLoadingPromise.then(() => chartRef.current && chartRef.current());
            
            function createWidget() {
                if (document.getElementById('tradingview_ac040') && 'TradingView' in window) {
                    setChartLoader(false)
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: stockCode,
                        theme: "light",
                        style: "1",
                        locale: "en",
                        toolbar_bg: "#f1f3f6",
                        // enable_publishing: true,
                        // withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        // enabled_features: ["move_logo_to _main_pane"],
                        // hide_legend: true,
                        details: true,
                        // custom_indicators_getter: function (PineJS) {
                        //     return Promise.resolve([
                        //       {
                        //         name: "Bar Colorer Demo",
                        //         metainfo: {
                        //           _metainfoVersion: 51,
                    
                        //           id: "BarColoring@tv-basicstudies-1",
                        //           name: "BarColoring",
                        //           description: "Bar Colorer Demo",
                        //           shortDescription: "BarColoring",
                    
                        //           isCustomIndicator: true,
                        //           isTVScript: false,
                        //           isTVScriptStub: false,
                    
                        //           format: {
                        //             type: "price",
                        //             precision: 4,
                        //           },
                    
                        //           defaults: {
                        //             palettes: {
                        //               palette_0: {
                        //                 // palette colors
                        //                 // change it to the default colors that you prefer,
                        //                 // but note that the user can change them in the Style tab
                        //                 // of indicator properties
                        //                 colors: [{ color: "#FFFF00" }, { color: "#0000FF" }],
                        //               },
                        //             },
                        //           },
                        //           inputs: [],
                        //           plots: [
                        //             {
                        //               id: "plot_0",
                    
                        //               // plot type should be set to 'bar_colorer'
                        //               type: "bar_colorer",
                    
                        //               // this is the name of the palette that is defined
                        //               // in 'palettes' and 'defaults.palettes' sections
                        //               palette: "palette_0",
                        //             },
                        //           ],
                        //           palettes: {
                        //             palette_0: {
                        //               colors: [{ name: "Color 0" }, { name: "Color 1" }],
                    
                        //               // the mapping between the values that
                        //               // are returned by the script and palette colors
                        //               valToIndex: {
                        //                 100: 0,
                        //                 200: 1,
                        //               },
                        //             },
                        //           },
                        //         },
                        //         constructor: function () {
                        //           this.main = function (context, input) {
                        //             this._context = context;
                        //             this._input = input;
                    
                        //             var valueForColor0 = 100;
                        //             var valueForColor1 = 200;
                    
                        //             // perform your calculations here and return one of the constants
                        //             // that is specified as a key in 'valToIndex' mapping
                        //             var result =
                        //               (Math.random() * 100) % 2 > 1 // we randomly select one of the color values
                        //                 ? valueForColor0
                        //                 : valueForColor1;
                    
                        //             return [result];
                        //           };
                        //         },
                        //       },
                        //     ]);
                        //   },
                        studies: [
                            // "ElliottWave@tv-basicstudies",
                            "XvS9Lamf-Price-Trading-Levels"
                        ],
                        // source: 'https://www.tradingview.com/script/XvS9Lamf-Price-Trading-Levels',
                        show_popup_button: true,
                        container_id: "tradingview_ac040"

                    });
                    // return () => {
                    //     widget.remove();
                    // };
                    
                }
            }
            // document.querySelector('.tv-header__link').remove();
        }, [stockCode]);
    const handleClose = () => {
        window.close()
    }
    setTimeout(() => {

    }, 3000)
    let height = window.innerHeight;
    document.body.style.height = `${height}px`;
    return (
        <>

            <div className='container-fluid m-0 p-0 position-relative'>
                {/* <Link to={'/'} > */}
                <button type="button" onClick={handleClose} className="btn-close btn-close-provider btn-close-candle" data-bs-dismiss="modal" aria-label="Close">×</button>
                {/* </Link> */}
                {/* <div className="row">
                    <div class="row">
                        <div class="cp-tail-bx cp-tail-bx-asd">
                            <div class="col-sm-12 mt-4 d-flex  md-2">
                                <h1>{stockData[0]?.symbol}</h1>
                                <h5 className=''>{stockData[0]?.close || 0} <span className={increased ? 'green' : 'red'}>{Number(profitLoss ? profitLoss : 0).toFixed(2)} ( {Number(profitPresent ? profitPresent : 0).toFixed(2)}% )</span><span class="id ms-1">{stockData[0]?.priceDate}</span> </h5>
                            </div>
                        </div>
                    </div>
                    <div class="bot-brd "></div>
                </div> */}


                {chartLoader &&
                    <div className='chartLoader'>
                        <Loader />
                    </div>
                }
                <div className='tradingview-widget-container'>
                    <div id='tradingview_ac040' style={{ 'height': `${height}px` }} className='tradingview_5327b-css w-100' />
                    <p ref={chartRef}></p>
                </div>

            </div>
            {/* <div className="tradingview-widget-container" ref={contariner}>
                <div className="tradingview-widget-container__widget"></div>
                <div className="tradingview-widget-copyright"></div>
            </div> */}

        </>
    )
}

export default CandleChartView