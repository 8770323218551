import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { getSearchStockDetails } from '../../../services/HomeService';
import { getExchangeRateAPI } from '../../../services/commonServices';
import { modifyOpenTrade } from '../../../services/TradeService';
import { toast } from 'react-toastify';
import { GetCurrentBalabce } from '../../../services/ProviderServices';
import {GetOpenTradeListByUserId}from '../../../services/TradeService'
const validationSchemaBuy = Yup.object().shape({
    quantity: Yup.string().required('Quantity is required'),
    amount: Yup.string().required('Amount is required'),
    // providerId: Yup.string().required('Provider is required'),
});
const BuySellUpdateTrade = ({ onSave ,allCountryList, buySellTradeData, setBuySellTradeData ,portFolipID ,userType}) => {
    const [buySellInput, setBuySellInput] = useState({ quantity: '', amount: '' })
    const [stockCurrentData, setStockCurrentData] = useState({ price: '0', profit: '0', profitPer: '0' })
    const [exchangeRate, setExchangeRate] = useState()
    const [brokerData, setBrokerData] = useState('')
    const { price, profit, profitPer } = stockCurrentData
    const [popUpShow, setPopUpShow] = useState(false)
    const twoDecimal = (value) => {
        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 2) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
            }
        }
        return roundedNumber
    }

    const toastQueue = [];

const showToast = (message) => {
    
    toastQueue.push(message);
    if (!popUpShow) {
        displayNextToast();
    }
};

const displayNextToast = () => {
    if (toastQueue.length > 0) {
        const message = toastQueue.shift();
        toast.error(message, {
            autoClose: 3000, // Set the duration as needed
            // onClose: () => {
            //     setPopUpShow(false);
            //     displayNextToast(); // Display the next toast message
            // },
        });
        setPopUpShow(true);
        setTimeout(()=>{
                   setPopUpShow(false);
                // displayNextToast();
        },3500)
    }
};
const getcurrentBalance =()=>{
    let cData = {
        portfolioId: portFolipID,
        userId: userType?.userId
    }
    // console.log(cData , "current data")
    if (cData.userId !== undefined && cData.portfolioId!== undefined) {
        try {
            GetCurrentBalabce(cData).then((res) => {
    // console.log(res.data , "current balance get api")
                if (res?.status === 200) {
                    setBrokerData(res.data)
                }
            })
        } catch (error) {
    
        }
    }
}

    const handleRemoveBuySelldata = () => {
        setBuySellTradeData({})
        setBuySellInput({ quantity: '', amount: '' })
    }
    const getExchangeRate = () => {
        
        if (buySellTradeData?.country?.length > 0) {
            let selectedStockCountry = allCountryList.filter((val) => val.key.toUpperCase() == buySellTradeData.country.toUpperCase())
            // console.log(selectedStockCountry)
            try {
                getExchangeRateAPI(selectedStockCountry[0]?.value, buySellTradeData?.portfolio?.currency).then((res) => {
                    if (res.status == 200) {
                        let exchange = res?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate'] || 1
                      
                     
                        setExchangeRate(exchange)
                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getExchangeRate()
    }, [buySellTradeData.country])

    const getCurrentPrice = () => {
    
        if (buySellTradeData?.stockCode) {
            try {
                getSearchStockDetails(buySellTradeData?.stockCode).then((res) => {
                   
                    if (res.status == 200) {
                      
                        let data1 = res.data
// console.log(data1.quotes ,"repllllllllllllllllllllll")
                        // let aus = buySellTradeData?.stockCode.includes(".AX")
                        let aus = buySellTradeData?.stockCode.includes("ASX:")
                     
                            if (aus) {
                                const data = res.data.quotes[0].price;
                                // console.log("ASX Data:", data);
                                // setStockCurrentData({ price: (close).toString(), profit: twoDecimal((close - open).toString()), profitPer: twoDecimal(((close - open) * 100 / open).toString()) })

                                const close = data1.quotes[0].price;
                                const open = data1.quotes[0].open;
// console.log(close , open ,"loseopenprice")
                                // setStockCurrentData({
                                //     price: (data).toString(),
                                //     profit: twoDecimal((data.close - data.open).toString()),
                                //     profitPer: twoDecimal(((data.close - data.open) * 100 / data.open).toString())
                                // });
                                setStockCurrentData({ price: (close).toString(), profit: twoDecimal((close - open).toString()), profitPer: twoDecimal(((close - open) * 100 / open).toString()) })

                            }
                            // setStockCurrentData({ price: (data).toString(), profit: twoDecimal((data.close - data.open).toString()), profitPer: twoDecimal(((data.close - data.open) * 100 / data.open).toString()) })
                         else {
                            const close = res.data["Global Quote"]["05. price"];
                            const open = res.data["Global Quote"]["02. open"];
                            // console.log(open ,"open ")
                            setStockCurrentData({ price: (close).toString(), profit: twoDecimal((close - open).toString()), profitPer: twoDecimal(((close - open) * 100 / open).toString()) })
                        }
                    }
                    // console.log(res)

                })
            } catch (error) {

            }
        }
}
    useEffect(() => {
        getCurrentPrice()
        getcurrentBalance()
    }, [buySellTradeData?.stockCode ,portFolipID, userType])

  
    const handleBlurInputPort = () => {

    }
   
    const handleChangeBuySelloInput = (e) => {
        // debugger
        if (e.target.name == 'quantity') {
            // debugger
            let vs = price * Math.round(e.target.value)
            if (Number(e.target.value) >= 0 && e.target.value != '' && e.target.value.length <= 10) {
                let n = e.target.value.split('.')
                let value = twoDecimal(e.target.value)
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setBuySellInput({ ...buySellInput, [e.target.name]: value, amount: twoDecimal((value * (price * exchangeRate)).toString()) })
                    }
                } 
                else if (brokerData?.currentBalance < vs) {
                    showToast('Does not have sufficient balance!',)
                } 
                else if (e.target.value.length <= 10) {
                    setBuySellInput({ ...buySellInput, [e.target.name]: value, amount: twoDecimal((value * (price )).toString()) })
                }
            } else if (e.target.value == '') {
                setBuySellInput({ ...buySellInput, [e.target.name]: '', amount: '' })
            }
           
        } else {
            if (Number(e.target.value) >= 0 && e.target.value != '' && e.target.value.length <= 10) {
                let n = e.target.value.split('.')
                let value = twoDecimal(e.target.value)
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        

                        setBuySellInput({ ...buySellInput, [e.target.name]: value, quantity:  twoDecimal((value / (price * exchangeRate)).toString()) })
                    }
                } else if (e.target.value.length <= 10) {
                    setBuySellInput({ ...buySellInput, [e.target.name]: value, quantity: twoDecimal((value / (price * exchangeRate)).toString()) })
                }
            } else if (e.target.value == '') {
                setBuySellInput({ ...buySellInput, [e.target.name]: '', quantity: '' })
            }
        }
    }
    // console.log(buySellTradeData ,"buySellTradeData")
    const handleSubmitBuySell = (type) => {
        // console.log(typeof(buySellTradeData?.valueInShares)  ,typeof(buySellTradeData?.profitLoss ) , "typsse")
        let value = {
            TradeId: buySellTradeData?.transactionNo,
            Qty: buySellInput?.quantity,
            StockPrice: buySellInput?.amount,
            Short: type
        }
        try {
            // console.log(value , "alue")
            modifyOpenTrade(value).then((res) => {
                if (res?.status === 200) {
                    onSave();
                    document.getElementById('closeBuySell').click()
                }
            })
        } catch (error) {
        }
        
    }

   
    return (
        <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="BuySellUpdateTrade" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header p-0 border-bottom-0">
                        <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">{buySellTradeData?.stockCode} <span>{twoDecimal(price)}</span> <span className={profit >= 0 ? 'green' : 'red'} >{profit}</span> (<span className={profit >= 0 ? 'green' : 'red'}>{profitPer}%</span>) </h5>
                        {/* <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">{buySellTradeData?.stockCode} <span>{twoDecimal(price)}</span>  </h5> */}
                        {/* {buySellTradeData?.stockCode?.includes("ASX:") ? (
  <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">
    {buySellTradeData?.stockCode} <span>{twoDecimal(price)}</span>
  </h5>
) : (
  <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">
    {buySellTradeData?.stockCode} <span>{twoDecimal(price)}</span> <span className={profit >= 0 ? 'green' : 'red'}>{profit}</span> (<span className={profit >= 0 ? 'green' : 'red'}>{profitPer}%</span>)
  </h5>
)} */}
                        <button type="button" className="btn-close btn-close-provider" id='closeBuySell' data-bs-dismiss="modal" aria-label="Close" onClick={handleRemoveBuySelldata} >×</button>
                    </div>
                    <div className="col-lg-12">
                        <Formik
                            initialValues={buySellInput}
                            enableReinitialize={true}
                            validationSchema={validationSchemaBuy}
                            onSubmit={(value) => {
                                // handleSubmitBuySell()
                            }}

                        >
                            {({ errors, touched }) => (
                                <Form className='row p-3'>
                                    {/* <div class="col-sm-12"> */}
                                    <div class="col-6">
                                        <label>Total Stocks<span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" value={buySellTradeData?.qty || 0} disabled class="form-control" onBlur={handleBlurInputPort} name='portfolioName' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.portfolioName && touched.portfolioName ? (
                                            <div className='color-msg-err'>{errors.portfolioName}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-6">
                                        <label>Total Amount <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" value={twoDecimal(((buySellTradeData?.valueInShares || 0) + (buySellTradeData?.profitLoss || 0)).toString())} disabled class="form-control" onBlur={handleBlurInputPort} name='portfolioName' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.portfolioName && touched.portfolioName ? (
                                            <div className='color-msg-err'>{errors.portfolioName}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-6 mt-2">
                                        <label>Stocks <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" class="form-control" onBlur={handleBlurInputPort} name='quantity' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null}
                                    </div>
                                    <div class="col-6 mt-2">
                                        <label>Amount <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" class="form-control" onBlur={handleBlurInputPort} name='amount' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.amount && touched.amount ? (
                                            <div className='color-msg-err'>{errors.amount}</div>
                                        ) : null}
                                    </div>

                                    <div className='col-md-4 d-flex'>


                                        {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEdit === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}


                                        <button class="btn buyButton mt-3 w-100" style={{ padding: '2px 37px' }} onClick={()=>handleSubmitBuySell('buy')} type='submit'>Buy</button>
                                        <button class="btn ms-md-2 ms-2  mt-3 w-100 sellButton" style={{ padding: '2px 37px' }} onClick={()=>handleSubmitBuySell('sell')} id='Cancle-portFolio' type='submit' >Sell</button>
                                    </div>





                                </Form>
                            )}

                        </Formik>
                    </div>
                    {/* <div className="modal-body blackout-bx">

                        <div class="table-responsive">
                            <table class="table table-input1 table-bordered mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className='color-grey'>Date</th>
                                        <th scope="col" className='color-grey'>Price</th>
                                        <th scope="col" className='color-grey'>Quantity</th>
                                        <th scope="col" className='color-grey'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default BuySellUpdateTrade