import React, { useEffect, useRef, useState } from 'react'
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { getStockDailyFullDetails } from '../../../../services/ChartService';
const LineChart = ({ tradeCode, stockDate }) => {
    const [showSideBar, setShowSideBar] = useState(true)
    const [chartType, setChartType] = useState('1')
    const [stockFullPriceData, setStockFullPriceData] = useState([])
    const [stockLineData,setStockLineData]=useState([])
    const chartRef = useRef()
    let tvScriptLoadingPromise;

    let chrtT = "0"

    useEffect(() => {
        chartRef.current = createWidget;

        if (!tvScriptLoadingPromise) {
            tvScriptLoadingPromise = new Promise((resolve) => {
                const script = document.createElement('script');
                script.id = 'tradingview-widget-loading-script';
                script.src = 'https://s3.tradingview.com/tv.js';
                script.type = 'text/javascript';
                script.onload = resolve;

                document.head.appendChild(script);
            });
        }

        tvScriptLoadingPromise.then(() => chartRef.current && chartRef.current());
        function createWidget() {
            if (document.getElementById('tradingview_ac040') && 'TradingView' in window) {
                const widget = window.tvWidget = new window.TradingView.widget({
                    autosize: true,
                    symbol: tradeCode,
                    interval: stockDate,
                    timezone: "Etc/UTC",
                    theme: "light",
                    style: chartType,
                    locale: "en",
                    toolbar_bg: "#f1f3f6",
                    // enable_publishing: true,
                    // withdateranges: true,
                    // range: "YTD",
                    "hide_top_toolbar": true,
                    hide_side_toolbar: showSideBar,
                    allow_symbol_change: false,
                    save_image: false,
                    container_id: "tradingview_ac040"
                });
                return () => {
                    widget.remove();
                };
            }
        }
    },
        [tradeCode, showSideBar, stockDate, chartType]
    );


    // document.querySelector('.tv-header__link').remove();
    // function convertAndStoreData(originalData) {
    //     const dataArray = [];
    //     const lineDataArr=[]
    //     for (const date in originalData["Time Series (Daily)"]) {
    //         if (originalData["Time Series (Daily)"].hasOwnProperty(date)) {
    //             const dataEntry = originalData["Time Series (Daily)"][date];
    //             const time = date;
    //             const open = parseFloat(dataEntry["1. open"]);
    //             const high = parseFloat(dataEntry["2. high"]);
    //             const low = parseFloat(dataEntry["3. low"]);
    //             const close = parseFloat(dataEntry["4. close"]);
    //             const value = parseInt(dataEntry["5. volume"]);

    //             const newDataEntry = {
    //                 time,
    //                 open,
    //                 high,
    //                 low,
    //                 close,
    //                 value
    //             };
    //             dataArray.push(newDataEntry);
    //             let lineData={time:time,value:close}
    //             lineDataArr.push(lineData)
    //         }
    //     }
    //     dataArray.sort((a, b) => new Date(a.time) - new Date(b.time));
    //     lineDataArr.sort((a, b) => new Date(a.time) - new Date(b.time));


    //     return [dataArray,lineDataArr];
    // }
    // useEffect(() => {
    //     try {
    //         getStockDailyFullDetails(tradeCode).then((res) => {
    //             if (res?.status === 200) {
    //                 let data = convertAndStoreData(res.data)
    //                 setStockFullPriceData(data[0])
    //                 setStockLineData(data[1])

    //             }
    //         })
    //     } catch (error) {

    //     }
    // }, [tradeCode])

    // const chartContainerRef = useRef(null);

    // const chart = useRef(null);
    // useEffect(() => {
    //     const container = document.getElementById('chart-container');
    //     const chart = createChart(container, {
    //         width: container.clientWidth,
    //         height: container.clientHeight,
    //         crosshair: {
    //             mode: CrosshairMode.Normal,
    //         },
    //     });
    //     // addBarSeries
    //     if (chartType == '0') {
    //         const candlestickSeries = chart.addBarSeries({
    //             topColor: '#2962FF',
    //             bottomColor: 'rgba(41, 98, 255, 0.28)',
    //             lineColor: '#2962FF',
    //             lineWidth: 2,
    //         });
    //         candlestickSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 // positioning the price scale for the area series
    //                 top: 0.1,
    //                 bottom: 0.4,
    //             },
    //         });
    //         const volumeSeries = chart.addHistogramSeries({
    //             color: '#26a69a',
    //             priceFormat: {
    //                 type: 'volume',
    //             },
    //             priceScaleId: '', // set as an overlay by setting a blank priceScaleId
    //             // set the positioning of the volume series
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         volumeSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         candlestickSeries.setData(stockFullPriceData)
    //         volumeSeries.setData(stockFullPriceData)
    //     }else if(chartType=='1'){
    //         const candlestickSeries = chart.addCandlestickSeries({
    //             topColor: '#2962FF',
    //             bottomColor: 'rgba(41, 98, 255, 0.28)',
    //             lineColor: '#2962FF',
    //             lineWidth: 2,
    //         });
    //         candlestickSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 // positioning the price scale for the area series
    //                 top: 0.1,
    //                 bottom: 0.4,
    //             },
    //         });
    //         const volumeSeries = chart.addHistogramSeries({
    //             color: '#26a69a',
    //             priceFormat: {
    //                 type: 'volume',
    //             },
    //             priceScaleId: '', // set as an overlay by setting a blank priceScaleId
    //             // set the positioning of the volume series
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         volumeSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         candlestickSeries.setData(stockFullPriceData)
    //         volumeSeries.setData(stockFullPriceData)
    //     }else{
    //         const candlestickSeries = chart.addAreaSeries({
    //             topColor: '#2962FF',
    //             bottomColor: 'rgba(41, 98, 255, 0.28)',
    //             lineColor: '#2962FF',
    //             lineWidth: 2,
    //         });
    //         candlestickSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 // positioning the price scale for the area series
    //                 top: 0.1,
    //                 bottom: 0.4,
    //             },
    //         });
    //         const volumeSeries = chart.addHistogramSeries({
    //             color: '#26a69a',
    //             priceFormat: {
    //                 type: 'volume',
    //             },
    //             priceScaleId: '', // set as an overlay by setting a blank priceScaleId
    //             // set the positioning of the volume series
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         volumeSeries.priceScale().applyOptions({
    //             scaleMargins: {
    //                 top: 0.7, // highest point of the series will be 70% away from the top
    //                 bottom: 0,
    //             },
    //         });
    //         candlestickSeries.setData(stockLineData)
    //         volumeSeries.setData(stockFullPriceData)
    //     }
      

    //     return () => {
    //         if (chart) {
    //             chart.remove();
    //         }
    //     };
    // }, [stockFullPriceData, chartType,stockLineData]);

    return (
        <>
            <div className='tradingview-widget-container-crypto'>
                {/* <button className={showSideBar ? 'show-widgth-button show-widgth-button-hide' : 'show-widgth-button show-widgth-button-show'} onClick={() => showSideBar ? setShowSideBar(false) : setShowSideBar(true)}>{showSideBar ? <IoIosArrowForward /> : <IoIosArrowBack />}</button> */}
                <div className='row justify-content-end'>
                    <div className='col-12 d-flex mb-1 justify-content-end'>
                        <p className='mb-0 chartSel white_text'>Select Chart Type </p>
                        <select className='chartSel1 ms-2' value={chartType} onChange={(e) => setChartType(e.target.value)}>
                            <option value={'0'}>Bar</option>
                            <option value={'1'}>Candle</option>
                            <option value={'2'}>Line</option>
                        </select>
                    </div>

                </div>
                {/* <div id="chart-container" ref={chartContainerRef} style={{ width: '100%', height: '350px' }} /> */}
                <div id='tradingview_ac040' className='chart-overView' />
                <p ref={chartRef}></p>
                <div class="col-sm-12 ">

                </div>
            </div>
            {/* <div className='tradingview-widget-container'>
				<div id='tradingview_fca25' />
				<div className="tradingview-widget-copyright">
					<a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets</span></a> on TradingView
				</div>
			</div> */}
        </>
    )
}

export default LineChart