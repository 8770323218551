import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const StockHeader = ({ stockData, stockDate, tradeCode }) => {
    console.log(stockData,'stockDatastockData')
    let withoutSpaces = tradeCode.replace(/\s+/g, '')

    const [increased, setIncreased] = useState(true)
    const [profitLoss, setProfitLoss] = useState(true)
    const [profitPresent, setprofitPresent] = useState(true)
    const [tradeSymbol, setTradeSymbol] = useState('')
    let n = tradeCode.split('.')
    let tradeDataArray
    useEffect(()=>{
        
        if (tradeCode) {
            var _ss=n[n.length - 1];
            if (_ss.includes("ASX:")) {
             
                tradeDataArray = stockData[0]
                setTradeSymbol('ASX:' + n[0])
            } else {
               
                tradeDataArray = stockData[0]
                setTradeSymbol(n[0])
            }
    
        }
    },[tradeCode])

    useEffect(() => {
        console.log(stockData[0],'stockDatastockDatastockData')
        let op = stockData[0]?.open || 0
        let cl = stockData[0]?.close || 0
        // console.log(stockData[0],'opppp')
        setProfitLoss(cl - op)
        if ((cl - op) > 0) {
            setIncreased(true)
        } else {
            setIncreased(false)
        }
        setprofitPresent(((cl - op) * 100) / stockData[0]?.open || 0)
    }, [stockData])
    // useMemo(() => , [profitLoss])

    return (
        <>
            <div class="row">
                <div class="cp-tail-bx">
                    {/* <div class="col-sm-7">
                        <div class="company-lg">
                            <img src="assets/image/tata.webp" class="img-fluid" />
                        </div>
                    </div> */}

                    {/* 
                    <div class="col-sm-4  ">
                        <div class="bell-bx">
                            <ul>
                                <li><a href=""><i class="bi bi-bell"></i></a></li>
                                <li><a href=""><i class="bi bi-bookmark"></i></a></li>
                            </ul>
                        </div>
                    </div> */}

                    <div class="col-sm-12 mt-4 d-md-flex">
                        {/* <button class="btn ms-auto d-block optino-btn-td"> <i class="bi bi-link" style={{"font-size":"14px;"}}></i> Option Chain</button> */}
                        <h1 className='white_text' >{tradeCode?tradeCode : 'Loading...'}</h1>
                        <h5 className='overview-header-text white_text'>
                        {Number(stockData[0]?.close || 0).toFixed(2)} <span className={increased ? 'green' : 'red'}>{Number(profitLoss).toFixed(2)} ( {Number(profitPresent).toFixed(2)}% )
                       
                        </span>
                            {/* <span class="id">{stockDate}</span> */} &nbsp; 
                            <Link to={`/chart?tradeCode=${tradeSymbol}`} target='_blank' className='candle-chart-link'>View Professional Chart</Link></h5>
                        <button type='button' id='closeButton' className='d-none' data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockHeader