import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { addStocks, getSearchStockDetails, getStockList, searchStocks } from '../../../services/HomeService'
// import { getPortfoloio } from '../../../services/PortfolioService'
// import { getProviders } from '../../../services/ProviderServices'
// import CreatePortfolio from '../../commonComponent/commonModels/StockModels/CreatePortfolio'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { getStockOverView, getStockStats } from '../../../services/TradeService'
import { GetProviderstocks } from '../../../services/ProviderServices';
import { toast } from 'react-toastify';
import { getStrategy } from '../../../services/StrategyService';
import moment from 'moment';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
import $ from "jquery";

const CreateTrade = ({ allCountryList, userType, setGetTraderun, providerList, setTradeListRun, allData }) => {
    const [addStockData, setAddStockData] = useState({
        userId: "",
        country: "",
        providerName: "",
        providerId: '',
        stockId: '',
        stockCode: "",
        tradeType: "",
        direction: true,
        entry: '',
        stopLoss: '',
        target: '',
        closed: '',
        price: '',
        changes: '',
        positionStatus: false,
        tradeDate: new Date(),
        margin: '',
        Brokrage: '',
        stockName: '',
        TradingSystemId: '',
        imgFile: '',
        imgUrl: ''
    })
    const [selectStock, setSelectStock] = useState()
    const [searchList, setSearchList] = useState([])
    const [stocksList, setStocksList] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [longShort, setLongShort] = useState(true)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [stockStats, setStockStats] = useState()
    const [showStockListDropDown, setShowStockListDropDown] = useState(false)
    const [stockListPaging, setStockListPaging] = useState({ PageNumber: 1, PageSize: 100, search: '' })
    const { PageNumber, PageSize, search } = stockListPaging
    const [loadingDit, setLoadingDot] = useState(false)
    const [error, setError] = useState({ target: true, stopLoss: true })
    const [strategyList, setStrategyList] = useState([])
    const [disableSave, setDisableSave] = useState(false)
    const [stockCountrySymbol, setCountrySymbol] = useState({ symbol: '', code: '' })
    const { symbol, code } = stockCountrySymbol
    const { userId, imgFile, country, providerId, stockName, Brokrage, providerName, margin, stockId, stockCode, tradeType, direction, entry, stopLoss, target, closed, price, changes, positionStatus } = addStockData
    const [popUpShow, setPopUpShow] = useState(null)

    const showToast = (message) => {
        if (popUpShow) {
            toast.dismiss(popUpShow);
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
        } else {
            // If no active toast, show a new one
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
    };
    const getStrategyList = () => {
        if (userType?.userId !== undefined) {


            try {
                getStrategy(userType?.userId).then((res) => {
                    if (res?.status === 200) {
                        setStrategyList(res.data)
                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        // if (userType?.userId!==undefined) {
        getStrategyList()
        // }
    }, [userType])
    const getProviderStocksList = () => {
        if (providerId !== '' && providerId !== undefined) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                id: providerId,
                search: search
            }
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setStocksList(res.data.data)
                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getProviderStocksList()
    }, [providerId, search, PageSize])
    const handleSearchStocks = (e) => {
        setLoadingDot(true)
        setSearchKey(e.target.value)
        let a = e.target.value

        if (a.length > 1) {

            let data = {
                a, providerId
            }
            try {
                searchStocks(data).then((res) => {
                    setLoadingDot(false)
                    setSearchList(res.data)
                    setStocksList(res.data)
                })
            } catch (error) {

            }
        } else {
            setLoadingDot(false)
            setSearchList([])
            setStocksList([])
        }
    }

    const handleSearchSelectStock = (value) => {
        let stockCountry = allCountryList.filter((val) => val.key.toUpperCase() == value.country.toUpperCase())
        let stockCountySymbol = CountryCurrencyList.filter((val) => val.code == stockCountry[0]?.value)

        setCountrySymbol({ code: stockCountry[0]?.value, symbol: stockCountySymbol[0]?.symbol_native })
        try {
            getSearchStockDetails(value.stockCode).then((res) => {
                if (res?.status === 200) {

                    //let aus = value.stockCode.includes(".AX")
                    let aus = value.stockCode.includes("ASX:")
                    let India = value.stockCode.includes(".BSE")
                    let pName = providerList.filter((el) => {
                        return (
                            el.providerId === value.providerId
                        )
                    })

                    if (aus) {

                        // const price = res.data["close"];
                        const price = res.data.quotes[0].price

                        setSearchList([])
                        setSearchKey('')
                        setSelectStock(res.data)
                        // providerId


                        setShowStockListDropDown(false)
                        setAddStockData({ ...addStockData, stockName: value?.stockCode, stopLoss: '', closed: '', target: '', stockCode: value?.stockCode, providerId: value.providerId, stockId: value.stockId, price: Number(price).toFixed(2), entry: Number(price).toFixed(2), providerName: pName[0]?.providerName, country: value.country, margin: value.margin })

                    }
                    
                    else if(India){
                        const price = res?.data["Global Quote"]["05. price"];
                        setSearchList([])
                        setSearchKey('')
                        setSelectStock(res.data)
                        // providerId


                        setShowStockListDropDown(false)
                        setAddStockData({ ...addStockData, stockName: res?.data[0]?.key, stopLoss: '', closed: '', target: '', stockCode: value?.stockCode, providerId: value.providerId, stockId: value.stockId, price: Number(price).toFixed(2), entry: Number(price).toFixed(2), providerName: pName[0]?.providerName, country: value.country, margin: value.margin })

                    }
                    else {
                        const price = res?.data["Global Quote"]["05. price"];

                        setSearchList([])
                        setSearchKey('')
                        setSelectStock(res.data)
                        // providerId


                        setShowStockListDropDown(false)
                        setAddStockData({ ...addStockData, stockName: res?.data[0]?.key, stopLoss: '', closed: '', target: '', stockCode: value?.stockCode, providerId: value.providerId, stockId: value.stockId, price: Number(price).toFixed(2), entry: Number(price).toFixed(2), providerName: pName[0]?.providerName, country: value.country, margin: value.margin })

                    }
                }
            })
        } catch (error) {

        }
        // let aus = value.stockCode.includes(".AX")
        let aus = value.stockCode.includes("ASX:")
        let india = value.stockCode.includes(".BSE")
        if (!aus) {
debugger
            try {
                getStockOverView(value.stockCode).then((res) => {
                    if (res?.status === 200 && res.data) {

                        setStockStats(res.data)
                    }
                })
            } catch (error) {

            }
           
        }
        // setStockStats
    }
    useEffect(() => {

        if (stockId == '') {
            setSelectStock()
        }
    }, [stockId])
    const handleLongShort = (val) => {
        if (val === 'long') {
            setLongShort(true)
            setAddStockData({ ...addStockData, direction: true })
        } else {
            setLongShort(false)
            setAddStockData({ ...addStockData, direction: false })
        }
        setAddStockData({ ...addStockData, target: '', closed: '' })
    }
    const hanleInputData = (e) => {
        if (e.target.name == 'providerId') {

            let pName = providerList.filter((el) => {
                return (
                    el.providerId == e.target.value
                )
            })
            setAddStockData({ ...addStockData, [e.target.name]: pName[0]?.providerName, providerId: e.target.value, stockId: '' })
        } else if (e.target.name == 'stockId') {
            let stCode = stocksList.filter((el) => {
                return (
                    el.stockId == e.target.value
                )
            })

            handleSearchSelectStock(stCode[0])
            setAddStockData({ ...addStockData, [e.target.name]: e.target.value, stockCode: stCode[0]?.stockCode })
            // stockId
        } else if (e.target.name == 'target') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    showToast('Select provider first.')
                } else if (stockCode == '') {
                    showToast('Select stock first.')
                } else if (entry == '' || entry == 0) {
                    showToast('Enter entry price first.')
                } else {


                    let n = e.target.value.split('.')
                    var roundedNumber = e.target.value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                        if (decimalPlaces > 2) {
                            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                        }
                    }
                    if (longShort == true) {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                        }
                    } else {
                        if (Number(e.target.value) < Number(entry)) {
                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                                }
                            } else if (n.length == 1 && e.target.value.length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                            }
                        } else {
                            showToast('Set lower target price than entry while short trading')
                        }
                    }

                }
            }

        } else if (e.target.name == 'entry') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    showToast('Select provider first.')
                } else if (stockCode == '') {
                    showToast('Select stock first.')
                } else {

                    let n = e.target.value.split('.')
                    var roundedNumber = e.target.value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                        if (decimalPlaces > 2) {
                            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                        }
                    }
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, target: '', closed: '', stopLoss: '' })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, target: '', closed: '', stopLoss: '' })
                    }

                }
            }
        } else if (e.target.name == 'closed') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    showToast('Select provider first.')
                } else if (stockCode == '') {
                    showToast('Select stock first.')
                } else if (entry == '' || entry == 0) {
                    showToast('Enter entry price first.')
                } else {

                    let n = e.target.value.split('.')
                    var roundedNumber = e.target.value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                        if (decimalPlaces > 2) {
                            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                        }
                    }
                    if (longShort == false) {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                        }
                    } else {
                        if (Number(e.target.value) < Number(entry)) {

                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                                }
                            } else if (n.length == 1 && e.target.value.length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                            }
                        } else {
                            showToast('Set lower stop loss price than entry while long trading')
                        }
                    }
                }

            }
        } else if (e.target.name == 'files') {
            setAddStockData({
                ...addStockData, imgFile: e.target.files[0],
                imgUrl: URL.createObjectURL(e.target.files[0])
            })

        } else if (e.target.name == 'margin') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {

                if (providerId == '' || providerId == undefined) {
                    showToast('Select provider first.')
                } else if (stockCode == '') {
                    showToast('Select stock first.')
                } else {

                    var roundedNumber = e.target.value;
                    var decimalIndex = roundedNumber.indexOf(".");
                    if (decimalIndex !== -1) {
                        var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                        if (decimalPlaces > 2) {
                            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                        }
                    }
                    let n = e.target.value.split('.')
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                    }

                }
            }
        } else if (e.target.name == 'Brokrage' || e.target.name == 'changes') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                let n = e.target.value.split('.')
                var roundedNumber = e.target.value;
                var decimalIndex = roundedNumber.indexOf(".");
                if (decimalIndex !== -1) {
                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                    if (decimalPlaces > 2) {
                        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                    }
                }
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                }

            }

        } else {
            setAddStockData({ ...addStockData, [e.target.name]: e.target.value })
        }
    }

    const handleCreateStock = () => {
        const formatDate = (date) => {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const tradeDateFormatted = formatDate(selectedDate);
        const formData = new FormData();
        formData.append('UserId', addStockData.userId);
        formData.append('Country', addStockData.country.toUpperCase());
        formData.append('ProviderName', addStockData.providerName);
        formData.append('TradingSystemId', addStockData.TradingSystemId);
        formData.append('StockId', addStockData.stockId);
        formData.append('StockCode', addStockData.stockCode);
        formData.append('TradeType', addStockData.tradeType);
        formData.append('Direction', longShort);
        formData.append('Entry', addStockData.entry);
        formData.append('StopLoss', addStockData.stopLoss);
        formData.append('Target', addStockData.target);
        formData.append('Closed', addStockData.closed);
        formData.append('Price', addStockData.price);
        formData.append('Changes', addStockData.changes);
        formData.append('PositionStatus', false);
        // formData.append('TradeDate', selectedDate);
        formData.append('TradeDate', tradeDateFormatted);
        formData.append('Margin', addStockData.margin);
        formData.append('Brokrage', addStockData.Brokrage);
        formData.append('StockName', addStockData.stockName);
        formData.append('Action', '');
        formData.append('Strategy', '');
        formData.append('ProviderID', addStockData?.providerId);
        formData.append('file', addStockData.imgFile);
debugger
        try {
            addStocks(formData).then((res) => {
                if (res?.status === 200) {
                    setTradeListRun(true)
                    let cancle = document.getElementById('cancel-button-edit')
                    document.getElementById('imgFile').value = ''
                    setGetTraderun(true)
                    cancle.click()
                }
                setDisableSave(false)
            })
        } catch (error) {
            setDisableSave(false)
        }
    }


    const handleRemoveAllData = () => {
        setAddStockData({
            userId: "1",
            country: "",
            imgFile: '',
            imgUrl: '',
            providerName: "",
            providerId: '',
            stockId: '',
            stockCode: "",
            tradeType: "",
            direction: true,
            entry: '',
            stopLoss: '',
            target: '',
            closed: '',
            price: '',
            changes: '',
            positionStatus: false,
            tradeDate: new Date(),
            margin: '',
            Brokrage: '',
            stockName: '',
            TradingSystemId: ''
        })
        setSelectStock()
        setShowStockListDropDown(false)
        setError({ target: true, stopLoss: true })
        setOpen(false)
        document.getElementById('imgFile').value = ''
        setStocksList([])
    }
    // const handleScroll = () => {
    // }

    // setTimeout(() => {
    //     let scrollid = document.getElementById('selectScroll')
    //     scrollid.addEventListener('scroll', handleScroll)
    // }, 1000)


    const filterWeekends = (date) => {
        // Get the day of the week (0-6) for the provided date
        const day = date.getDay();
        // If the day of the week is Saturday (6) or Sunday (0), disable the date
        return day !== 0 && day !== 6;
    };
    let validationSchema = Yup.object().shape({
        country: Yup.string().required('Country is required').typeError('Country name is not valid'),
        providerId: Yup.string().required('Provider name is required'),
        TradingSystemId: Yup.string().required('Strategy is required'),
        stockId: Yup.string().required('Stock is required'),
        entry: Yup.string().required('Entry is required'),
        stopLoss: Yup.string().required('Stoploss is required'),
        target: Yup.string().required('Target is required'),
        closed: Yup.string().required('Stop loss is required'),
        changes: Yup.string().required('Exchange rate is required'),
        margin: Yup.string().required('Margin is required'),
        Brokrage: Yup.string().required('Brokrage is required'),
    });
    const renderError = (message) => <p className="help is-danger">{message}</p>
    // const Formik=useFormik({initialValues:{ ...addStockData },
    //     validationSchema:{validationSchema},
    //     onSubmit: values => {
    //         alert(JSON.stringify(values, null, 2));
    //       },    
    // })


    // Event handler for scroll event on select element
    var selectElement = document.getElementById('selectScroll');
    var page = 1; // Track the page number for pagination
    var isLoading = false; // Track if data is currently being loaded
    // Event handler for scroll event on select element
    selectElement?.addEventListener('scroll', () => {
        if (selectElement.scrollTop + selectElement.clientHeight >= selectElement.scrollHeight) {
            // User has scrolled to the bottom
            // Load more data if not already loading
            if (!isLoading) {
                loadData()
            }
        }
    });

    // Function to load additional data from the API
    function loadData() {
        isLoading = true; // Set loading state
        setStockListPaging({ ...stockListPaging, PageNumber: 1, PageSize: 100 + PageSize })
    }
    const handleSelectInputStock = (e) => {

        if (providerId == '') {
            showToast('Select provider first.')
        } else {

            setStockListPaging({ ...stockListPaging, search: e.target.value })
            setAddStockData({ ...addStockData, stockCode: e.target.value })
            setShowStockListDropDown(true)
        }
    }
    const handleBlur = () => {
        setTimeout(() => {
            setShowStockListDropDown(false)
        }, 500)
    }

    // start 02-06-2023
    const [isOpen, setOpen] = useState(false);
    const [isOpen2, setOpen2] = useState(false);
    const [items, setItem] = useState(providerList);
    const [selectedItem, setSelectedItem] = useState(null);


    const toggle = () => setOpen2(!isOpen2);
    const toggleDropdown = () => {
        setOpen(!isOpen);
        setShowStockListDropDown(false)
    }



    const handleItemClick = (data) => {
        setSelectStock()
        setAddStockData({
            ...addStockData, providerId: data.providerId, providerName: data.providerName,
            country: "",
            stockId: '',
            stockCode: "",
            tradeType: "",
            direction: true,
            entry: '',
            stopLoss: '',
            target: '',
            closed: '',
            price: '',
            changes: '',
            positionStatus: false,
            tradeDate: new Date(),
            margin: '',
            Brokrage: '',
            stockName: '',
            TradingSystemId: '',
            imgFile: '',
            imgUrl: ''
        })
        setOpen(false);
        //   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    }

    const handleItemClick2 = (data) => {

        setAddStockData({ ...addStockData, TradingSystemId: data.tradingSystemId, name: data.name })
        setOpen2(false);
        //   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    }



    //end 02-06-2023
    const handleCheckValidation = (e) => {


        if (Number(e.target.value) >= 0) {
            if (entry !== '') {
                if (e.target.name == 'target') {
                    if (longShort === true) {
                        if (Number(entry) >= Number(e.target.value)) {
                            showToast('Set higher target price than entry while long trading')
                            setError({ ...error, target: false })
                        } else {
                            setError({ ...error, target: true })
                        }
                    } else {
                        setError({ ...error, target: true })
                    }
                } else {
                    if (longShort === false) {
                        if (Number(entry) >= Number(e.target.value)) {
                            showToast('Set higher stop loss price than entry while short trading')
                            setError({ ...error, stopLoss: false })
                        } else {
                            setError({ ...error, stopLoss: true })
                        }
                    } else {
                        setError({ ...error, stopLoss: true })
                    }
                }
            }
        }
    }
    const handleBlurInput = () => {

    }
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdownO > .captionO").length === 0) {
                setShowStockListDropDown(false)
            }
        });
    });
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown1 > .caption1").length === 0) {
                setOpen(false)
            }
        });
    });
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown2 > .caption2").length === 0) {
                setOpen2(false)
            }
        });
    });
    return (
        <>
            <div className="modal fade backdrop-1" id="createTrade" data-bs-backdrop="static" role="dialog">
                <div className="modal-dialog modal-lg  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Create Trade</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close" onClick={handleRemoveAllData}>×</button>
                        </div>

                        <div className="modal-body blackout-bx">
                            {/* <!-------modeal-----> */}

                            <div className="row">


                                {/* <div className="col-lg-12">
                                    <p>Search by company name or code <Link to='' className="float-end" >or  <span style={{ color: "#ff8038" }}>+ Add a custom instrument</span></Link> </p>
                                </div> */}


                                <div className="col-lg-12 mb-4">
                                    <div className="search loading-main-dot">
                                        <i className="bi bi-search" type="submit"></i>
                                        {
                                            loadingDit && <div className='loading-Dot'>
                                                <div class="dot1"> </div>
                                                <div class="dot2"></div>
                                                <div class="dot3"></div>
                                            </div>
                                        }
                                        <input type="text" value={searchKey} onChange={handleSearchStocks} className="form-control" placeholder="Search Stock" />
                                        {
                                            searchList.length > 0 && <div className='dropdown-search'  >
                                                {
                                                    searchList.map((val, ind) => {
                                                        return (
                                                            <p className='d-flex justify-content-between ' key={ind} onClick={() => handleSearchSelectStock(val)}><spn>{val.stockCode}</spn><span className='me-3 city_name'>{val.country}</span></p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                {

                                    selectStock !== undefined && <div className="col-lg-12">

                                        <div className="rate-bx">
                                            {

                                                (stockCode.split('.').length > 1) ?
                                                    <>
                                                    {console.log(selectStock ,"selectStock")}
                                                    {console.log(selectStock['Global Quote']['05. price'] ,"selectStockGlobalQuote")}

                                                        <div class='d-flex'>
                                                            <h2>{stockCode}
                                                                {/* <span className="float-end"> NVIDIA Corp</span> */}
                                                            </h2>
                                                            {/* <p className='ps-5'>Ps Ratio: {(stockStats?.peRatio)?.toFixed(3)} &nbsp;Dividend Yield: {(stockStats?.dividendYield)?.toFixed(3)}</p> */}
                                                        </div>
                                                        {/* <h2 className="pt-3">{symbol} {selectStock['close']} <span className={selectStock.close > selectStock.open ? 'green' : 'red'}> &nbsp;{Number((selectStock.close - selectStock.open) * 100 / selectStock.open).toFixed(2)} %</span> {code}  <span className="float-end"> {moment(selectStock.date).format('DD-MM-YYYY')} </span> </h2> */}
                                                        <h2 className="pt-3">{symbol} {selectStock["Global Quote"]["05. price"]} <span className={selectStock["Global Quote"]["05. price"] > selectStock["Global Quote"]["02. open"] ? 'green' : 'red'}>{Number((selectStock["Global Quote"]["05. price"] - selectStock["Global Quote"]["02. open"]) * 100 / selectStock["Global Quote"]["02. open"]).toFixed(2)} %</span> {code}  <span className="float-end"> {selectStock["Global Quote"]["07. latest trading day"]} </span> </h2>

                                                    </>



                                                    : stockCode.includes("ASX:") ? (
                                                        <>
                                                            <div className="d-flex">
                                                                <h2>
                                                                    {stockCode}
                                                                    {/* <span className="float-end"> NVIDIA Corp</span> */}
                                                                </h2>
                                                                {/* <p className="ps-5">Ps Ratio: {stockStats?.PERatio} &nbsp;Dividend Yield: {Number(stockStats?.DividendYield)}</p> */}
                                                            </div>
                                                            {/* <h1 className="pt-3">
    {symbol} {selectStock.quotes[0].price} 
    <span className="float-end"> {moment(selectStock.date).format('DD-MM-YYYY')} </span>
  </h1> */}
                                                            <h2 className="pt-3">
                                                                {symbol} {selectStock.quotes[0].price}
                                                                <span className={selectStock.quotes[0]["price"] > selectStock.quotes[0]["open"] ? 'green' : 'red'}>&nbsp;
                                                                    {Number(((selectStock.quotes[0]["price"] - selectStock.quotes[0]["open"]) * 100 / selectStock.quotes[0]["open"]).toFixed(2))} % &nbsp;
                                                                </span>
                                                                {code}
                                                                <span className="float-end">
                                                                <span className="float-end"> {moment(selectStock.date).format('DD-MM-YYYY')} </span>
                                                                </span>
                                                            </h2>
                                                        </>

                                                    )
                                                        :

                                                        <>
                                                            <div class='d-flex'>
                                                                <h2>{stockCode}
                                                                    {/* <span className="float-end"> NVIDIA Corp</span> */}
                                                                </h2>
                                                                <p className='ps-5'>Ps Ratio: {(stockStats?.PERatio)} &nbsp;Dividend Yield: {Number(stockStats?.DividendYield)}</p>
                                                            </div>

                                                            <h2 className="pt-3">{symbol} {selectStock["Global Quote"]["05. price"]} <span className={selectStock["Global Quote"]["05. price"] > selectStock["Global Quote"]["02. open"] ? 'green' : 'red'}>{Number((selectStock["Global Quote"]["05. price"] - selectStock["Global Quote"]["02. open"]) * 100 / selectStock["Global Quote"]["02. open"]).toFixed(2)} %</span> {code}  <span className="float-end"> {selectStock["Global Quote"]["07. latest trading day"]} </span> </h2>
                                                        </>
                                            }
                                        </div>
                                    </div>
                                }
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={addStockData}
                                    validationSchema={validationSchema}
                                    onSubmit={(value) => {
                                        if (error.stopLoss == true && error.target == true) {
                                            setDisableSave(true)
                                            handleCreateStock()
                                        }
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form className="form-bx mt-4" >

                                            <div className="row">
                                                {/* <!-------row-----> */}

                                                <div className="col-lg-6 ">
                                                    <div className="mb-3">
                                                        <label for="formGroupExampleInput" className="form-label">Country <span style={{ "color": "#f00" }}>*</span></label>
                                                        <Field type="text" disabled name='country' onBlur={handleBlurInput} className="form-control" onKeyUp={(e) => { hanleInputData(e) }} id="formGroupExampleInput" />
                                                        {errors.country && touched.country ? (
                                                            <div className='color-msg-err'>{errors.country}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 ms-auto">
                                                    {/* start 02-06-2023 */}

                                                    <div className="mb-3 a dropdown1">
                                                        <label for="formGroupExampleInput" className="form-label">Provider Name <span style={{ "color": "#f00" }}>*</span></label>
                                                        <div className='dropdown caption1'>
                                                            <div className='dropdown-header background_white' onClick={toggleDropdown}>
                                                                {selectedItem ? items.find(item => item.providerId == selectedItem)?.label : addStockData?.providerName || "Select"}
                                                                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
                                                            </div>
                                                            <div className={`dropdown-body ${isOpen && 'open'}`} as='select' onChange={(e) => { hanleInputData(e) }}>
                                                                {providerList.map(item => (
                                                                    <div className="dropdown-item" onClick={e => handleItemClick(item)} id={item.providerId}>
                                                                        <span className={`dropdown-item-dot ${item.providerId == selectedItem && 'selected'}`}> </span>
                                                                        {item.providerName}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        {errors.providerId && touched.providerId ? (
                                                            <div className='color-msg-err'>{errors.providerId}</div>
                                                        ) : null}

                                                        {/*                                                         
                                                        <Field name='providerId' as='select' className="form-select" onChange={(e) => { hanleInputData(e) }}>
                                                            <option value="0" style={{ 'visibility': "hidden"}}>Select Provider</option>
                                                            {
                                                                providerList.map((val, ind) => {
                                                                    return (
                                                                        <option key={ind} value={(val.providerId)} >{val.providerName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field> */}
                                                        {/* {errors.providerName && touched.providerName ? (
                                                            <div className='color-msg-err'>{errors.providerName}</div>
                                                        ) : null} */}
                                                    </div>

                                                    {/* end 02-06-2023 */}
                                                </div>

                                                <div className="col-lg-6 ">
                                                    <div className="mb-3">
                                                        <label for="formGroupExampleInput" className="form-label">Trade Date</label>
                                                        <DatePicker
                                                            className='date-picker ps-2'
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            // minDate={new Date()}
                                                            filterDate={filterWeekends}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 ms-auto">

                                                    <div className="long-shr-btn ">
                                                        <label for="formGroupExampleInput" className="form-label">Direction</label>
                                                        <nav className="menu">
                                                            <button type='button' className={longShort == 'long' ? "menu-link menu-active-create flex-fill" : "menu-link flex-fill"} onClick={() => handleLongShort('long')}>Long</button>
                                                            <button type='button' className={longShort == 'short' ? "menu-link menu-active-create flex-fill" : "menu-link flex-fill"} onClick={() => handleLongShort('short')}>Short</button>
                                                        </nav>
                                                        {/* <nav className="menu">
                                                            <Link to="#" className="menu-link menu-active flex-fill" onClick={() => handleLongShort('long')} data-menu="0">Long</Link>
                                                            <Link to="#" className="menu-link flex-fill" onClick={() => handleLongShort('short')} data-menu="3">Short</Link>
                                                        </nav> */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mt-2">
                                                    <div className="mb-3">
                                                        <label for="formGroupExampleInput" className="form-label">Margin % <span style={{ "color": "#f00" }}>*</span></label>
                                                        <Field type="text" name='margin' placeholder='0' id='restrictedInput' autocomplete='off' value={margin} onBlur={handleBlurInput} className="form-control" onChange={hanleInputData} />
                                                        {errors.margin && touched.margin ? (
                                                            <div className='color-msg-err'>{errors.margin}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mt-2 ms-auto">
                                                    <div className="mb-3 dropdownO">
                                                        <label for="formGroupExampleInput" className="form-label">Stocks <span style={{ "color": "#f00" }}>*</span></label>

                                                        <input autocomplete={'off'} as='select' onBlur={handleBlurInput} onClick={() => setShowStockListDropDown(!showStockListDropDown)} placeholder='Select Stock' value={stockCode} onChange={(e) => { handleSelectInputStock(e) }} className="form-select captionO" name='stockCode' />
                                                        {/* <option selected >Select Stock</option> */}
                                                        {showStockListDropDown &&
                                                            <div className='dropdown-search stockOption' id="selectScroll" >
                                                                {stocksList.length > 0 ?
                                                                    stocksList.map((val, ind) => {
                                                                        return (
                                                                            <option key={ind} onBlur={handleBlur} onClick={() => handleSearchSelectStock(val)}>{val.stockCode}</option>
                                                                        )
                                                                    }) : <p>No stock found.</p>
                                                                }
                                                            </div>
                                                        }
                                                        {errors.stockId && touched.stockId ? (
                                                            <div className='color-msg-err'>{errors.stockId}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="rate-bx1">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Entry Price <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>

                                                                    <Field name='entry' placeholder='0' id='restrictedInput' autocomplete='off' onBlur={handleBlurInput} value={entry} className='form-control' onChange={hanleInputData} />
                                                                    {errors.entry && touched.entry ? (
                                                                        <p className='err-msg'>{errors.entry}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">$</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Target Price <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>
                                                                    <Field id='restrictedInput' placeholder='0' autocomplete='off' class={!error.target ? "input-err-color form-control" : "form-control"} onBlur={handleCheckValidation} value={target} name='target' onChange={hanleInputData} />
                                                                    {errors.target && touched.target ? (
                                                                        <p className='err-msg'>{errors.target}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">$</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Stop Loss <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>
                                                                    <Field name='closed' id='restrictedInput' placeholder='0' autocomplete='off' onBlur={handleCheckValidation} class={!error.stopLoss ? "input-err-color form-control" : "form-control"} value={closed} onChange={hanleInputData} />
                                                                    {errors.closed && touched.closed ? (
                                                                        <p className='err-msg'>{errors.closed}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">$</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mt-3">
                                                    <div className="row">
                                                        {/* start 02-06-2023 */}

                                                        <div className="col-sm-7 col-lg-12 a mt-4">
                                                            <label for="formGroupExampleInput" className="form-label">Strategy <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></label>
                                                            <div className="d-flex gap-4">
                                                                <div className='w-100 dropdown2'>
                                                                    <div className='dropdown caption2'>
                                                                        <div className='dropdown-header background_white' onClick={toggle}>
                                                                            {/* {selectedItem ? items.find(item => item.providerId == selectedItem)?.label :addStockData?.providerName||"Select"} */}
                                                                            {selectedItem ? items.find(item => item.tradingSystemId == selectedItem)?.label : addStockData?.name || "Select"}
                                                                            <i className={`fa fa-chevron-right icon ${isOpen2 && "open"}`}></i>
                                                                        </div>

                                                                        <div className={`dropdown-body ${isOpen2 && 'open'}`} as='select' onChange={(e) => { hanleInputData(e) }}>
                                                                            {strategyList?.map(item => (
                                                                                <div className="dropdown-item" onClick={() => handleItemClick2(item)} id={item.tradingSystemId}>
                                                                                    <span className={`dropdown-item-dot ${item.tradingSystemId == selectedItem && 'selected'}`}> </span>
                                                                                    {item.name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>

                                                                    {errors.TradingSystemId && touched.TradingSystemId ? (
                                                                        <div className='color-msg-err'>{errors.TradingSystemId}</div>
                                                                    ) : null}
                                                                </div>

                                                                <button className={errors.TradingSystemId && touched.TradingSystemId ? "btn bt-light fullc-or d-block mt-0 flex-shrink-0 height_51px" : "btn bt-light fullc-or d-block mt-0 flex-shrink-0 "} type="button" data-bs-toggle="modal" data-bs-target="#addEditStrategy"> <span> <i className="bi bi-plus-lg"></i> </span> Create Strategy </button>

                                                            </div>

                                                        </div>

                                                        {/* <div className="col-sm-5 col-lg-4 mt-4">
                                                            <button className="btn bt-light fullc-or  ms-auto d-block" type="button" data-bs-toggle="modal" data-bs-target="#addEditStrategy"> <span> <i className="bi bi-plus-lg"></i> </span> Create Strategy </button>
                                                        </div> */}

                                                        {/* end 02-06-2023 */}

                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mt-3">
                                                    <div className="in-w-slec">
                                                        <label for="formGroupExampleInput" className="form-label">Exchange Rate <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></label>
                                                        <div className="input-group ">
                                                            <Field type="text" id='restrictedInput' placeholder='0' autocomplete='off' className="form-control " value={changes} onBlur={handleBlurInput} name='changes' onChange={(e) => { hanleInputData(e) }} aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                            {/* <select className="form-select  " onChange={hanleInputData}>
                                                                <option selected >AUD/USD </option>
                                                            </select> */}
                                                        </div>
                                                        {errors.changes && touched.changes ? (
                                                            <div className='color-msg-err'>{errors.changes}</div>
                                                        ) : null}
                                                    </div>
                                                </div>


                                                <div className="col-lg-6 mt-3">
                                                    <div className="in-w-slec">
                                                        <label for="formGroupExampleInput" className="form-label">Brokrage <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></label>
                                                        <div className="input-group">
                                                            <Field type="text" name='Brokrage' id='restrictedInput' placeholder='0' autocomplete='off' value={Brokrage} onBlur={handleBlurInput} onChange={hanleInputData} className="form-control " aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                            {/* <select className="form-select onChange={hanleInputData}">
                                                                <option selected >AUD</option>
                                                            </select> */}

                                                        </div>
                                                        {
                                                            errors.Brokrage && touched.Brokrage ? (
                                                                <div className='color-msg-err mb-2'>{errors.Brokrage}</div>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mt-4">
                                                    <div className="">
                                                        <label for="formGroupExampleInput" className="form-label">Upload Chart Image</label>
                                                        <div className="input-group ">
                                                            <Field type="file" id='imgFile' accept="image/*" className="form-control file_upload" name='files' onChange={hanleInputData} aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                        </div>

                                                        {errors.imgUrl ? (
                                                            <div className='color-msg-err'>{errors.imgUrl}</div>
                                                        ) : null}
                                                    </div>
                                                </div>





                                                {/* <button className="btn  add-btn white" type="button"> <span> <i className="bi bi-plus-lg"></i> </span> Add comments </button> */}


                                                <div className="row mt-4">
                                                    <div className="col-lg-4 col-sm-4  mx-auto">
                                                        <button className="btn cansel-btn" data-bs-dismiss="modal" id='cancel-button-edit' onClick={handleRemoveAllData} type="button">Cancel</button>
                                                    </div>


                                                    <div className="col-lg-4   col-sm-4 mx-auto">
                                                        <button disabled={disableSave} className="btn save-btn" type="submit">Save</button>
                                                    </div>
                                                </div>



                                                {/* <button className="btn mt-5 ms-auto d-block white add-btn" type="button"> <span> <i className="bi bi-plus-lg"></i> </span>  Save & add another </button> */}





                                            </div>
                                            {/* <!-------row-----> */}
                                        </Form>
                                    )}

                                </Formik>


                            </div>

                        </div>
                        {/* <!-------modeal-----> */}



                    </div>
                </div>
            </div>
            {/* <CreatePortfolio /> */}
        </>
    )
}

export default CreateTrade