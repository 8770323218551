import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";

const NewsHeader = ({ setCurrentDate, setFormatedEndDate }) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [activeTab, setActiveTab] = useState("1D");


    const handleDay = () => {
        
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() - 1);
        const formattedCurrentDate = currentDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        const formattedEndDate = endDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        setCurrentDate(formattedCurrentDate)
        setFormatedEndDate(formattedEndDate)
        // console.log(formattedCurrentDate ,"handleday")
        // console.log(formattedEndDate ,"handleday")
    };

    const handleweek = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() - 7);

        const formattedCurrentDate = currentDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        const formattedEndDate = endDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        setCurrentDate(formattedCurrentDate)
        setFormatedEndDate(formattedEndDate)

    };

    const handleMonth = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() - 30);


        const formattedCurrentDate = currentDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        const formattedEndDate = endDate.toISOString().slice(0, 16).replace(/[-:]/g, '');
        setCurrentDate(formattedCurrentDate)
        setFormatedEndDate(formattedEndDate)
    };
    useEffect(() => {
        handleDay();
    }, []);
    const tabs = [
        { text: "1D", handler: handleDay },
        { text: "1W", handler: handleweek },
        { text: "1M", handler: handleMonth },
    ];

    const handleTabClick = (tabText, handler) => {
        setActiveTab(tabText);
        setStartDate(null);
        setEndDate(null);
        handler();
    };
    const CustomInput = ({ value, onClick, placeholder }) => (
        <input
            className="custom-datepicker-input"
            value={value}
            onClick={onClick}
            placeholder={placeholder}
            style={{

                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px",
                marginLeft: 12,
                marginTop: 13,
                width:'90%'
            }}
        />
    );

    const handleStartDateChange = (date) => {
        date.setHours(18);
        date.setMinutes(30);
        const formattedCurrentDate = date.toISOString().slice(0, 16).replace(/[-:]/g, '');
        setCurrentDate(formattedCurrentDate)
        setStartDate(date);
        setActiveTab(null);
    };

    const handleEndDateChange = (date) => {
        date.setHours(18);
        date.setMinutes(30);
        const formattedEndDate = date.toISOString().slice(0, 16).replace(/[-:]/g, '');
        setFormatedEndDate(formattedEndDate)
        setEndDate(date);
        const currentDate = new Date();
        setStartDate(currentDate);
        setActiveTab(null);

    };



    return (
        <div className="news">
            <div className="News_Header" style={style.header}>
                <div className="tabs d-flex">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => handleTabClick(tab.text, tab.handler)}
                            style={{
                                padding: "5px 33px",
                                margin: 8,
                                border: "none",
                                borderRadius: 17,
                                color: tab.text === activeTab ? " #fff" : "#000000",
                                backgroundColor: tab.text === activeTab ? " #ff9152" : "#fff",
                            }}
                        >
                            {tab.text}
                        </button>
                    ))}
                </div>

                <div className="datepickers" style={{ display: "flex",width:'100%' }}>
                    <div className="Date" >
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            maxDate={new Date()}
                            customInput={<CustomInput />}
                            placeholderText="From"
                        />

                    </div>
                    <div className="Date"  >
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            maxDate={new Date()}
                            customInput={<CustomInput />}
                            placeholderText="To"
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NewsHeader;

const style = {
    list: {
        listStyle: "none",
        margin: 24,
        borderWidth: 1,
        borderColor: "#000",
        padding: 11,
    },
    tab: {

    }
};


